<template>
    <lee-page bg-img="/images/bg_head_10.jpg" title-class="margin-top-100" :height="280" title="登录">
        <template v-if="$isMobile">
            <div class="margin-top padding-left-right">
                fds
            </div>
        </template>
        <template v-else>
            <div class="bg-img-2 padding-bottom-400">
                <div class="cl"></div>
                <div class="margin-auto bg-light border-radius-5 padding-20" style="width:500px;margin-top: -30px;">
                    <div class="green cl border-bottom padding-bottom">
                        登录
                        <el-button size="mini" type="success" round plain class="y vm" @click="$router.push({path:'/qrlogin'})">扫码登录<i class="fa fa-qrcode margin-left" aria-hidden="true"></i></el-button>
                    </div>
                    <el-form :model="formData" status-icon :rules="rules" ref="ruleForm" label-width="auto" class="margin-top-30">
                        <el-form-item label="" prop="username">
                            <el-input v-model="formData.username" autocomplete="off" class="lee-input mobile" placeholder="用户名">
                                <i class="el-icon-user el-input__icon" slot="prefix"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="" prop="password">
                            <el-input type="password" v-model="formData.password" autocomplete="off" class="lee-input mobile" placeholder="密码">
                                <i class="el-icon-lock el-input__icon" slot="prefix"></i>
                            </el-input>
                        </el-form-item>
                        <div class="cl">
                            <a href="javascript:void(0);" class="dark-5 y under" @click="$router.push({path:'/findpwd'})">忘记密码？</a>
                        </div>
                        <el-form-item class="margin-top-30">
                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-button type="success" class="block" @click="login">登录</el-button>
                                </el-col>
                                <el-col :span="12">
                                    <el-button class="block button-green" plain @click="$router.push({path:'/register'})">注册</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </template>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            formData:{
                username:'',
                password:''
            },
            rules: {
                username:[{ required: true, message:'请输入用户名'}],
                password:[{ required: true, message:'请输入密码'}],
            }
        }
    },
    created() {

    },
    mounted() {
        this.showUserWord = true;
    },
    methods:{
        login(){
            let _this = this;
            this.$http.post({
                url:'/Common/login',
                data:this.formData,
                success(res){
                  _this.$user.setMobile(res.mobile);
                  _this.$user.setOpenId(res.openid);
                  _this.$user.setAvatar(res.avatar);
                  _this.$user.setUid(res.uid);
                  _this.$user.setUserName(res.username);
                  _this.$user.setToken(res.token);
                  _this.$user.setEmail(res.email);
                  _this.$user.setContact(res.contact);
                  _this.$user.setSex(res.sex);
                  _this.$user.setNickName(res.nickname);
                  _this.$user.setVipState(res.vip_state);
                  _this.$user.setCompany(res.company);
                  _this.$user.setJob(res.job);
                  _this.$router.back();
                }
            })
        }
    }
}
</script>
