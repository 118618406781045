<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
          据中物联冷链委统计，{{nowYear}}年全国冷库容量达{{data.volume.now.total}}万立方米，较上年{{(parseFloat(data.volume.now.total) - parseFloat(data.volume.last.total)) >= 0 ? '新增' : '减少'}}{{(parseFloat(data.volume.now.total) - parseFloat(data.volume.last.total)).toFixed(2)}}万立方米，同比增长{{data.volume.now.rate}}%<br/>
<!--            据中物联冷链委不完全统计，{{nowYear}}年全国冷库容量达到{{data.size.now.total}}万吨（折合约{{data.volume.now.total}}亿立方米），{{(parseFloat(data.size.now.total) - parseFloat(data.size.last.total)) >= 0 ? '新增' : '减少'}}库容{{ (parseFloat(data.size.now.total) - parseFloat(data.size.last.total)).toFixed(2)}}万吨，同比{{data.size.now.rate >= 0 ? '增长' : '减少'}}{{data.size.now.rate}}%，库容总量已超过美国。整体来看{{nowYear}}年冷库新建项目很多，其中重要原因是进口猪肉、牛羊肉和各类水产品存储需求量的增长。{{endYear}}-{{nowYear}}六年内冷库容量变化情况。-->
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData2",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {
        // this.startYear = this.year;
        // this.endYear = this.year - 5;
    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/HydataLengku/getYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear, endYear){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataLengku/getData',
                data:{year:startYear},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.volume.list.length;i++) {
                        _bardata.bar.push(res.volume.list[i].total);
                        _bardata.line.push(res.volume.list[i].rate);
                        _bardata.xaxis.push(res.volume.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '冷库容量', type: 'bar', color: 'green', data: _bardata.bar,dw:'万立方米'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }
                }
            })
        },
    }
}
</script>
