<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data1">
            21212
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData7",
    data(){
        return{
            data1:null,
            data2:null,
            startYear:'',
            endYear:''
        }
    },
    props:{
        year:{
            type:[String,Number],
            default:''
        }
    },
    created() {
        this.startYear = this.year;
        this.endYear = this.year - 4;
    },
    mounted() {
        // this.getTotal1();
    },
    methods:{

    }
}
</script>
