<template>
	<div :id="id" :style="{height:height,width:width}"></div>
</template>

<script>
export default {
	name: "PidEcharts",
	data() {
		return {
			id: 'echarts',
			option: {
				title: {
					text: '',
					left: 'center',
					show: false,
					textStyle: {
						fontWeight: 'normal',
						fontSize: 18
					}
				},
				tooltip: {
					trigger: 'item',
					formatter: '{b} : {c} ({d}%)'
				},
				grid: {
					top: 20,
					containLabel: true
				},
				legend: null,
				color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
				series: [
					{
						name: '访问来源',
						type: 'pie',
						radius: ['0%', '70%'],
						data: [],
						label: {
							formatter: '{d}%',
							position: 'inside',
							fontSize: 16
						},
						itemStyle:{
							borderRadius:5
						},
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)',
							}
						}
					}
				]
			}
		}
	},
	props: {
		width: String,
		height: String,
		title: String,
		radius: {
			type: Array,
			default() {
				return ['0%', '70%']
			}
		},
		showLegend: {
			type: Boolean,
			default: true
		},
		legendOrient: {
			type: String,
			default: 'horizontal'
		},
		labelPos: {
			type: String,
			default: 'inside'
		},
		labelFormat: {
			type: String,
			default: '{d}%'
		},
		titlePos: {
			type: String,
			default: 'top'
		},
		titleSize: {
			type: Number,
			default: 18
		},
		labelSize: {
			type: Number,
			default: 14
		},
		showTitle: {
			type: Boolean,
			default: true
		},
		data: {
			type: Array,
			default() {
				return []
			}
		},
		legendPos: {
			type: String,
			default: 'bottom'
		},
		gridBottom: {
			type: Number,
			default: 30
		}
	},
	created() {
		this.id = this.$utils.randomWords(8);

		if (this.titlePos === 'top') {
			this.option.title.top = 0;
		} else if (this.titlePos === 'bottom') {
			this.option.title.bottom = 0;
		}

		this.option.series[0].label.formatter = this.labelFormat;
		this.option.series[0].radius = this.radius;
		this.option.series[0].label.position = this.labelPos;

		this.option.title.textStyle.fontSize = this.titleSize;

		this.option.grid.bottom = this.gridBottom;

		if (this.title) {
			this.option.title.text = this.title;
			this.option.grid.top = 40;
		}

		this.option.title.show = this.showTitle;
		this.option.series[0].label.fontSize = this.labelSize
		this.option.series[0].data = this.data;


	},
	mounted() {
		this.echarts = this.$echarts.init(document.getElementById(this.id));
		this.echarts.setOption(this.option, true);
	},
	methods: {
		initData(data, dw) {
			if (data.length > 0) {
				let _legend = [];
				for (let i = 0; i < data.length; i++) {
					let _data = data[i];

					_legend.push(_data.name);
				}

				if (this.showLegend) {
					this.option.legend = this.$echartsJs.getLegend(_legend, this.legendPos, this.legendOrient);
				}

				this.option.series[0].data = data;

				this.option.tooltip.formatter = '{b} : {c} ' + (dw ? dw : '') + ' ({d}%)'

			}

			this.echarts.setOption(this.option, true);
		}
	}
}
</script>
