<template>
    <div class="cl margin-top-20 border-radius-5 bg-gray-5" :class="$isMobile ? 'padding' : 'padding-20'">
        <el-row :gutter="10" :style="{lineHeight:$isMobile ? '60px' : '50px'}">
            <el-col :md="2" :xs="4" v-if="img">
                <lee-img-icon :size="$isMobile ? 40 : 50" :url="img" class="vm"></lee-img-icon>
            </el-col>
            <el-col :md="img ? ($slots.rightButton ? (rightTitle ? 14 : 18) : 18) : ($slots.rightButton ? (rightTitle ? 16 : 20) : 20)" :xs="img ? ($slots.rightButton ? (rightTitle ? 10 : 14) : 16) : ($slots.rightButton ? 14 : 20)" v-if="title || subTitle || $slots.subTitle">
                <el-row :gutter="10" :style="{lineHeight: $isMobile ? '20px' : ''}">
                    <el-col :md="4" :class="$isMobile ? 'f12 '+titleClass : 'f16 '+titleClass" v-if="title">{{title}}</el-col>
                    <el-col :md="title ? 20 : 24" :class="$isMobile ? 'f12 '+subTitleClass : 'f14 '+subTitleClass" v-if="subTitle && !$slots.subTitle">{{subTitle}}</el-col>
                    <el-col :md="title ? 20 : 24" :class="$isMobile ? 'f12 '+subTitleClass : 'f14 '+subTitleClass" v-else><slot name="subTitle"></slot></el-col>
                </el-row>
            </el-col>
            <el-col :md="4" :xs="6" v-if="$slots.rightButton">
                <slot name="rightButton"></slot>
            </el-col>
            <el-col :md="4" :xs="4" class="warning hand text-right f16" :class="rightTitleClass" v-if="rightTitle" @click.native="click">{{rightTitle}}</el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "BaseCell",
    props:{
        img:{
            type:String,
            default:''
        },
        title:{
            type:String,
            default:''
        },
        subTitle:{
            type:String,
            default:''
        },
        rightTitle:{
            type:String,
            default:''
        },
        subTitleClass:{
            type:String,
            default:''
        },
        titleClass:{
            type:String,
            default:''
        },
        rightTitleClass:{
            type:String,
            default:''
        }
    },
    methods:{
        click(){
            this.$emit('rightClick');
        }
    },
    mounted() {
        if(this.subTitle && !this.$slots.subTitle){
            console.log(true);
        }else{
            console.log(false);
        }
        console.log(this.$slots);
    }
}
</script>
