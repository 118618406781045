<template>
    <div>
        <el-form :model="formData" status-icon :rules="rules" ref="form" hide-required-asterisk>
            <el-form-item class="green margin-bottom" label="收件人姓名" prop="consignee" style="width: 100%;">
                <el-input v-model="formData.consignee" class="lee-input mobile" placeholder="请输入收件人姓名"></el-input>
            </el-form-item>
            <el-form-item class="green margin-bottom" label="收件人电话" prop="phone" style="width: 100%;">
                <el-input v-model="formData.phone" class="lee-input mobile" placeholder="请输入收件人电话"></el-input>
            </el-form-item>
            <el-form-item class="green margin-bottom" label="收件人地址" prop="area_code" style="width: 100%;">
                <el-cascader
                    class="lee-select mobile bg-gray-4 border-radius-5 vm"
                    style="width: 100%;"
                    :options="options"
                    v-model="cityData"
                    @change="handleChange">
                </el-cascader>
            </el-form-item>
            <el-form-item class="green margin-top" label="" prop="address" style="width: 100%;">
                <el-input v-model="formData.address" class="lee-input mobile" placeholder="请输入详细地址"></el-input>
            </el-form-item>
        </el-form>
        <div class="cl" slot="footer">
            <el-button size="medium" type="success" class="box-shadow-green padding-left-50 padding-right-50" :class="$isMobile ? 'block' : ''"  @click="submit('form')">提交确认</el-button>
        </div>
    </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
export default {
    name: "AddOrder",
    data(){
        return{
            cityData:[],
            options: regionData,
            formData:{
                id:'',
                consignee:'',
                phone:'',
                province_code:'',
                province_name:'',
                city_code:'',
                city_name:'',
                area_code:'',
                area_name:'',
                address:'',
                order_type:''
            },
            rules:{
                consignee: [{required: true,message:'收件人必填'}],
                phone: [{required: true,message:'收件人电话必填'}],
                province_code: [{required: true}],
                province_name: [{required: true}],
                city_code: [{required: true}],
                city_name: [{required: true}],
                area_code: [{required: true,message:'地区必须选择'}],
                area_name: [{required: true}],
                address: [{required: true,message:'详细地址必填'}],
            }
        }
    },
    props:{
        id:{
            type:[String,Number],
            default:''
        },
        order_type:{
            type:String,
            default:''
        }
    },
    created() {
        this.formData.id = this.id;
        this.formData.order_type = this.order_type;
    },
    watch:{
        id(n,o){
            this.formData.id = n;
        },
        order_type(n,o){
            this.formData.order_type = n;
        }
    },
    methods:{
        handleChange(e){
            this.formData.province_code = e[0];
            this.formData.city_code = e[1];
            this.formData.area_code = e[2];

            this.formData.province_name = CodeToText[e[0]];
            this.formData.city_name = CodeToText[e[1]];
            this.formData.area_name = CodeToText[e[2]];
        },

        onAddressSubmit(e){
            this.formData.province_code = e.province.code;
            this.formData.city_code = e.city.code;
            this.formData.district_code = e.area.code;

            this.formData.province_name = e.province.name;
            this.formData.city_name = e.city.name;
            this.formData.district_name = e.area.name;
        },

        submit(formName) {
            let _this = this;
            console.log(this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/Order/add',
                        data: this.formData,
                        success(res) {
                            console.log(res);
                            _this.$emit('onPay',res);
                            _this.reset();
                        }
                    })
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        reset(){
            this.formData.consignee='';
            this.formData.phone='';
            this.formData.province_code='';
            this.formData.province_name='';
            this.formData.city_code='';
            this.formData.city_name='';
            this.formData.area_code='';
            this.formData.area_name='';
            this.formData.address='';
            this.cityData = [];
        }
    }
}
</script>
