<template>
	<div class="margin-top-100">
		<div class="text-center f38 dark-1 f-lq divider-green">最新行业报告</div>
		<div class="text-center f24 f-thin dark-3">Latest Industry Report</div>
		<div class="margin-top-50 margin-auto" style="width: 1100px;">
			<el-row :gutter="20" class="margin-top-20">
				<el-col :span="12" v-for="(item,index) in list" class="margin-bottom-20">
					<div class="cl hand" @click="$router.push({path:'/industry/detail/'+item.id})" style="background-color: #f8f8f8;padding:15px;">
						<div class="z">
							<img :src="$utils.formatImagePath(item.head_img)" style="width: 100px;height: 110px;"/>
						</div>
						<div class="z margin-left-20" style="width: 390px;">
							<div class="text-line-1">{{item.title}}</div>
							<div class="f12 dark-3 text-line-3 margin-top">{{item.content}}</div>
							<div class="f12 dark-3 text-right margin-top-20">{{$moment(item.create_time).format('YYYY-MM hh:mm')}}</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	name: "report",
	data() {
		return {
			list:[]
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList(){
			let _this = this;
			this.$http.post({
				url: '/Report/listAll',
				data: {type:'industry',orderBy:'create_time',sortBy:'desc',limit:4},
				success(res) {
					_this.list = res;
				}
			});
		},
	}
}
</script>
