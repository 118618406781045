<template>
    <div>
        <div class="border-bottom f14 padding-bottom margin-top-20">
            全部评论({{totalCount}})
        </div>
        <template v-if="list.length > 0">
            <div class="padding-top-bottom" v-for="(item,index) in list">
                <div>
                    <img :src="item.avatar ? $config.assetsUrl+item.avatar : '/images/avatar.jpeg'" class="border-radius" height="30" width="30"/>
                    <span class="margin-left f14">{{item.nickname}}</span>
                    <span class="margin-left-30 dark-3 f14">{{item.create_time}}</span>
                </div>
                <div style="padding-left:40px;" class="f14 dark-2 hh">
                    {{item.content}}
                </div>
            </div>
<!--            <div class="text-center margin-top-100" v-if="!$isMobile">-->
<!--                <el-pagination-->
<!--                    :hide-on-single-page="true"-->
<!--                    background-->
<!--                    layout="pager"-->
<!--                    :page-size="pageSize"-->
<!--                    :current-page.sync="page"-->
<!--                    :total="totalCount"-->
<!--                    @current-change="pageChange">-->
<!--                </el-pagination>-->
<!--            </div>-->
        </template>
        <div class="text-center padding-top-bottom-40 dark-3" v-else>
            暂无评论
        </div>
        <div class="border-bottom f14 padding-bottom margin-top-20">
            发表评论
        </div>
        <template v-if="$user.getToken()">
            <div class="padding-top-bottom">
                <img :src="$user.getAvatar() ? $config.assetsUrl+$user.getAvatar() : '/images/avatar.jpeg'" class="border-radius" height="30" width="30"/>
                <span class="margin-left f14">{{$user.getNickName()}}</span>
            </div>
            <div class="bg-gray-4 cl">
                <el-input
                    class="lee-textarea"
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                    v-model="formData.content">
                </el-input>
                <el-button size="small" type="success" class="y border-radius-0" @click="add">发布</el-button>
            </div>
        </template>
        <div class="text-center padding-top-bottom-40" v-else>
            <el-button type="success" class="border-radius-0" @click="showLogin = true">登录后，可发表评论</el-button>
        </div>
      <login-dialog v-model="showLogin"></login-dialog>
    </div>
</template>

<script>
export default {
    name: "CommentView",
    data(){
        return{
          showLogin:false,
            formData:{
                content:''
            },
            totalCount:0,
            page:1,
            pageSize:10,
            list:[]
        }
    },
    props:{
        id:{
            type:[Number,String],
            default:0
        },
        akey:{
            type:String,
            default:'',
            isRequired:true
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.post({
                url: '/UserLog/listPage',
                data: {page:this.page,akey:this.akey,tid:this.id,state:1,uid:this.$user.getUid(),type:'reply'},
                success(res) {
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.getList();
        },
        add(){
            let _this = this;
            if(this.formData.content.trim()) {
                this.$http.post({
                    url: '/UserLog/add',
                    data: {akey: this.akey, content: this.formData.content, tid: this.id,type:'reply'},
                    success(res) {
                        _this.page = 1;
                        _this.getList();
                        _this.formData.content = '';
                    }
                });
            }else{
                this.formData.content = '';
                this.$message.error('请输入内容');
            }
        }
    }
}
</script>
