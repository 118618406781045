<template>
    <div>
        <div class="text-center f24 light f-lq padding-top-50" style="height: 120px;background-image: url('/images/bg_head_9.jpg');background-repeat: no-repeat;background-size: 100% 100%;">支付成功</div>
        <div class="text-center margin-top-50">
            <i class="el-icon-success green" style="font-size: 100px;"></i>
            <div class="f16 padding-top-bottom-40">支付成功</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pay",
    data(){
        return{
            info:null,
        }
    },
}
</script>
