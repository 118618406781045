<template>
	<el-form :model="formData" status-icon :rules="rules" ref="form" label-position="left" label-width="auto">
		<el-form-item label="企业名称:" prop="company_name">
			<el-input v-model="formData.company_name" class="lee-input mobile" placeholder="请输入企业名称" maxlength="20" show-word-limit></el-input>
		</el-form-item>
		<el-form-item label="统一社会信用代码:" prop="company_code" class="padding-top">
			<el-input v-model="formData.company_code" class="lee-input mobile" placeholder="请输入统一社会信用代码" maxlength="20" show-word-limit></el-input>
		</el-form-item>
		<el-form-item label="营业执照:" prop="files_ids" class="padding-top">
			<one-img-upload v-model="formData.files_ids"></one-img-upload>
		</el-form-item>
		<el-form-item>
			<div class="margin-top-20">
				<el-button type="success" @click="submit" class="box-shadow-green">提交</el-button>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
export default {
	name: "addCompany",
	data() {
		return {
			formData:{
				company_name:'',
				company_code:'',
				files_ids:'',
			},
			rules:{
				company_name:[{required:true,message:'请填写企业名称'}],
				company_code:[{required:true,message:'请填写统一社会信用代码'}],
				files_ids:[{required:true,message:'请上传营业执照'}]
			}
		}
	},
	created() {
	},
	mounted() {
		this.getMyCompany();
	},
	methods: {
		submit(){
			let _this = this;
			this.$refs.form.validate(valid => {
				if (valid) {
					if(_this.formData.id){
						_this.$http.post({
							url: '/UserCompany/edit',
							data: _this.formData,
							success(res) {
								_this.$emit('success')
							}
						})
					}else {
						_this.$http.post({
							url: '/UserCompany/add',
							data: _this.formData,
							success(res) {
								_this.$emit('success')
							}
						})
					}
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		getMyCompany(){
			let _this = this;
			this.$http.post({
				url: '/UserCompany/myCompany',
				data: null,
				success(res) {
					if(res) {
						res.files_ids = res.files_ids && res.files_ids.length > 0 ? res.files_ids[0] : '';
						_this.formData = res;
					}
				}
			});
		},
	}
}
</script>
