<template>
    <lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" :title="data.title" :bread="[{title:'政策标准',url:'/zhengce'},{title:data.policy.name,url:'/zhengce/list/'+data.type+'/'+data.type_policy_id},{title:data.title}]" v-if="data">
        <div class="bg-img-2 padding-bottom-400 padding-top-100" style="background-color: #f8f9fa;">
            <div class="wrap1100">
                <div class="box-shadow bg-light padding-30">
                    <div class="green-line">
                        <div class="cl f34">
                            <img src="/images/icon/icon_30.png" height="22" class="margin-right no-v-mid"/>{{data.title}}
                        </div>
                        <el-row :gutter="20" class="dark-3 margin-top f14">
<!--                            <el-col :md="5" :xs="12" v-if="data.department">发布机构：{{data.department.name}}</el-col>-->
                          <template v-if="data.type === 'standard'">
                            <el-col :md="8" :xs="12" v-if="data.doc_number" class="margin-bottom">文号：{{data.doc_number}}</el-col>
                            <el-col :md="8" :xs="12" v-if="data.push_time" class="margin-bottom">发布时间：{{data.push_time}}</el-col>
                            <el-col :md="8" :xs="12" v-if="data.todo_time" class="margin-bottom">实施时间：{{data.todo_time}}</el-col>
                            <el-col :md="8" :xs="12" v-if="data.department">
                              <el-tooltip effect="dark" :content="data.department.name" placement="top">
                                <div class="text-line-1">发布机构：{{data.department.name}}</div>
                              </el-tooltip>
                            </el-col>
                            <el-col :md="8" :xs="12" v-if="data.type === 'standard'">状态：<template v-if="data.push_state === 0">废止</template><template v-if="data.push_state === 1">有效</template><template v-if="data.push_state === 2">修订</template></el-col>
                          </template>
                          <template v-else>
                            <el-col :md="8" :xs="12" v-if="data.department">
                              <el-tooltip effect="dark" :content="data.department" placement="top">
                                <div class="text-line-1">发布机构：{{data.department}}</div>
                              </el-tooltip>
                            </el-col>
                            <el-col :md="8" :xs="12" v-if="data.doc_number">文号：{{data.doc_number}}</el-col>
                            <el-col :md="8" :xs="12" v-if="data.push_time">发布时间：{{data.push_time}}</el-col>
                            <el-col :md="8" :xs="12" v-if="data.todo_time">实施时间：{{data.todo_time}}</el-col>
                          </template>
                        </el-row>
											<div class="margin-top" v-if="data.yuanwen_url || data.jiedu_url">
												<a style="background-color: #3288fd;color:#fff;padding:5px 10px;border-radius: 3px;" :href="data.yuanwen_url.indexOf('http') >= 0 ? data.yuanwen_url : 'http://'+data.yuanwen_url" class="f14" v-if="data.yuanwen_url" target="_blank">阅读原文 &gt;&gt;</a>
												<a style="margin-left:20px;background-color: #3288fd;color:#fff;padding:5px 10px;border-radius: 3px;" :href="data.jiedu_url.indexOf('http') >= 0 ? data.jiedu_url : 'http://'+data.jiedu_url" class="f14" v-if="data.jiedu_url" target="_blank">政策解读 &gt;&gt;</a>
											</div>
                    </div>
                  <template v-if="data.check.type === 'ok'">
                    <div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''"></div>
                    <div class="margin-top-20" v-if="data.files.length > 0">
                      <lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="standard" :tid="data.id"></lee-vip-file-down>
                    </div>
	                  <lee-good-view class="margin-top-50" :id="data.id" :akey="data.type" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1" :open-comment="data.open_comment === 1"></lee-good-view>
                  </template>
                  <vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买政策标准详情" pay-sub-title="政策标准详情金额" type="policy" v-else></vip-check-button>
                </div>
	            <template v-if="data.check.type === 'ok'">
		            <div class="box-shadow padding-30 bg-light margin-top-30" v-if="data.open_comment === 1">
			            <lee-comment-view total-count="30" :akey="data.type" :id="data.id"></lee-comment-view>
		            </div>
	            </template>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            title:'',
            type:'',
            id:'',
            data:null,
        }
    },
    created() {
        this.type = this.$route.params.type;
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        getDetail(){
            let _this = this;

            if(this.id) {
                this.$http.post({
                    url: '/Policy/detail',
                    data: {id: this.id},
                    success(res) {
                        if(res){
                            _this.data = res;
                        }
                    }
                });
            }
        },
    }
}
</script>
