<template>
    <lee-page bg-img="/images/bg_head_6.jpg" title="关于我们" sub-title="About Us" des="" :bread="[{title:'关于我们'}]">
        <div :class="$isMobile ? 'padding-left-right' : 'bg-img-1 padding-bottom-100'">
            <div class="cl"></div>
<!--          <lee-tabs :list="tabList" :default-index="mm_type" @change="onTabChange" class="margin-auto margin-top-30" style="width: 500px;"></lee-tabs>-->
            <div :class="$isMobile ? 'title-border-green margin-top-20 f16 text-left' : 'text-center margin-top-50 f38 divider-green f-lq'" class="dark-2">协会简介<span v-if="$isMobile" class="margin-left-20 f-thin dark-3">Association Profile</span></div>
            <div class="text-center f24 f-thin dark-3 margin-top" v-if="!$isMobile">Association Profile</div>
            <div class="dark-3" :class="$isMobile ? 'margin-top f14' : 'f18 margin-top-50 wrap1100'" v-html="aboutus"></div>
            <div class="margin-auto" :class="$isMobile ? 'margin-top-20 padding-bottom-20' : 'margin-top-100'" :style="{width:$isMobile ? '' : '980px'}">
                <el-row :gutter="$isMobile ? 10 : 100">
                    <el-col :span="8">
                        <div class="bg-light border-radius-5 about-tab-item box-shadow" :class="$isMobile ? 'padding' : 'padding-50'">
                            <div class="">
                                <img src="/images/icon/icon_21.png" :height="$isMobile ? 30 : 56"/>
                            </div>
                            <div class="margin-top">
                                <i class="green" :class="$isMobile ? 'f30' : 'f70'">{{policyCount}}</i>
                                <b class="f-n" :class="$isMobile ? 'f14' : 'f28'">+</b>
                            </div>
                            <div class="dark-1 f-thin margin-top" :class="$isMobile ? 'f14' : 'f24'">政策标准</div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="bg-light border-radius-5 about-tab-item box-shadow" :class="$isMobile ? 'padding' : 'padding-50'">
                            <div class="">
                                <img src="/images/icon/icon_22.png" :height="$isMobile ? 30 : 56"/>
                            </div>
                            <div class="margin-top">
                                <i class="green" :class="$isMobile ? 'f30' : 'f70'">{{hydataCount}}</i>
                                <b class="f-n" :class="$isMobile ? 'f14' : 'f28'">+</b>
                            </div>
                            <div class="dark-1 f-thin margin-top" :class="$isMobile ? 'f14' : 'f24'">行业数据</div>
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="bg-light border-radius-5 about-tab-item box-shadow" :class="$isMobile ? 'padding' : 'padding-50'">
                            <div class="">
                                <img src="/images/icon/icon_23.png" :height="$isMobile ? 30 : 56"/>
                            </div>
                            <div class="margin-top">
                                <i class="green" :class="$isMobile ? 'f30' : 'f70'">{{reportCount}}</i>
                                <b class="f-n" :class="$isMobile ? 'f14' : 'f28'">+</b>
                            </div>
                            <div class="dark-1 f-thin margin-top" :class="$isMobile ? 'f14' : 'f24'">研究报告</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="bg-img-8 padding-bottom-100">
            <div class="cl"></div>
            <div :class="$isMobile ? 'margin-left title-border-green margin-top-20 f16 text-left' : 'text-center margin-top-50 f38 divider-green f-lq'" class="light">合作伙伴<span v-if="$isMobile" class="margin-left-20 f-thin light">Cooperation Partners</span></div>
            <div class="text-center f24 f-thin light margin-top" v-if="!$isMobile">Cooperation Partners</div>
            <div class="margin-auto margin-top-80" style="width: 1300px;" v-if="!$isMobile && kehuList.length > 0">
                <el-row :gutter="20">
                    <el-col :span="4" v-for="(item,index) in kehuList" class="margin-bottom-20"><img :src="$config.assetsUrl + item.head_img" width="200" height="108"/></el-col>
                </el-row>
            </div>
        </div>
        <div class="bg-light" :class="$isMobile ? '' : 'padding-bottom-100'">
            <div :class="$isMobile ? 'margin-left title-border-green margin-top-20 f16 text-left' : 'text-center margin-top-50 f38 divider-green f-lq'" class="dark-2">我们的团队<span v-if="$isMobile" class="margin-left-20 f-thin dark-3">Our Team</span></div>
            <div class="text-center f24 f-thin dark-3 margin-top" v-if="!$isMobile">Our Team</div>
            <div class="wrap1250" v-if="!$isMobile">
                <lee-swiper class="margin-top-50 padding-top-bottom" height="275">
                    <el-carousel-item v-for="(item,index) in teamList" :key="item.id" class="box-shadow bg-light padding-20 margin-top-bottom" style="height: 255px;">
                        <img :src="$config.assetsUrl + item.head_img" width="475" height="215"/>
                    </el-carousel-item>
                </lee-swiper>
            </div>
            <div v-else class="padding-left-right margin-top">
                <el-row :gutter="10">
                    <el-col :span="item % 2 === 0 ? 8 : 16" v-for="item in 2"><img src="/images/banner_8.jpg" width="100%" height="115"></el-col>
                    <el-col class="margin-top" :span="item % 2 === 0 ? 16 : 8" v-for="item in 2"><img src="/images/banner_8.jpg" width="100%" height="115"></el-col>
                </el-row>
            </div>
        </div>
        <div class="bg-light" :class="$isMobile ? '' : 'padding-bottom-400'">
            <div :class="$isMobile ? 'margin-left title-border-green margin-top-20 f16 text-left' : 'text-center margin-top-50 f38 divider-green f-lq'" class="dark-2">大事记<span v-if="$isMobile" class="margin-left-20 f-thin dark-3">Memorabilia</span></div>
            <div class="text-center f24 f-thin dark-3 margin-top" v-if="!$isMobile">Memorabilia</div>
            <div class="wrap1250">
                <lee-time-line :list="timeLineList" class="margin-top-50"></lee-time-line>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            timeLineList:[],
            aboutus:'',
            reportCount:0,
            policyCount:0,
            hydataCount:0,
            kehuList:[],
            teamList:[],
          mm_type:'chain',
          tabList:[
            {label:'冷链',value:'chain',icon:'/images/icon/icon_57_'},
            {label:'食材供应链',value:'agriculture',icon:'/images/icon/icon_58_'},
          ],
        }
    },
    mounted() {
        this.getPolicyTotal();
        this.getReportTotal();
        this.getHydataTotal();
        this.getKehu();
        this.getTeam();
      this.getTime();
      this.getAboutUs();
    },
    methods:{
      onTabChange(i){
        this.mm_type = i.value;
        this.getKehu();
        this.getTeam();
        this.getTime();
        this.getAboutUs();
      },
        getKehu(){
            let _this = this;
            this.$http.post({
                url:'/AboutKehu/listAll',
                data:{orderBy:'sort',sortBy:'asc',limit:12,state:1,mm_type:this.mm_type},
                success(res){
                    _this.kehuList = res;
                }
            })
        },
        getTeam(){
            let _this = this;
            this.$http.post({
                url:'/AboutTeam/listAll',
                data:{orderBy:'sort',sortBy:'asc',state:1,mm_type:this.mm_type},
                success(res){
                    _this.teamList = res;
                }
            })
        },
        getTime(){
            let _this = this;
            this.$http.post({
                url:'/AboutHistory/listAll',
                data:{orderBy:'year',sortBy:'desc',mm_type:this.mm_type},
                success(res){
                    _this.timeLineList = res;
                }
            })
        },
        getPolicyTotal(){
            let _this = this;
            this.$http.post({
                url:'/Policy/count',
                data:null,
                success(res){
                    _this.policyCount = res.count;
                }
            })
        },
        getReportTotal(){
            let _this = this;
            this.$http.post({
                url:'/Report/count',
                data:null,
                success(res){
                    _this.reportCount = res.count;
                }
            })
        },
        getHydataTotal(){
            let _this = this;
            this.$http.post({
                url:'/HydataTotal/count',
                data:null,
                success(res){
                    _this.hydataCount = res.count;
                }
            })
        },
        getAboutUs(){
            let _this = this;

            let _data = {skey:'about'};

            if(this.mm_type === 'chain') {
              _data.skey = 'about';
            }else{
              _data.skey = 'about_ncp';
            }
            this.$http.post({
                url:'/SystemSetting/detail',
                data:_data,
                success(res){
                    _this.aboutus = res.svalue;
                }
            })
        }
    }
}
</script>

<style scoped>
.about-tab-item:hover{border:5px solid #7ED321;padding:45px !important;}
</style>
