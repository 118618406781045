<template>
    <div class="bg-green box-shadow">
        <div class="cl">
            <div class="z">32</div>
            <div class="z">
                <div v-if="title">{{title}}</div>
                <div v-if="subTitle">{{subTitle}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlockItem",
    props:{
        icon:String,
        title:String,
        subTitle:String
    }
}
</script>
