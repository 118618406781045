<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" :back="false" title="项目申报" :bread="[{title:'项目',url:'/prize'},{title:'项目申报'}]" v-if="info">
		<div class="bg-img-11 padding-top-100 padding-bottom-400">
			<div class="wrap1100">
				<div class="box-shadow bg-light padding-30" v-if="info">
					<div class="green-line">
						<div class="cl f34">
							<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
							{{ info.jiangxiang.name }}-{{info.year}}年
						</div>
					</div>
					<template v-if="info.state === 0">
						<div class="text-center f16 dark-3" style="margin: 100px auto;">
							<div>
								<i class="orange margin-right el-icon-warning"></i>当前项目还不可申报，请耐心等待
							</div>
							<el-button type="success" class="margin-top-30 box-shadow-green" @click="$router.replace({path:'/'})">返回首页</el-button>
						</div>
					</template>
					<template v-else>
						<template v-if="token">
							<div class="f16 dark-1" v-html="info.html_content"></div>
							<div class="cl dark-2 f20">
								<img src="/images/icon/icon_62.png" height="20"/>
								项目简介
							</div>
							<div v-html="info.prize_info" class="f-thin dark-1 padding-top-bottom"></div>
							<div class="cl dark-2 f20 margin-top-20">
								<img src="/images/icon/icon_62.png" height="20"/>
								申报要求
							</div>
							<div v-html="info.prize_need" class="f-thin dark-1 padding-top-bottom"></div>
							<div class="cl dark-2 f20 margin-top-20">
								<img src="/images/icon/icon_62.png" height="20"/>
								项目设置
							</div>
							<div v-html="info.prize_setting" class="f-thin dark-1 padding-top-bottom"></div>
							<div class="text-center margin-top-20">
								<el-button type="success" class="box-shadow-green" @click="$router.push({path:'/prize/join/form',query:{id:id}})">开始申报</el-button>
							</div>
						</template>
						<div class="text-center f16 dark-3" style="margin: 100px auto;" v-else>
							<i class="orange margin-right el-icon-warning"></i>
							请先登录
							<login-button></login-button>
						</div>
					</template>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			id: '',
			info: null,
			token:null,
		}
	},
	created() {
		this.id = this.$route.query.id;
		this.token = this.$user.getToken();
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/Prize/detail',
				data: {id: this.id},
				success(res) {
					_this.info = res;
				}
			});
		},
	}
}
</script>
