<template>
	<div v-if="info">
		<template v-if="info.is_check === 1">
			<div class="f14 padding-20" style="background-color: #f4f4f4;color:#333;">
				<el-row :gutter="10">
					<el-col :span="24">
						<div style="height: 64px;line-height: 64px;">审核状态：<b class="green f18 f-n">已通过</b></div>
					</el-col>
					<el-col :span="12">
						<div style="height: 64px;line-height: 64px;">企业名称：<b class="green f18 f-n">{{info.company_name}}</b></div>
					</el-col>
					<el-col :span="12">
						<div style="height: 64px;line-height: 64px;">统一社会信用代码：<b class="green f18 f-n">{{info.company_code}}</b></div>
					</el-col>
					<el-col :span="12">
						<div style="height: 64px;line-height: 64px;">我的角色：<b class="green f18 f-n">{{info.user_type_name}}</b></div>
					</el-col>
					<el-col :span="12" v-if="info.files_ids">
						营业执照：<el-image :src="$utils.formatImagePath(info.files_ids)" style="width: 64px;height: 64px;vertical-align: middle;" :preview-src-list="[$utils.formatImagePath(info.files_ids)]"></el-image>
					</el-col>
				</el-row>
			</div>
		</template>
		<template v-else>
			<el-form ref="form" label-position="left" label-width="auto">
				<el-form-item label="审核状态:">
					<el-tag type="success" effect="dark" v-if="info.is_check === 1">已通过</el-tag>
					<el-tag type="danger" effect="dark" v-if="info.is_check === 2">被拒绝</el-tag>
					<el-tag type="warning" effect="dark" v-if="info.is_check === 0">待审核</el-tag>
				</el-form-item>
				<el-form-item label="拒绝原因:" v-if="info.is_check === 2 && info.reason">
					<div>{{info.reason}}</div>
				</el-form-item>
				<el-form-item label="企业名称:">
					<div class="f20">{{info.company_name}}</div>
				</el-form-item>
				<el-form-item label="统一社会信用代码:">
					<div class="f20">{{info.company_code}}</div>
				</el-form-item>
				<el-form-item label="营业执照:">
					<el-image :src="$utils.formatImagePath(info.files_ids)" style="width: 148px;height: 148px;" :preview-src-list="[$utils.formatImagePath(info.files_ids)]"></el-image>
				</el-form-item>
				<el-form-item v-if="info.is_check === 2">
					<div class="margin-top-20">
						<el-button type="success" @click="toReSubmit" class="box-shadow-green">修改并重新提交</el-button>
					</div>
				</el-form-item>
			</el-form>
		</template>
	</div>
</template>

<script>
export default {
	name: "companyInfo",
	data() {
		return {
			info:null
		}
	},
	props:{
		data:{
			type:Object,
			default:null
		}
	},
	watch:{
		'data':{
			handler: function (n, o) {
				this.info = n;
			},
			deep: true,
			immediate:true
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toReSubmit(){
			this.$emit('toReSubmit')
		}
	}
}
</script>
