<template>
	<lee-user-main-page type="dark">
		<lee-user-page class="padding-bottom-100">
<!--			{{formData}}-->
			<el-steps :active="step" finish-status="success" simple>
				<el-step title="选择订单" icon="el-icon-edit"></el-step>
				<el-step title="填写开票信息" icon="el-icon-upload"></el-step>
			</el-steps>
			<template v-if="step === 0">
				<el-table :data="list" @selection-change="handleSelectionChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column label="订单编号" prop="order_no" width="180px"></el-table-column>
					<el-table-column label="订单描述" prop="order_body"></el-table-column>
					<el-table-column label="实付金额" prop="pay_amount" width="100px"></el-table-column>
					<el-table-column label="支付时间" prop="pay_time" width="180px">
						<template slot-scope="scope">
							{{$moment.unix(scope.row.pay_time).format('YYYY-MM-DD HH:mm:ss')}}
						</template>
					</el-table-column>
				</el-table>
				<div class="text-right margin-top">
					开票金额：￥<b class="f16 red">{{formData.pay_amount}}</b>
					<el-button class="margin-left" type="primary" @click="toNext">下一步</el-button>
				</div>
			</template>
			<template v-if="step === 1">
				<el-form :model="formData" label-width="150px" ref="form" :rules="rules">
					<el-form-item prop="open_type" label="开票类型">
						<el-radio-group v-model="formData.open_type">
							<el-radio label="person">个人</el-radio>
							<el-radio label="company">企业</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item prop="type" label="发票类型">
						<el-radio-group v-model="formData.type">
							<el-radio label="email">电子发票</el-radio>
							<el-radio label="paper">纸质发票</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item prop="title" label="发票名称">
						<el-input v-model="formData.title" autocomplete="off" placeholder="请填写发票名称"></el-input>
					</el-form-item>
					<el-form-item prop="number" label="纳税人识别号" :rules="[{required:true,message:'请填写纳税人识别号'},{pattern:$regexp.TINumber,message:'请填写正确的发票税号'}]" v-if="formData.open_type === 'company'">
						<el-input v-model="formData.number" autocomplete="off" placeholder="请填写发票税号"></el-input>
					</el-form-item>
					<el-form-item prop="program" label="发票事项">
						<el-input v-model="formData.program" autocomplete="off" placeholder="请填写发票事项"></el-input>
					</el-form-item>
					<template v-if="formData.type === 'email'">
						<el-form-item prop="email" label="电子邮箱" :rules="[{required:true,message:'请填写电子邮箱'},{pattern:$regexp.email,message:'请填写正确的邮箱地址'}]">
							<el-input v-model="formData.email" autocomplete="off" placeholder="请填写电子邮箱"></el-input>
						</el-form-item>
					</template>
					<template v-else>
						<el-form-item prop="consignee" label="收件人" :rules="[{required:true,message:'请填写收件人'}]">
							<el-input v-model="formData.consignee" autocomplete="off" placeholder="请填写收件人"></el-input>
						</el-form-item>
						<el-form-item prop="phone" label="收件人电话" :rules="[{required:true,message:'请填写收件人电话'},{pattern:$regexp.mobile,message:'请填写正确的收件人电话'}]">
							<el-input v-model="formData.phone" autocomplete="off" placeholder="请填写收件人电话"></el-input>
						</el-form-item>
						<el-form-item prop="address" label="收件地址" :rules="[{required:true,message:'请填写收件地址'}]">
							<el-input v-model="formData.address" autocomplete="off" placeholder="请填写收件地址"></el-input>
						</el-form-item>
					</template>
					<div v-show="showMore" v-if="formData.open_type === 'company'">
						<el-form-item prop="company_adr" label="单位地址(选填)">
							<el-input v-model="formData.company_adr" autocomplete="off" placeholder="请填写单位地址(选填)"></el-input>
						</el-form-item>
						<el-form-item prop="company_phone" label="单位电话(选填)">
							<el-input v-model="formData.company_phone" autocomplete="off" placeholder="请填写单位电话(选填)"></el-input>
						</el-form-item>
						<el-form-item prop="company_bank" label="开户银行(选填)">
							<el-input v-model="formData.company_bank" autocomplete="off" placeholder="请填写开户银行(选填)"></el-input>
						</el-form-item>
						<el-form-item prop="company_account" label="银行账户(选填)">
							<el-input v-model="formData.company_account" autocomplete="off" placeholder="请填写银行账户(选填)"></el-input>
						</el-form-item>
					</div>
					<el-divider class="hand" @click="showMore = !showMore" v-if="formData.open_type === 'company'">{{ showMore ? '隐藏' : '显示更多' }}
						<i class="margin-left-5" :class="showMore ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
					</el-divider>
				</el-form>
				<div class="text-center">
					<el-button @click="step = 0">上一步</el-button>
					<el-button type="primary" @click="submit">提交</el-button>
				</div>
			</template>
		</lee-user-page>
	</lee-user-main-page>
</template>

<script>
export default {
	name: "add",
	data() {
		return {
			list:[],
			formData:{
				id:'',
				open_type: 'person',
				type: 'email',
				address: '',
				number: '',
				consignee: '',
				phone: '',
				program: '',
				title: '',
				email: '',
				company_adr: '',
				company_phone: '',
				company_bank: '',
				company_account: '',
				akey: '',
				tid: '',
				pay_amount:0,
				order_ids:[]
			},
			rules: {
				program: [{required: true, message: '请填写发票事项'}],
				title: [{required: true, message: '请填写发票名称'}]
			},
			step:0,
			showMore:false,
			less_kaipiao_price:0
		}
	},
	created() {
		this.formData.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if(this.formData.id){
			this.getDetail();
		}
		
		this.getInvoice();
		this.getList();
		this.getSetting();
	},
	methods: {
		getDetail(){
			let _this = this;
			
		},
		submit() {
			let _this = this;
			this.$refs['form'].validate(valid => {
				if (valid) {
					let _form = JSON.parse(JSON.stringify(_this.formData));
					if (_form.type === 'email') {
						_form.address = '';
						_form.consignee = '';
						_form.phone = '';
					} else {
						_form.email = '';
					}
					
					_this.$http.post({
						url: '/UserInvoiceLog/add',
						data: _form,
						success(res) {
							_this.$message.success('发票申请提交成功');
							// _this.showTicket = false;
							// _this.getList();
							_this.$router.replace({path:'/user/fapiao'});
						}
					});
				}
			});
		},
		toNext(){
			if(this.formData.pay_amount <= 0){
				this.$message.error('开票金额不能为0');
				return;
			}
			
			if(this.formData.pay_amount < this.less_kaipiao_price){
				this.$message.error('最低开票金额不能低于'+this.less_kaipiao_price+'元');
				return;
			}
			
			this.step = 1;
		},
		handleSelectionChange(e){
			console.log(e);
			this.formData.pay_amount = 0;
			
			let _ids = [];
			
			for (let i=0;i < e.length;i++){
				_ids.push(e[i].id);
				this.formData.pay_amount += parseFloat(e[i].pay_amount);
			}
			
			this.formData.pay_amount = this.formData.pay_amount.toFixed(2);
			
			this.formData.order_ids = _ids;
		},
		getSetting() {
			let _this = this;
			
			this.$http.post({
				url: '/SystemSetting/getSystem',
				data:{skey:'less_kaipiao_price'},
				success(res) {
					_this.less_kaipiao_price = parseFloat(res.less_kaipiao_price);
				}
			});
		},
		getList(){
			let _this = this;
			this.$http.post({
				url: '/User/getMyCanInvoiceOrder',
				data: null,
				success(res) {
					_this.list = res;
				}
			});
		},
		getBase() {
			let _this = this;
			this.$http.post({
				url: '/UserProfile/getBase',
				data: null,
				success(res) {
					_this.formData.email = res.email;
				}
			});
		},
		getInvoice() {
			let _this = this;
			this.$http.post({
				url: '/UserInvoice/detail',
				data: null,
				success(res) {
					_this.formData.address = res.address;
					_this.formData.consignee = res.consignee;
					_this.formData.phone = res.phone;
					_this.formData.program = res.program;
					_this.formData.title = res.title;
					_this.formData.number = res.company_number;
					_this.formData.company_account = res.company_account;
					_this.formData.company_adr = res.company_adr;
					_this.formData.company_bank = res.company_bank;
					_this.formData.company_phone = res.company_phone;
				}
			});
		},
	}
}
</script>
