import Vue from 'vue';
let vm = new Vue();

import Config from './config'
const provincePy = {'湖北':'hubei','湖南':'hunan','重庆':'chongqing','四川':'sichuan','北京':'beijing','新疆':'xinjiang','西藏':'xizang','内蒙古':'mengku','辽宁':'liaoning','吉林':'jilin','黑龙江':'heilongjiang','天津':'tianjing','河北':'hebei','上海':'shanghai','江苏':'jiangsu','浙江':'zhejiang','福建':'fujian','山东':'shandong','广东':'guangdong','山西':'shanxi2','安徽':'anhui','江西':'jiangxi','河南':'henan','广西':'guangxi','贵州':'guizhou','云南':'yunnan','陕西':'shanxi','甘肃':'gansu','青海':'qinghai','海南':'hainan','宁夏':'ningxia'};
const provinceName = {'liaoning':'辽宁', 'jilin':'吉林', 'heilongjiang':'黑龙江', 'beijing':'北京', 'tianjing':'天津', 'hebei':'河北', 'shanghai':'上海', 'jiangsu':'江苏', 'zhejiang':'浙江', 'fujian':'福建', 'shandong':'山东', 'guangdong':'广东', 'shanxi2':'山西', 'anhui':'安徽', 'jiangxi':'江西', 'henan':'河南', 'hubei':'湖北', 'hunan':'湖南', 'menggu':'内蒙古', 'guangxi':'广西', 'chongqing':'重庆', 'sichuan':'四川', 'guizhou':'贵州', 'yunnan':'云南', 'xizang':'西藏', 'shanxi':'陕西', 'gansu':'甘肃', 'ningxia':'宁夏', 'xinjiang':'新疆', 'qinghai':'青海', 'haihan':'海南'}

const marketCityName = {'beijing':'北京','haerbin':'哈尔滨', 'kunming':'昆明', 'guiyang':'贵阳', 'zhanjiang':'湛江', 'shenzhen':'深圳', 'guangzhou':'广州', 'chongqing':'重庆', 'shenyang':'沈阳', 'zhengzhou':'郑州', 'nanning':'南宁', 'chengdu':'成都', 'xiamen':'厦门', 'wuhan':'武汉', 'shanghai':'上海', 'nanjing':'南京', 'wuxi':'无锡', 'suzhou':'苏州', 'xian':'西安', 'changsha':'长沙', 'hangzhou':'杭州', 'qingdao':'青岛', 'jinan':'济南', 'shijiazhuang':'石家庄', 'taiyuan':'太原', 'huhehaote':'呼和浩特', 'nanchang':'南昌', 'hefei':'合肥', 'dalian':'大连', 'haikou':'海口'}

const marketPriceCityName = {'beijing':'北京','haerbin':'哈尔滨', 'kunming':'昆明', 'guiyang':'贵阳', 'shenzhen':'深圳', 'guangzhou':'广州', 'chongqing':'重庆', 'shenyang':'沈阳', 'zhengzhou':'郑州', 'nanning':'南宁', 'chengdu':'成都', 'wuhan':'武汉', 'shanghai':'上海', 'nanjing':'南京', 'xian':'西安', 'changsha':'长沙', 'hangzhou':'杭州', 'qingdao':'青岛', 'jinan':'济南', 'shijiazhuang':'石家庄', 'taiyuan':'太原', 'huhehaote':'呼和浩特', 'nanchang':'南昌', 'hefei':'合肥', 'haikou':'海口','xining':'西宁','changchun':'长春','wulumuqi':'乌鲁木齐','lanzhou':'兰州','tianjin':'天津','yinchuan':'银川','dongguan':'东莞'}

// import { JSEncrypt } from 'jsencrypt'
// import { JSEncrypt } from 'encryptlong'

export default {
    // provincePy:{'湖北省':'hubei','湖南省':'hunan','重庆市':'chongqing','四川省':'sichuan','北京市':'beijing','新疆维吾尔自治区':'xinjiang','西藏自治区':'xizang','内蒙古自治区':'mengku','辽宁省':'liaoning','吉林省':'jilin','黑龙江省':'heilongjiang','天津省':'tianjing','河北省':'hebei','上海市':'shanghai','江苏省':'jiangsu','浙江省':'zhejiang','福建省':'fujian','山东省':'shandong','广东省':'guangdong','山西省':'shanxi2','安徽省':'anhui','江西省':'jiangxi','河南省':'henan','广西省':'guangxi','贵州省':'guizhou','云南省':'yunnan','陕西省':'shanxi','甘肃省':'gansu'},
    
    //'liaoning'=>'辽宁','jilin'=>'吉林','heilongjiang'=>'黑龙江','beijing'=>'北京','tianjing'=>'天津','hebei'=>'河北','shanghai'=>'上海','jiangsu'=>'江苏','zhejiang'=>'浙江','fujian'=>'福建','shandong'=>'山东','guangdong'=>'广东','shanxi2'=>'山西','anhui'=>'安徽','jiangxi'=>'江西','henan'=>'河南','hubei'=>'湖北','hunan'=>'湖南','menggu'=>'内蒙古','guangxi'=>'广西','chongqing'=>'重庆','sichuan'=>'四川','guizhou'=>'贵州','yunnan'=>'云南','xizang'=>'西藏','shanxi'=>'陕西','gansu'=>'甘肃','ningxia'=>'宁夏','xinjiang'=>'新疆'
    getProvincePy(name){
        return provincePy[name];
    },
    getProvinceName(py){
        return provinceName[py];
    },
    getMarketCityName(py){
        return marketCityName[py];
    },
    getMarketCityList(){
        return marketCityName;
    },
    getMarketPriceCityName(py){
        return marketPriceCityName[py];
    },
    getMarketPriceCityList(){
        return marketPriceCityName;
    },
    formatImagePath(path){
        if(!path){
            return ''
        }
        return path.indexOf('http') >= 0 ? path : Config.assetsUrl + path;
    },
    isEmptyObject(data){
        let arr = Object.keys(data);
        return arr.length === 0;
    },
    formatDecimal(num, decimal) {
        num = num.toString()
        let index = num.indexOf('.')
        if (index !== -1) {
            num = num.substring(0, decimal + index + 1)
        } else {
            num = num.substring(0)
        }
        return parseFloat(num).toFixed(decimal)
    },
    getWxCodeUrl(url){
        var authUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize';
        authUrl += '?appid=' + Config.WX_CONFIG.appId;
        authUrl += '&redirect_uri=' + encodeURIComponent(url);
        authUrl += '&response_type=code';
        authUrl += '&scope=snsapi_base';
        authUrl += '&state=state';
        return authUrl;
    },
    randomWords(n = 6){
        //创建26个字母数组
        let arr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
        let idvalue ='';
        for(let i=0;i<n;i++){
            idvalue+=arr[Math.floor(Math.random()*26)];
        }
        return idvalue;
    },
    isWxImg(img) {
        if (img) {
            if (!/\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(img)) {
                return true;
            }

            return false;
        } else {
            return false;
        }
    },
    secretStr(str){
        if (typeof str == 'number') {
            str = str.toString();
        }
        let _start = str.substr(0, 3);

        let _end = str.substr(-3,3);

        return _start + '****' + _end;
    },
    mustLetter(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /[a-z0-9]$/;
        if (!_v.test(value)) {
            return callback(new Error('必须是小写字母或数字'));
        } else {
            return callback();
        }
    },
    mustNumber(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /(^[1-9]\d*$)/;
        if (!_v.test(value)) {
            return callback(new Error('必须是整数'));
        } else {
            return callback();
        }
    },
    must09(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        // if (!Number.isInteger(value)) {
        //     return callback(new Error('请输入数字值'));
        // }

        if(value.length>1){
            let flagRex =/^0/;
            if(flagRex.test(value)){
                return callback(new Error('请输入正确的数字'));
            }
        }

        let _v = /(^[0-9]\d*$)/;
        if (!_v.test(value)) {
            return callback(new Error('必须是整数'));
        } else {
            return callback();
        }
    },
    biggerZero(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        if (!Number.isInteger(value) && typeof value !== 'number') {
            return callback(new Error('请输入数字值'));
        } else {
            if (value < 0) {
                return callback(new Error('必须>=0'));
            } else {
                return callback();
            }
        }
    },
    biggerZero2(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        // console.log(typeof value);
        if (!Number.isInteger(value) && typeof value !== 'number') {
            return callback(new Error('请输入数字值'));
        } else {
            if (value <= 0) {
                return callback(new Error('必须大于0'));
            } else {
                return callback();
            }
        }
    },
    rulePercent(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;

        if (!_v.test(value)) {
            return callback(new Error('请输入数字值'));
        } else {
            if (parseFloat(value) < 0 || parseFloat(value) > 100) {
                return callback(new Error('必须>=0 并且 <= 100'));
            } else {
                return callback();
            }
        }
    },
    rulePriceNotZero(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;

        if (!_v.test(value)) {
            return callback(new Error('请输入数字值'));
        } else {
            if (parseFloat(value) <= 0) {
                return callback(new Error('必须大于0'));
            } else {
                return callback();
            }
        }
    },
    rulePriceCanZero(rule, value, callback){
        if (value === '') {
            return callback(new Error('不能为空'));
        }

        let _v = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;

        if (!_v.test(value)) {
            return callback(new Error('请输入数字值'));
        } else {
            if (parseFloat(value) < 0) {
                return callback(new Error('必须大于0'));
            } else {
                return callback();
            }
        }
    },
    checkParams(rule,val){
        for(let item in rule){
            if(rule[item].check === 'require'){
                if(this.isUndefined(val[item].trim())){
                    console.error('param '+item+' must be require!');
                    return false;
                }
            }

            if(typeof val[item] !== rule[item].type && !this.isUndefined(val[item])){
                console.error('param '+item+' must be '+rule[item].type+'!');
                return false;
            }
        }
        return true;
    },
    formatPrice(price){
        return (Math.floor(Number(price) * 100) / 100).toFixed(2);
    },
    isUndefined(variable) {
        return (typeof variable == 'undefined' || variable === null || variable === '');
    },
    sessionSet(key,val){
        if(typeof key === 'string') {
            val = val ? val : (val === null ? '' : val);
            return sessionStorage.setItem(key, val);
        }else{
            console.error('value & key must be a string!');
        }
        return false;
    },
    sessionGet(key){
        if(typeof key === 'string'){
            return sessionStorage.getItem(key);
        }else{
            console.error('key must be a string!');
        }
        return false;
    },
    sessionDel(key){
        if(typeof key === 'string'){
            return sessionStorage.removeItem(key);
        }else{
            console.error('key must be a string!');
        }
        return false;
    },
    sessionClear(){
        return sessionStorage.clear();
    },

    localSet(key,val){
        if(typeof key === 'string') {
            val = val ? val : (val === null ? '' : val);
            return localStorage.setItem(key, val);
        }else{
            console.error('value & key must be a string!');
        }
        return false;
    },
    localGet(key){
        if(typeof key === 'string'){
            return localStorage.getItem(key);
        }else{
            console.error('key must be a string!');
        }
        return false;
    },
    localDel(key){
        if(typeof key === 'string'){
            return localStorage.removeItem(key);
        }else{
            console.error('key must be a string!');
        }
        return false;
    },
    localClear(){
        return localStorage.clear();
    },

    copy(data){
        let oInput = document.createElement('input');
        oInput.value = data;
        oInput.classList.add('copy-input');
        document.body.appendChild(oInput);
        vm.$nextTick(() => {
            oInput.select(); // 选择对象;
            console.log(oInput.value);
            document.execCommand("Copy"); // 执行浏览器复制命令
            vm.$message({
                message: '复制成功',
                type: 'success'
            });
            oInput.remove();
        });
    },
    isMobile(){
        let u = navigator.userAgent, app = navigator.appVersion;
        let out = {versions:{
                trident: u.indexOf('Trident') > -1, //IE内核
                presto: u.indexOf('Presto') > -1, //opera内核
                webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
                gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') === -1, //火狐内核
                mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
                android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或uc浏览器
                iPhone: u.indexOf('iPhone') > -1, //是否为iPhone或者QQHD浏览器
                iPad: u.indexOf('iPad') > -1, //是否iPad
                webApp: u.indexOf('Safari') === -1 //是否web应该程序，没有头部与底部
            }, language: (navigator.browserLanguage || navigator.language).toLowerCase()}
        return out;
    }
}
