<template>
  <el-form :model="searchForm" class="bg-light border-radius-5 padding flex">
    <el-form-item style="margin-bottom: 0 !important;" class="flex1 margin-right">
      <el-input clearable v-model="searchForm.title" placeholder="输入关键字" class="mobile lee-input" :style="{width:'100%'}" @clear="clear"></el-input>
    </el-form-item>
    <el-form-item style="margin-bottom: 0 !important;" v-if="showPolicy" class="margin-right">
      <lee-select val-key="id" val-label="name" clearable :options="policyList" class="mobile" v-model="searchForm.type_policy_id" style="width: 100px;"></lee-select>
    </el-form-item>
    <el-form-item style="margin-bottom: 0 !important;" v-if="showMmType" class="margin-right">
      <lee-select clearable :options="mmTypeList" class="mobile" v-model="searchForm.mm_type" style="width: 100px;"></lee-select>
    </el-form-item>
    <el-form-item style="margin-bottom: 0 !important;" v-if="showYear" class="margin-right">
      <lee-select clearable placeholder="选择年份" :options="searchYearList" class="mobile" v-model="year" @change="changeYear" style="width: 100px;"></lee-select>
    </el-form-item>
<!--    <el-form-item style="margin-bottom: 0 !important;">-->
<!--      <lee-select :options="[{label:'标题',value:'title'},{label:'关键字',value:'key'}]" class="mobile" v-model="searchForm.title_type" style="width: 100px;"></lee-select>-->
<!--    </el-form-item>-->
    <el-form-item style="margin-bottom: 0 !important;margin-right: 0 !important;">
      <el-button type="success" @click="onSubmit" icon="el-icon-s-promotion" style="width: 150px;">查询</el-button>
    </el-form-item>
<!--    <div class="green margin-top-5 hand" v-if="searchForm.title_type === 'key'" @click="changeSort">相关性<i :class="searchForm.sortBy === 'desc' ? 'el-icon-sort-down' : 'el-icon-sort-up'"></i></div>-->
  </el-form>
  <!--    <div class="cl bg-light border-radius-5 padding">-->
  <!--        <el-input class="mobile lee-input" v-model="value"/>-->
  <!--        <el-button type="success y">fds{{value}}</el-button>-->
  <!--    </div>-->
  <!--    <div class="search-box">-->
  <!--&lt;!&ndash;        <i class="el-icon-search dark-3"></i>&ndash;&gt;-->
  <!--        <input v-model="value" :placeholder="placeholder" @input="input" @change="change" :style="{fontSize:$isMobile? '14px' : '20px'}"/>-->
  <!--    </div>-->
</template>

<script>
export default {
  name: "Search",
  data(){
    return{
      searchForm:{
        title:'',
        title_type:'title',
        slugs:[],
        sortBy:'desc',
        page:1,
        type_policy_id:''
      },
      searchYearList:[],
      policyList:[],
      mmTypeList:[
        {label:'全部类别',value:''},
        {label:'冷链',value:'chain'},
        {label:'食材供应链',value:'agriculture'},
        {label:'冷链/食材供应链',value: 'all'}
      ],
      year:''
      // value:''
    }
  },
  // model:{
  //     prop:'values',
  //     event:'input'
  // },
  props:{
    showYear:{
      type:Boolean,
      default:false
    },
    showPolicy:{
      type:Boolean,
      default:false
    },
    showMmType:{
      type:Boolean,
      default:false,
    },
    showSort:{
      type:Boolean,
      default:false
    },
    searchPath:{
      type:String,
      default:'',
    },
    searchSlugs:{
      type:Array,
      default(){
        return []
      }
    },
    stopSearch:{
      type:Boolean,
      default:false
    },
    toPath:{
      type:String,
      default:'',
    },
    searchTimeKey:{
      type:String,
      default:'create_time',
    },
    // values:{
    //     type:String,
    //     default:''
    // },
    // placeholder:{
    //     type:String,
    //     default:'请输入'
    // },
    // fontSize:{
    //     type:[String,Number],
    //     default:'20px'
    // },
  },
  created() {
    // this.value = this.values;
    // this.searchForm = this.$utils.isEmptyObject(this.$route.query) ? {} : this.$route.query;
    this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
    this.searchForm.title_type = this.$route.query.title_type ? this.$route.query.title_type : 'title';
    this.searchForm.sortBy = this.$route.query.sortBy ? this.$route.query.sortBy : 'desc';

    if(this.showPolicy){
      this.searchForm.type_policy_id = this.$route.query.type_policy_id ? this.$route.query.type_policy_id : '';
    }

    if(this.showMmType){
      this.searchForm.mm_type = this.$route.query.mm_type ? this.$route.query.mm_type : '';
    }

    if(this.$route.query[this.searchTimeKey]){
      this.searchForm[this.searchTimeKey] = this.$route.query[this.searchTimeKey];

      this.year = this.$moment(this.$route.query[this.searchTimeKey][0]).format('YYYY');
    }

    let _nowYear = this.$moment().year();

    this.searchYearList.push({label:'全部年份',value:''})
    for(let i = _nowYear; i > _nowYear - 30;i--){
      this.searchYearList.push({value:i.toString(),label:i.toString()+'年'});
    }
  },
  mounted() {
    if(this.searchForm.title) {
      // this.$emit('search',{title:this.searchForm.title,title_type:this.searchForm.title_type});
    }

    if(this.showPolicy){
      this.getPolicyType();
    }
  },
  methods:{
    getPolicyType(){
      let _this = this;
      this.$http.post({
        url:'/Type/listAll',
        data:{akey:'policy',orderBy:'sort',sortBy:'asc',state:1},
        success(res){
          _this.policyList = [];

          _this.policyList = [{id:'',name:'全部分类'}].concat(res);

          _this.getStandardType();
        }
      })
    },
    getStandardType(){
      let _this = this;
      this.$http.post({
        url:'/Type/listAll',
        data:{akey:'standard',orderBy:'sort',sortBy:'asc',state:1},
        success(res){
          for (let i=0;i < res.length;i++) {
            _this.policyList.push(res[i]);
          }
        }
      })
    },
    changeYear(e){
      if(e) {
        let _start = e + '-1-1 00:00:00'; //this.$moment(e+'-1-1 00:00:00').unix();
        let _end = e + '-12-31 23:59:59'; //this.$moment(e+'-12-31 23:59:59').unix();

        this.searchForm[this.searchTimeKey] = [_start, _end];
      }else{
        delete this.searchForm[this.searchTimeKey];

        console.log(this.searchForm);
        // this.$router.replace({query:{...this.searchForm}});
      }
    },
    onSubmit(){
      let _this = this;

      // this.$router.replace({query:{...this.searchForm}});

      if(!this.showPolicy){
        delete this.searchForm.type_policy_id;
      }

      if(this.stopSearch){
        // if(this.searchForm.title) {
          this.$router.push({path: this.toPath, query:{...this.searchForm}});
        // }else{
        //   this.$message.error({message: '请输入关键字', duration: 2000});
        // }
      }else {
        this.$router.replace({query:{...this.searchForm}});
        // this.$emit('search',{title:this.searchForm.title,title_type:this.searchForm.title_type});
      }
    },
    clear(){
      this.$emit('clear');
      this.searchForm.title = '';
      this.$router.replace({query:{...this.searchForm}});
    },
    toSearch(){
      // this.$emit('search',{title:this.searchForm.title,title_type:this.searchForm.title_type});

    },
    changeSort(){
      if(this.searchForm.sortBy === 'desc'){
        this.searchForm.sortBy = 'asc';
      }else{
        this.searchForm.sortBy = 'desc';
      }
      this.$router.replace({query:{...this.searchForm}});
    }
  },
  computed:{
    inputWidth(){
      let _width = 576;

      if(this.showMmType){
        _width -= 110;
      }

      if(this.showPolicy){
        _width -= 110;
      }

      if(this.showYear){
        _width -= 110;
      }

      return _width+'px';
      // if(this.showYear && this.showPolicy){
      //   return '356px';
      // }else if(!this.showYear && !this.showPolicy){
      //   return '576px';
      // }else{
      //   return '466px';
      // }
    }
  }
}
</script>
