<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">进出口国别相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchFrom.type" @change="onChangeType" class="margin-right">
						<el-option :value="tt.value" :label="tt.label" v-for="(tt,ii) in typeList"></el-option>
					</el-select>
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
<!--					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">-->
<!--						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>-->
<!--					</el-select>-->
				</div>
			</div>
		</div>
		<template v-if="data">
			<div class="f16 margin-top-20 dark-2" v-if="data && data.content1">
				{{data.content1 ? data.content1 : ''}}
			</div>
			<div class="margin-top-20">
				<div class="margin-auto" :style="{width:'600px'}">
					<lee-pie-echarts
						ref="pie1"
						key="pie1"
						:grid-bottom="30"
						height="350px"
						:label-format="'{b}\n{d}%'"
						label-pos="outside"
						legend-orient="vertical"
						legend-pos="right"
						:radius="['0%','60%']"></lee-pie-echarts>
				</div>
			</div>
			<div class="margin-top-20" v-if="data && data.countryList.length > 0">
				<table class="lee-table margin-auto" style="width:100%;margin-top: 20px;">
					<thead>
					<td width="300">国别名称</td>
					<td>占比</td>
					</thead>
					<tr v-for="(item,index) in data.countryList">
						<td>{{ item.name }}</td>
						<td>{{ item.num }}%</td>
					</tr>
				</table>
			</div>
		</template>
		<template v-else>
			<el-empty description="暂无数据"></el-empty>
		</template>
	</div>
</template>

<script>
export default {
	name: "InOutCountry",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
				type:'shucai'
			},
			typeList:[
				{label:'蔬菜及制品进口',value:'shucai'},
				{label:'水果及制品进口',value:'shuiguo'},
				{label:'肉类及制品进口',value:'rou'},
				{label:'水海产品及制品进口',value:'shuichanpin'},
				{label:'乳制品进口',value:'ruzhipin'},
			],
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.onChangeType();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeType(){
			this.getYears();
		},
		onChangeYear(e){
			this.getTotal1()
		},
		// onChangeQuarter(e){
		// 	this.getTotal1();
		// },
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataInoutCountry/getYears',
				data: {type:this.searchFrom.type},
				success(res) {
					if(res && res.length > 0) {
						_this.yearList = res;
						_this.searchData = res[0].value;
						_this.getTotal1();
					}else{
						_this.yearList = [];
						_this.searchData = '';
						_this.data = null;
					}
				}
			});
		},
		getMonth() {
			let _this = this;

			this.$http.post({
				url: '/HydataInoutCountry/getMonth',
				data: {year:this.searchData,type:this.searchFrom.type},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		formatBar(type, dw = '万吨') {
			let _bardata = {bar: [],line:[],};

			for (let i = 0; i < data.length; i++) {
				_bardata.bar.push(data[i].total);
			}

			return {name: dw, type: 'bar', color: 'green', data: _bardata.bar};
		},

		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchData){
				_where.year = this.searchData
			}
			// if(this.searchQuarterData){
			// 	_where.times = [this.searchQuarterData,'<=']
			// 	_where.time_type = 'month';
			// }else{
				_where.time_type = 'year';
			// }
			_where.type = this.searchFrom.type;
			this.$http.post({
				url: '/HydataInoutCountry/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _pie1Data = [];

					if(res && res.countryList){
						for(let i=0;i < res.countryList.length;i++){
							_pie1Data.push({value: parseFloat(res.countryList[i].num), name: res.countryList[i].name});
						}
					}

					setTimeout(()=>{
						if (_this.$refs.pie1) {
							_this.$refs.pie1.initData(_pie1Data);
						}
					},200)
				}
			})
		},
	}
}
</script>
