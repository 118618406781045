<template>
	<lee-user-main-page>
		<lee-user-page>
			<lee-user-top-tab slot="headRight"></lee-user-top-tab>
			<div style="width: 530px;" class="padding-bottom-100">
				<el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" :show-message="false" :inline-message="true">
					<el-form-item label="" prop="title" class="margin-bottom-0">
						<div class="form-title">发票抬头</div>
						<el-input v-model="formData.title" class="lee-input mobile" placeholder="发票抬头" maxlength="20" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="company_number" class="margin-bottom-0">
						<div class="form-title margin-top">统一社会信用代码</div>
						<el-input v-model="formData.company_number" class="lee-input mobile" placeholder="统一社会信用代码" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="company_adr" class="margin-bottom-0">
						<div class="form-title margin-top">单位地址</div>
						<el-input v-model="formData.company_adr" class="lee-input mobile" placeholder="单位地址" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="company_phone" class="margin-bottom-0">
						<div class="form-title margin-top">单位电话</div>
						<el-input v-model="formData.company_phone" class="lee-input mobile" placeholder="单位电话" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="company_bank" class="margin-bottom-0">
						<div class="form-title margin-top">开户银行</div>
						<el-input v-model="formData.company_bank" class="lee-input mobile" placeholder="开户银行" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="company_account" class="margin-bottom-0">
						<div class="form-title margin-top">银行账户</div>
						<el-input v-model="formData.company_account" class="lee-input mobile" placeholder="银行账户" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<!--                    <el-form-item label="" prop="type_id" class="margin-bottom-0" v-if="typeList.length > 0">-->
					<!--                        <div class="form-title margin-top">发票类型</div>-->
					<!--                        <lee-select placeholder="发票类型" :options="typeList" class="mobile" v-model="formData.type_id"></lee-select>-->
					<!--                    </el-form-item>-->
					<el-form-item label="" prop="program" class="margin-bottom-0">
						<div class="form-title margin-top">发票事项</div>
						<el-input v-model="formData.program" class="lee-input mobile" placeholder="发票事项" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="address" class="margin-bottom-0">
						<div class="form-title margin-top">邮寄地址</div>
						<el-input v-model="formData.address" class="lee-input mobile" placeholder="邮寄地址" maxlength="40" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="consignee" class="margin-bottom-0">
						<div class="form-title margin-top">收件人</div>
						<el-input v-model="formData.consignee" class="lee-input mobile" placeholder="收件人" maxlength="20" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="phone" class="margin-bottom-0">
						<div class="form-title margin-top">联系方式</div>
						<el-input v-model="formData.phone" class="lee-input mobile" placeholder="联系方式" maxlength="11" show-word-limit></el-input>
					</el-form-item>
					<el-button type="primary" class="padding-left-50 padding-right-50 f18 f-n box-shadow-blue margin-top-50" @click="submit('form')">保存</el-button>
				</el-form>
			</div>
		</lee-user-page>
	</lee-user-main-page>
</template>

<script>
export default {
	name: "Base",
	data(){
		return{
			// username	string	optional	收件人
			// address	string	optional	地址
			// remark	string	optional	事项
			// phone	string	optional	联系方式
			// type_id	integer	optional	发票类型
			//{"address":"","consignee":"","phone":"","program":"","title":""}
			formData:{
				address:'',
				consignee:'',
				phone:'',
				program:'',
				title:'',
				company_number:'',
				company_adr:'',
				company_phone:'',
				company_bank:'',
				company_account:''
			},
			typeList:[],
			rules: {
				title:[{ required: true, message:'请填写发票抬头'}],
				consignee:[{ required: true, message:'请填写收件人'}],
				address:[{ required: true, message:'请填写详细地址'}],
				phone:[{ required: true, message:'请填写联系方式'}],
				program:[{ required: true, message:'请选择发票事项'}],
			}
		}
	},
	mounted() {
		// this.getTypeList();
		this.getDetail();
	},
	methods:{
		getTypeList(){
			let _this = this;
			this.$http.post({
				url:'user/invoice/type',
				data:null,
				success(res){
					if(res.data){
						_this.typeList = res.data;
					}
				}
			})
		},
		getDetail(){
			let _this = this;
			this.$http.post({
				url:'/UserInvoice/detail',
				data:null,
				success(res){
					console.log(res);
					// let _data = res[0];
					_this.formData.title = res.title;
					_this.formData.program = res.program;
					_this.formData.address = res.address;
					_this.formData.phone = res.phone;
					_this.formData.consignee = res.consignee;
					_this.formData.company_number = res.company_number;
					_this.formData.company_adr = res.company_adr;
					_this.formData.company_phone = res.company_phone;
					_this.formData.company_bank = res.company_bank;
					_this.formData.company_account = res.company_account;
				}
			})
		},
		submit(formName){
			let _this = this;
			console.log(_this.formData);
			this.$refs[formName].validate(valid => {
				if (valid) {
					_this.$http.post({
						url: '/UserInvoice/edit',
						data: {..._this.formData},
						success(res) {
							if(res === 'ok') {
								_this.$message.success('保存成功');
								_this.getDetail();
							}else{
								_this.$message.error('保存失败');
							}
						}
					})
				}
			})
		},
	}
}
</script>
