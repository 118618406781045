<template>
    <span class="inline-block text-center border-radius padding-left-right" :class="'bg-tag-'+type + ($isMobile ? ' f12' : ' f14')" :style="{height:$isMobile ? '20px' : '30px',width:$isMobile ? 'auto' : '100px',lineHeight:$isMobile ? '20px' : '30px',backgroundColor:bgColor + ' !important',color:titleColor +' !important'}">{{title}}</span>
</template>

<script>
export default {
    name: "StateTag",
    props:{
        title:[String,Number],
        type:{
            type:String,
            default:'green'
        },
        bgColor:String,
        titleColor:String
    },
}
</script>
