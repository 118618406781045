<template>
    <el-select :clearable="clearable" v-model="value" :placeholder="placeholder" @change="change" class="lee-select bg-gray-4 border-radius-5 vm" @cancel="change">
        <el-option
            clearable
            v-for="item in optionList"
            :key="item[valKey]"
            :label="item[valLabel]"
            :value="item[valKey]">
        </el-option>
    </el-select>
</template>

<script>
export default {
    name: "Select",
    data(){
        return{
            value:'',
            optionList:[]
        }
    },
    model:{
        prop:'values',
        event:'change'
    },
    props:{
      valLabel:{
        type:String,
        default:'label'
      },
      valKey:{
        type:String,
        default:'value'
      },
        values:{
            type:[String,Number,Array],
            default:''
        },
        placeholder:{
            type:String,
            default:'请选择'
        },
        options:{
            type:Array,
            default(){
                return []
            }
        },
        clearable:{
            type:Boolean,
            default:false
        },
    },
    created() {
        this.value = this.values;
    },
    mounted() {
        // console.log(this.options);
        if(this.$isMobile){
            let _op = [];

            for (let i=0;i < this.options.length;i++){
                _op.push({text:this.options[i][this.valLabel],value:this.options[i][this.valKey]});
            }
            this.optionList = _op;
        }else{
            this.optionList = this.options;
        }
    },
    methods:{
        change(e){
            console.log(e);
            this.$emit('change',e);
        }
    },
    watch:{
        values(n,o){
            this.value = n;
        },
        options(n,o){
            this.optionList = n;
        }
    }
}
</script>
