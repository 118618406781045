<template>
    <lee-page bg-img="/images/bg_head_5.jpg" title="留言板" sub-title="The Top Hundred Corporations" des="">
      <div style="margin-top:-45px;height: 90px;" class="rel wrap1100 margin-auto cl">
        <div class="bg-green hand box-shadow padding border-radius margin-auto" style="width:250px;height: 90px;line-height: 70px;" @click="showAddComment = true">
          <img src="/images/icon/icon_20.png" width="65"/>
          <span class="light f24 margin-left-20 f-lq" style="vertical-align: middle;margin-right: 40px;">我要留言</span>
        </div>
      </div>
      <div class="bg-img-2 padding-bottom-400" style="margin-top:-45px;padding-top:45px; background-color: #f8f9fa;">
        <div class="wrap1100 margin-top-30">
          <template v-if="list.length > 0">
            <div class="bg-light padding-20 border-radius-5 margin-bottom-20" v-for="(item,index) in list">
              <div class="cl" style="height: 40px;line-height: 40px;">
                <div class="z margin-right-70 f22">用户：{{item.nickname}}</div>
                <!--                                <el-tag class="z f20 border-none" size="medium" effect="dark" style="height: 40px;line-height: 40px;" color="#FEBE00" :hit="false" v-if="item.slug">版块：{{item.slug.name}}</el-tag>-->
                <div class="y f22 dark-3 f-thin">{{item.create_time}}</div>
              </div>
              <div class="border-top padding-top margin-top f18">
                {{item.content}}
              </div>
            </div>
          </template>
          <template v-else>
            <div class="text-center padding-top-bottom-40 f20 dark-3">
              暂无留言
            </div>
          </template>
        </div>
      </div>
      <el-dialog
        title="留言"
        :visible.sync="showAddComment">
        <div class="green margin-top-20">留言内容</div>
        <div class="bg-gray-4 cl">
          <el-input
            class="lee-textarea"
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="formData.content">
          </el-input>
        </div>
        <div class="cl" slot="footer">
          <el-button size="medium" type="success" class="box-shadow-green" @click="add">提交留言</el-button>
        </div>
      </el-dialog>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            activeIndex:0,
            showAddComment:false,
            formData:{
                // slug:'',
                content:''
            },
            list:[],
            page:1,
            pageSize:15,
            total:0,
            typeList:[],
        }
    },
    mounted() {
        this.getList();
        // this.getTypeList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.post({
                url: '/Messageboard/listPage',
                data: {page:this.page},
                success(res) {
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        getTypeList(){
            let _this = this;
            this.$http.post({
                url: 'slug',
                data: {current_page:this.page},
                success(res) {
                    // console.log(res);
                    // if(res.data) {
                    //     if (res.data) {
                    //         _this.list = res.data;
                    //         _this.total = res.total;
                    //     }
                    // }
                    if(res.data.length > 0){
                        for(let i=0;i<res.data.length;i++){
                            _this.typeList.push({label:res.data[i].name,value:res.data[i].slug});
                        }
                    }
                }
            });
        },
        add(){
            // messageboard/store
            let _this = this;
            if(this.formData.content.trim()) {
                this.$http.post({
                    url: '/Messageboard/add',
                    data: this.formData,
                    success(res) {
                        _this.page = 1;
                        _this.getList();
                        _this.formData.content = '';
                        _this.showAddComment = false;
                    }
                });
            }else{
                this.formData.content = '';
                this.$message.error('请输入内容');
            }
        }
    }
}
</script>
