<template>
    <div class="cl right-tab">
        <span class="z tab-item hand" @click="$router.push({path:item.path})" v-for="(item,index) in list" :class="$route.path === item.path ? 'on' : ''">{{item.title}}</span>
    </div>
</template>

<script>
export default {
    name: "UserTopTab",
    data(){
        return{
            list:[
                {title:'基本资料',path:'/user/profile'},
                {title:'专业信息',path:'/user/profile/major'},
                {title:'发票信息',path:'/user/profile/ticket'},
            ]
        }
    }
}
</script>
