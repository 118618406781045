<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1">
            {{nowYear}}年，<template v-for="(item,index) in pieData">{{item.name}}总产量为{{item.value}}万吨{{index + 1 === pieData.length ? '。' : '，'}}</template>
        </el-col>
        <el-col :span="12">
            <lee-pie-echarts
                ref="pie1"
                :grid-bottom="$isMobile ? 150 : 30"
                height="200px"
                width="512px"
                :label-format="'{b}\n{d}%'"
                label-pos="inside"
                :show-legend="false"
                :legend-orient="$isMobile ? 'horizontal' : 'vertical'"
                :legend-pos="$isMobile ? 'bottom' : 'right'"
                :radius="['0%','90%']"></lee-pie-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData4",
    data(){
        return{
            data:null,
            pieData:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {
    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/HydataChanliang/getYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear, endYear) {
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url: '/HydataChanliang/getData',
                data: {year: startYear},
                success(res) {
                    _this.data = res;

                    let _pie1data = [];
                    _pie1data.push({value:res.shuiguo.now.total,name:'水果'});
                    _pie1data.push({value:res.shucai.now.total,name:'蔬菜'});
                    _pie1data.push({value:res.rouzhipin.now.total,name:'肉制品'});
                    _pie1data.push({value:res.shuichanpin.now.total,name:'水产品'});
                    _pie1data.push({value:res.ruzhipin.now.total,name:'乳制品'});
                    // _pie1data.push({value:res.sudongshipin.now.total,name:'速冻食品'});

                    _this.pieData = _pie1data;

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1data);
                    }
                }
            });
        },
    }
}
</script>
