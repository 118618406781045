import Vue from 'vue'
let vm = new Vue();

import VueRouter from 'vue-router'

import Index from '../views/site/Index.vue'

import ZcIndex from '../views/zhengce/Index'
import ZcList from '../views/zhengce/List'
import ZcDetail from '../views/zhengce/Detail'

import YjIndex from '../views/yanjiu/Index'
import hangyeList from '../views/yanjiu/hangye/list'
import xifenList from '../views/yanjiu/xifen/list'
import xifenIndex from '../views/yanjiu/xifen/Index'

import QaIndex from '../views/qa/Index'
import MyQa from "@/views/qa/MyQa";

import CommentIndex from '../views/comment/Index'
import AboutIndex from '../views/about/Index'

import CompanyIndex from '../views/company/Index'
import CompanyDetail from '../views/company/Detail'
import CompanyReport from '../views/company/Report'

import HyIndex from '../views/hydata/Index'
import ColdCar from "@/views/hydata/ColdCar";
import ColdStorage from "@/views/hydata/ColdStorage";
import ColdChain from "@/views/hydata/ColdChain";
import InAndOut from "@/views/hydata/InAndOut";
import Agriculture from "@/views/hydata/Agriculture";
import Characteristic from "@/views/hydata/Characteristic";
import HyDataShebei from "@/views/hydata/Shebei";

import Base from "@/views/user/profile/Base";
import SafeIndex from "@/views/user/Safe/SafeIndex";
import Major from "@/views/user/profile/Major";
import Ticket from "@/views/user/profile/Ticket";
import BuyLog from "@/views/user/buylog/BuyLog";
import ReadLog from "@/views/user/readlog/ReadLog";
import MyComment from "@/views/user/mycomment/MyComment";
import MyQaLog from "@/views/user/myqa/MyQaLog";
import MyCollection from "@/views/user/mycollection/MyCollection";
import MyGood from "@/views/user/mygood/MyGood";
import MyBalance from '@/views/user/balance/Index';
import Vip from "@/views/user/vip/Vip";
import UserCompanyIndex from "@/views/user/company/index";

import Xifen from "@/views/yanjiu/xifen/Xifen";
import Detail from "@/views/yanjiu/xifen/Detail";
import FileBuy from "@/views/yanjiu/xifen/FileBuy";
import Harvest from "@/views/hydata/Harvest";
import Login from "@/views/site/Login";
import QrLogin from "@/views/site/QrLogin";
import Register from "@/views/site/Register";
import FindPwd from "@/views/site/FindPwd";
import BookBuy from "@/views/yanjiu/hangye/BookBuy";
import orderPay from '@/views/order/pay';
import orderPayDone from '@/views/order/payDone';
import qrloginMobile from '@/views/site/QrLoginMobile';
import SurveyDetail from '@/views/survey/detail';
import SurveyInfo from '@/views/survey/info';

import newsIndex from '@/views/news/index';
import newsList from '@/views/news/list';
import newsDetail from '@/views/news/detail';

import ZcSearch from '@/views/zhengce/Search';
import YjSearch from '@/views/yanjiu/Search';

import SurveyIndex from '@/views/survey/Index';

import hydataTest from '@/views/hydata/test';
import MiniQrCode from "@/views/site/MiniQrCode";
import Cabinet from "@/views/hydata/Cabinet";
import ChangePwd from "@/views/user/Safe/ChangePwd";
import prizeIndex from '../views/prize/Index'
import prizeDetail from '../views/prize/Detail'
import prizeJoin from '../views/prize/Join'
import prizeJoinForm from '../views/prize/JoinForm'
import prizeList from '../views/prize/List'
import prizeSearch from '../views/prize/Search'
import MyAnswer from "@/views/qa/MyAnswer";

import SurveyDone from '@/views/survey/done';
import Fapiao from '../views/user/fapiao/Fapiao';
import FapiaoDetail from '@/views/user/fapiao/detail';
import FapiaoAdd from '@/views/user/fapiao/add';
import addCompany from '../views/user/company/addCompany'
import joinCompany from '../views/user/company/joinCompany'
import manageCompany from '../views/user/company/manageCompany'
import CalculatorIndex from '../views/calculator/index'

const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function(location){
  return routerPush.call(this, location).catch(error=> error)
};

const routerReplace = VueRouter.prototype.replace;

VueRouter.prototype.replace = function(location){
  return routerReplace.call(this, location).catch(error=> error)
};

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/miniqrcode',
    name: 'miniqrcode',
    component: MiniQrCode
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/qrlogin',
    name: 'QrLogin',
    component: QrLogin
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/findpwd',
    name: 'FindPwd',
    component: FindPwd
  },
  {
    path: '/zhengce',
    name: 'ZcIndex',
    component: ZcIndex
  },
  {
    path: '/zhengce/search',
    name: 'ZcSearch',
    component: ZcSearch
  },
  {
    path: '/zhengce/list/:type/:id',
    name: 'ZcList',
    component: ZcList
  },
  {
    path: '/policy/detail/:id',
    name: 'ZcDetail',
    component: ZcDetail
  },
  {
    path: '/prize',
    name: 'prizeList',
    component: prizeList
    // component: prizeIndex
  },
  {
    path: '/prize/search',
    name: 'prizeSearch',
    component: prizeSearch
  },
  // {
  //   path: '/prize/list/:type',
  //   name: 'prizeList',
  //   component: prizeList
  // },
  {
    path: '/prize/detail',
    name: 'prizeDetail',
    component: prizeDetail
  },
  {
    path: '/prize/join',
    name: 'prizeJoin',
    component: prizeJoin
  },
  {
    path: '/prize/join/form',
    name: 'prizeJoinForm',
    component: prizeJoinForm
  },
  {
    path: '/user/fapiao',
    name: 'Fapiao',
    component: Fapiao,
    meta:'发票管理'
  },
  {
    path: '/user/fapiao/detail',
    name: 'FapiaoDetail',
    component: FapiaoDetail,
    meta:'发票明细'
  },
  {
    path: '/user/fapiao/add',
    name: 'FapiaoAdd',
    component: FapiaoAdd,
    meta:'申请发票'
  },
  {
    path: '/standard/detail/:id',
    name: 'ZcDetail',
    component: ZcDetail
  },
  {
    path: '/yanjiu',
    name: 'YjIndex',
    component: YjIndex
  },
  {
    path: '/yanjiu/search',
    name: 'YjSearch',
    component: YjSearch
  },
  {
    path: '/yanjiu/xifen',
    name: 'Xifen',
    component: Xifen
  },
  {
    path: '/yanjiu/industry/list',
    name: 'hangyeList',
    component: hangyeList
  },
  {
    path: '/yanjiu/domain',
    name: 'xifenIndex',
    component: xifenIndex
  },
  {
    path: '/yanjiu/domain/list/:pid',
    name: 'xifenList',
    component: xifenList
  },
  {
    path: '/domain/detail/:id',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/yanjiu/xifen/buy/:id',
    name: 'FileBuy',
    component: FileBuy
  },
  {
    path: '/industry/detail/:id',
    name: 'BookBuy',
    component: BookBuy
  },
  {
    path: '/qa',
    name: 'QaIndex',
    component: QaIndex
  },
  {
    path: '/calculator',
    name: 'CalculatorIndex',
    component: CalculatorIndex
  },
  {
    path: '/qa/me',
    name: 'MyQa',
    component: MyQa
  },
  {
    path: '/qa/myanswer',
    name: 'MyAnswer',
    component: MyAnswer
  },
  {
    path: '/comment',
    name: 'CommentIndex',
    component: CommentIndex
  },
  {
    path: '/about',
    name: 'AboutIndex',
    component: AboutIndex
  },
  {
    path: '/company',
    name: 'CompanyIndex',
    component: CompanyIndex
  },
  {
    path: '/company/detail/:id',
    name: 'CompanyDetail',
    component: CompanyDetail
  },
  {
    path: '/company/report/:year',
    name: 'CompanyReport',
    component: CompanyReport
  },
  {
    path: '/hydata',
    name: 'HyIndex',
    component: HyIndex
  },
  {
    path: '/hydata/test',
    name: 'hydataTest',
    component: hydataTest
  },
  {
    path: '/hydata/coldcar',
    name: 'ColdCar',
    component: ColdCar
  },
  {
    path: '/hydata/coldstorage',
    name: 'ColdStorage',
    component: ColdStorage
  },
  {
    path: '/hydata/coldchain',
    name: 'ColdChain',
    component: ColdChain
  },
  {
    path: '/hydata/shebei',
    name: 'HyDataShebei',
    component: HyDataShebei
  },
  {
    path: '/hydata/inout',
    name: 'InAndOut',
    component: InAndOut
  },
  {
    path: '/hydata/agriculture',
    name: 'Agriculture',
    component: Agriculture
  },
  {
    path: '/hydata/characteristic',
    name: 'Characteristic',
    component: Characteristic
  },
  {
    path: '/hydata/harvest',
    name: 'Harvest',
    component: Harvest
  },
  {
    path: '/hydata/cabinet',
    name: 'Cabinet',
    component: Cabinet
  },
  {
    path: '/user/index',
    name: 'UserIndex',
    // component: UserIndex,
    redirect:'/user/profile',
  },
  {
    path: '/user',
    name: 'UserIndex2',
    // component: UserIndex,
    redirect:'/user/profile',
  },
  {
    path: '/user/profile',
    name: 'Base',
    component: Base,
    meta:'个人资料'
  },
  {
    path: '/user/profile/major',
    name: 'Major',
    component: Major,
    meta:'个人资料'
  },
  {
    path: '/user/profile/ticket',
    name: 'Ticket',
    component: Ticket,
    meta:'个人资料'
  },
  {
    path: '/user/safe',
    name: 'SafeIndex',
    component: SafeIndex,
    meta:'账号安全'
  },
  {
    path: '/user/safe/changepwd',
    name: 'changePwd',
    component: ChangePwd,
    meta:'修改密码'
  },
  {
    path: '/user/company',
    name: 'companyIndex',
    component: UserCompanyIndex,
    meta:'我的企业'
  },
  {
    path: '/user/company/add',
    name: 'addCompany',
    component: addCompany,
    meta:'创建企业'
  },
  {
    path: '/user/company/join',
    name: 'joinCompany',
    component: joinCompany,
    meta:'加入企业'
  },
  {
    path: '/user/company/manage',
    name: 'manageCompany',
    component: manageCompany,
    meta:'管理企业'
  },
  {
    path: '/user/buylog',
    name: 'BuyLog',
    component: BuyLog,
    meta:'消费记录'
  },
  {
    path: '/user/readlog',
    name: 'ReadLog',
    component: ReadLog,
    meta:'阅读记录'
  },
  {
    path: '/user/mycomment',
    name: 'MyComment',
    component: MyComment,
    meta:'我的评论'
  },
  {
    path: '/user/myqa',
    name: 'MyQaLog',
    component: MyQaLog,
    meta:'我的提问'
  },
  {
    path: '/user/mycollection',
    name: 'MyCollection',
    component: MyCollection,
    meta:'我的收藏'
  },
  {
    path: '/user/mygood',
    name: 'MyGood',
    component: MyGood,
    meta:'我的点赞'
  },
  {
    path: '/user/balance',
    name: 'MyBalance',
    component: MyBalance,
    meta:'我的余额'
  },
  {
    path: '/user/vip',
    name: 'Vip',
    component: Vip,
    meta:'我的VIP'
  },
  {
    path: '/order/pay',
    name: 'orderPay',
    component: orderPay,
  },
  {
    path: '/order/paydone',
    name: 'orderPayDone',
    component: orderPayDone,
  },
  {
    path: '/mobile/login',
    name: 'qrloginMobile',
    component: qrloginMobile,
  },
  {
    path: '/news',
    name: 'newsIndex',
    component: newsIndex,
  },
  {
    path: '/news/list/:type',
    name: 'newsList',
    component: newsList,
  },
  {
    path: '/news/detail/:id',
    name: 'newsDetail',
    component: newsDetail,
  },
  {
    path: '/survey',
    name: 'SurveyIndex',
    component: SurveyIndex,
  },
  {
    path: '/survey/detail/:id',
    name: 'SurveyDetail',
    component: SurveyDetail,
  },
  {
    path: '/survey/info/:id',
    name: 'SurveyInfo',
    component: SurveyInfo,
  },
  {
    path: '/survey/done',
    name: 'SurveyDone',
    component: SurveyDone,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(to.path.indexOf('/user') < 0) {
      return {x: 0, y: 0};
    }
  }
})

export default router
