<template>
	<lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="产销量相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'产销量'}]">
		<div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_harvest">
				<div class="wrap1100">
					<div class="flex">
						<div class="flex1" v-for="(t,i) in [{n:'概况',i:'all'},{n:'水果',i:'shuiguo'},{n:'蔬菜',i:'shucai'},{n:'肉制品',i:'rouzhipin'},{n:'水产品',i:'shuichanpin'},{n:'乳制品',i:'ruzhipin'},{n:'速冻食品',i:'sudongshipin'}]">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="[dataType === t.i ? 'bg-green light' : 'green',i < 6 ? 'margin-right-20':'']" @click="onChangeDataType(t.i)">{{t.n}}</div>
						</div>
					</div>
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<chanliang-all v-if="dataType === 'all'"></chanliang-all>
						<chanliang-shuiguo key="shuiguo" v-if="dataType === 'shuiguo'" title="水果" show-table tab-type="shuiguo"></chanliang-shuiguo>
						<chanliang-shuiguo key="shucai" v-if="dataType === 'shucai'" title="蔬菜" show-table tab-type="shucai"></chanliang-shuiguo>
						<chanliang-shuiguo key="rouzhipin" v-if="dataType === 'rouzhipin'" title="肉制品" show-table tab-type="rouzhipin"></chanliang-shuiguo>
						<chanliang-shuiguo key="shuichanpin" v-if="dataType === 'shuichanpin'" title="水产品" show-table tab-type="shuichanpin"></chanliang-shuiguo>
						<chanliang-shuiguo key="ruzhipin" v-if="dataType === 'ruzhipin'" title="乳制品" :show-table="false" tab-type="ruzhipin"></chanliang-shuiguo>
						<chanliang-shuiguo key="sudongshipin" v-if="dataType === 'sudongshipin'" title="速冻食品" :show-table="false" tab-type="sudongshipin" dw="(亿元)" bar-name="市场规模(亿元)"></chanliang-shuiguo>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
import ChanliangAll from './components/ChanliangAll'
import ChanliangShuiguo from './components/ChanliangShuiguo'
export default {
	name: "List",
	components: {ChanliangShuiguo, ChanliangAll},
	data() {
		return {
			dataType:'all'
		}
	},
	created() {
		let _this = this;
	},
	mounted() {
	},
	methods: {
		onChangeDataType(e){
			this.dataType = e;
		},
	}
}
</script>
