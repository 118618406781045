<template>
  <span>
    <el-button size="small"  type="warning" class="margin-left" @click="showDialog">立即登录</el-button>
    <login-dialog v-model="show"></login-dialog>
  </span>
</template>

<script>
export default {
  name: "LoginButton",
  data() {
    return {
      show:false,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    showDialog(){
      this.show = true;
    },
    hideDialog(){
      this.show = false;
    }
  }
}
</script>
