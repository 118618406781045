<template>
    <div class="border-l-green padding-left">
        <slot></slot>
    </div>
</template>

<script>
export default {
name: "headTItle"
}
</script>
