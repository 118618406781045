<template>
    <span>
        <el-upload
            :file-list="fileList"
            :multiple="true"
            :action="$config.uploadUrl"
            list-type="picture-card"
            :data="{key:imgKey}"
            :headers="headers"
            :limit="3"
            :before-upload="beforeUpload"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :on-preview="handlePictureCardPreview"
            accept=".jpg,.jpeg,.png,.gif"
            :on-remove="handleRemove">
            <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="showBigImg">
            <img width="100%" :src="bigImg" alt="">
        </el-dialog>
    </span>
</template>

<script>
import Moment from 'moment'

export default {
    name: "ImageUpload",
    data() {
        return {
            bigImg: '',
            showBigImg: false,
            fileList:[],
            values:'',
            ids:[],
	        headers:{}
        };
    },
	model:{
		prop:'value',
		event:'change'
	},
    props:{
        imgKey: {
            type: String,
            default: 'blog'
        },
        value:{
            type:Array,
            default(){
                return []
            }
        },
      file:{
        type:Array,
        default(){
          return []
        }
      }
    },
    created() {
        this.fileList = this.file;
    },
    watch:{
        'value': {
            handler: function (n, o) {
              // console.log(n);
                this.values = n;
            },
            deep: true
        },
      file(n,o){
          this.fileList = n;
      }
    },
    methods: {
        handleRemove(file, fileList) {
            this.ids = [];

            this.fileList = fileList;

            fileList.forEach(item=>{
                this.ids.push(item.id);
            })

            this.$emit('change',this.ids);
        },
        handlePictureCardPreview(file) {
            this.bigImg = file.url;
            this.showBigImg = true;
        },
        onSuccess(response, file, fileList) {
            console.log(fileList);
            if(response.code !== 0){
                this.$utils.error(response.msg);
                this.fileList = [];
            }else{
                this.ids = [];

                this.fileList = fileList;

                fileList.forEach(item=>{
                    this.ids.push(item.response.result.id);
                })
	            
	            console.log(this.ids);

                this.$emit('change',this.ids);
            }
        },
        onExceed(f,fl){
            console.log('323');
        },
	    beforeUpload(){
		    let _time = Moment().unix();
		
		    // let _secret = this.$md5(this.$config.secret + _time.toString());
		    //
		    // this.headers.secret = _secret;
		    // this.headers.timestamp = _time;
		    this.headers.Authorization = this.$user.getToken()
		    return true;
	    }
    }
}
</script>
