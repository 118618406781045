<template>
	<lee-user-main-page type="dark">
		<lee-user-page class="padding-bottom-100">
			<el-descriptions class="margin-top" title="" :column="2" size="small" border v-if="info">
				<el-descriptions-item label="发票性质">
					<template>
						<span v-if="info.type === 'email'">电子发票<a :href="info.file.indexOf('http') >= 0 ? info.file : $config.assetsUrl + info.file" class="under" target="_blank" v-if="info.file">(下载)</a></span>
						<span v-if="info.type === 'paper'">纸质发票</span>
					</template>
				</el-descriptions-item>
				<el-descriptions-item label="发票状态：">
					<span v-if="info.state === 0">待审核</span>
					<span v-if="info.state === 1">已开票</span>
					<span v-if="info.state === 2">已拒绝</span>
				</el-descriptions-item>
				<el-descriptions-item label="发票抬头">{{info.title}}</el-descriptions-item>
				<el-descriptions-item label="申请时间">{{info.create_time}}</el-descriptions-item>
				<el-descriptions-item label="发票金额">￥{{info.pay_amount}}</el-descriptions-item>
			</el-descriptions>
			<div class="margin-top">
				<el-table :data="list">
					<el-table-column label="订单编号" prop="order_no" width="180px"></el-table-column>
					<el-table-column label="订单描述" prop="order_body"></el-table-column>
					<el-table-column label="实付金额" prop="pay_amount" width="100px"></el-table-column>
					<el-table-column label="支付时间" prop="pay_time" width="180px">
						<template slot-scope="scope">
							{{$moment.unix(scope.row.pay_time).format('YYYY-MM-DD HH:mm:ss')}}
						</template>
					</el-table-column>
				</el-table>
			</div>
		</lee-user-page>
	</lee-user-main-page>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			id:'',
			info:null,
			list:[]
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
	},
	mounted() {
		if(this.id){
			this.getList();
			this.getDetail();
		}
	},
	methods: {
		getList(){
			let _this = this;
			this.$http.post({
				url: '/AuthUserInvoiceLog/getOrderList',
				data: {id:this.id},
				success(res) {
					_this.list = res;
				}
			});
		},
		getDetail(){
			let _this = this;
			this.$http.post({
				url: '/AuthUserInvoiceLog/detail',
				data: {id:this.id},
				success(res) {
					_this.info = res;
				}
			});
		},
	}
}
</script>
