<template>
    <div class="flex flex-column flex-ai flex-jc" style="height: 100%;">
        <img :src="weixin_mini_qrcode.indexOf('http') >= 0 ? weixin_mini_qrcode : $config.assetsUrl+weixin_mini_qrcode" style="width: 100%;"/>
        <div class="margin-top f14">长按上方<u class="green f-b">二维码</u>保存图片，在微信中<u class="green f-b">扫一扫</u>进入小程序</div>
    </div>
</template>

<script>
export default {
    name: "MiniQrCode",
    data(){
        return{
            weixin_mini_qrcode:''
        }
    },
    mounted() {
        this.getSetting();
    },
    methods:{
        getSetting() {
            let _this = this;

            this.$http.post({
                url: '/SystemSetting/getSystem',
                data:{skey:'weixin_mini_qrcode'},
                success(res) {
                    console.log(res);
                    _this.weixin_mini_qrcode = res.weixin_mini_qrcode;
                }
            });
        },
    }
}
</script>
