<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" :back="false" :title="info.jiangxiang.name" :bread="[{title:'项目',url:'/prize'},{title:info.jiangxiang.name}]" v-if="info">
		<div class="bg-img-11 padding-top-100 padding-bottom-400">
			<div class="wrap1100">
				<div class="box-shadow bg-light padding-30" v-if="info">
					<div class="green-line">
						<div class="cl f34">
							<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
							{{ info.jiangxiang.name }} - {{ info.year }}年
						</div>
					</div>
					<template v-if="info.check.type === 'ok'">
						<div class="f16 dark-1" v-html="info.html_content"></div>
						<div class="cl dark-2 f20">
							<img src="/images/icon/icon_62.png" height="20"/>
							项目简介
						</div>
						<div v-html="info.prize_info" class="f-thin dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							申报要求
						</div>
						<div v-html="info.prize_need" class="f-thin dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							项目设置
						</div>
						<div v-html="info.prize_setting" class="f-thin dark-1 padding-top-bottom"></div>
						<div class="cl dark-2 f20 margin-top-20">
							<img src="/images/icon/icon_62.png" height="20"/>
							申报结果
						</div>
						<div class="margin-top" v-if="companyList.length > 0">
							<table class="lee-table margin-auto" style="width:100%;">
								<thead>
								<td width="150">排名</td>
								<td>企业名称</td>
								<td width="150">省份</td>
								<td width="50"></td>
								</thead>
								<tr v-for="(item,index) in companyList">
									<td>{{ item.arank }}</td>
									<td>{{ item.company.title }}</td>
									<td>{{ item.company && item.company.province_name ? item.company.province_name : '-' }}</td>
									<td><el-link type="success" @click="$router.push({path:'/company/detail/'+item.company_id})">查看</el-link></td>
								</tr>
							</table>
						</div>
<!--						<lee-good-view akey="prize" :id="info.id" class="margin-top-30" :open-like="info.open_like === 1" :open-favorite="info.open_favorite === 1" :open-comment="info.open_comment === 1"></lee-good-view>-->
					</template>
					<vip-check-button :datas="info" :check="info.check" :price="info.price" :title="info.title" pay-title="权威榜单详情" pay-sub-title="权威榜单详情金额" type="prize" @paydone="getDetail" v-else></vip-check-button>
				</div>
<!--				<template v-if="info.check.type === 'ok'">-->
<!--					<div class="box-shadow padding-30 bg-light margin-top-30" v-if="info.open_comment === 1">-->
<!--						<lee-comment-view akey="industry" :id="info.id"></lee-comment-view>-->
<!--					</div>-->
<!--				</template>-->
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			id: '',
			info: null,
			year: '',
			yearList: [],
			companyList: []
		}
	},
	created() {
		this.id = this.$route.query.id;
	},
	mounted() {
		this.getDetail();
		// this.getYears();
	},
	methods: {
		onChangeDataTab(e) {
			console.log(e);
			this.year = e;

			this.getCompany();
		},
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/Prize/detail',
				data: {id: this.id},
				success(res) {
					_this.info = res;
					_this.title = res.title;
					_this.getCompany();
				}
			});
		},
		// getYears() {
		// 	let _this = this;
		// 	this.$http.post({
		// 		url: '/PrizeCompany/getYears',
		// 		data: {id: this.id},
		// 		success(res) {
		// 			console.log(res);
		// 			_this.yearList = res;
		// 			if (res.length > 0) {
		// 				_this.year = res[0];
		//
		// 				_this.getCompany();
		// 			}
		// 		}
		// 	});
		// },
		getCompany() {
			let _this = this;
			this.$http.post({
				url: '/PrizeCompanyChoose/listAll',
				data: {prize_id:this.id, orderBy:'arank',sortBy:'asc'},
				success(res) {
					_this.companyList = res;
				}
			});
		},
	}
}
</script>
