<template>
	<lee-page bg-img="/images/bg_head_8.jpg" title="行业数据" sub-title="Industry Data" des="" :bread="[{title:'行业数据'}]">
		<div class="bg-img-5 text-center padding-bottom-400">
			<div class="cl"></div>
			<div class="rel inline-block margin-top-100">
				<div class="flex flex-ai flex-jc">
					<div class="hide" style="margin-right:40px;display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">
						<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #ff9fd5;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/coldchain'})">
							<img src="/images/icon/icon_15_on.png" style="height: 70px;"/>
							<div class="f-lq light f28 margin-top-20">宏观冷链</div>
						</div>
					</div>
					<div class="hide" style="margin-right:40px;display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">
						<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #74e0fc;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/coldstorage'})">
							<img src="/images/icon/icon_10_on.png" style="height: 70px;"/>
							<div class="f-lq light f28 margin-top-20">冷库</div>
						</div>
					</div>
					<div class="hide" style="display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">
						<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #13bfcf;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/shebei'})">
							<img src="/images/icon/icon_12_on.png" style="height: 70px;"/>
							<div class="f-lq light f24 margin-top-20">冷链设施设备</div>
						</div>
					</div>
				</div>
				<div class="flex flex-ai flex-jc margin-top-50">
					<div class="hide" style="margin-right:40px;display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">
						<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #78b0ff;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/coldcar'})">
							<img src="/images/icon/icon_9_on.png" style="height: 70px;"/>
							<div class="f-lq light f28 margin-top-20">冷藏车</div>
						</div>
					</div>
					<div class="hide" style="margin-right:40px;display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">
						<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #90c900;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/harvest'})">
							<img src="/images/icon/icon_14_on.png" style="height: 70px;"/>
							<div class="f-lq light f28 margin-top-20">产销量</div>
						</div>
					</div>
					<div class="hide" style="display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">
						<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #e1b547;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/inout'})">
							<img src="/images/icon/icon_13_on.png" style="height: 70px;"/>
							<div class="f-lq light f28 margin-top-20">进出口</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			hyList: [
				{title: '冷藏车', icon: '/images/icon/icon_9_on.png', path: '/hydata/coldcar'},
				{title: '冷库', icon: '/images/icon/icon_10_on.png', path: '/hydata/coldstorage'},
				{title: '冷链物流', icon: '/images/icon/icon_11_on.png', path: '/hydata/coldchain'},
				{title: '产量', icon: '/images/icon/icon_12_on.png', path: '/hydata/harvest'},
				{title: '进出口', icon: '/images/icon/icon_13_on.png', path: '/hydata/inout'},
				{title: '农批', icon: '/images/icon/icon_14_on.png', path: '/hydata/agriculture'},
				{title: '特殊农品', icon: '/images/icon/icon_15_on.png', path: '/hydata/characteristic'},
			]
		}
	},
	methods: {
		prevSwiper() {
			this.$refs['swiper'].prev();
		},
		nextSwiper() {
			this.$refs['swiper'].next();
		}
	}
}
</script>
