<template>
    <el-row :gutter="40">
        <el-col :md="8" :xs="24">
            <img src="/images/banner_pay.jpg" class="block"/>
        </el-col>
        <el-col :md="8" :xs="24" class="border-right">
            <div class="dark-3" v-if="title">{{title}}</div>
            <div class="green-box padding margin-top dark-3" v-if="name">
                {{name}}
                <div class="margin-top-30 f-thin" v-if="fileSize">文档大小:{{fileSize}}</div>
            </div>
            <div class="dark-3 margin-top-20" v-if="subTitle">{{subTitle}}</div>
            <div class="dark-1 f18 margin-top" v-if="phone">联系方式：{{phone}}</div>
            <div class="red margin-top f46" style="line-height: 60px;" v-if="akey !== 'industry'">
                <span class="f-thin dark-2 f30">¥</span>{{price}}
            </div>

        </el-col>
        <el-col :md="8" :xs="24">
            <div>
                <img :src="qrcode" class="border-green border-w-5 border-radius-5" style="width: 100%;"/>
            </div>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "PayView",
    data(){
        return{
            payType:'alipay'
        }
    },
    props:{
        price:{
            type:[String,Number],
            default:333.89
        },
        fileSize:{
            type:String,
            default:'789Kb'
        },
        title:{
            type:String,
            default:'购买文档'
        },
        subTitle:{
            type:String,
            default:'报告金额'
        },
        name:{
            type:String,
            default: ''
        },
        qrcode:{
            type:String,
            default: ''
        },
        phone:{
            type:String,
          default:''
        },
      akey:{
        type:String,
        default:''
      }
    },
    methods:{
        change(v){
            this.payType = v;
            this.$emit('change',v);
        },
        submit(){
            this.$emit('submit',this.payType);
        }
    }

}
</script>
