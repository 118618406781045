<template>
    <lee-user-main-page type="dark">
        <lee-user-page class="padding-bottom-100">
            <div class="border-radius-5 bg-gray-5 padding-20">
                <el-row :gutter="10">
                    <el-col :span="10">
                        <span class="inline-block vm">余额：</span>
                        <span class="inline-block vm red f20 f-b">￥{{credit ? credit.balance : '0.00'}}</span>
                    </el-col>
                    <el-col :span="10">
                        <span class="inline-block vm">冻结余额：</span>
                        <span class="inline-block vm red f20 f-b">￥{{credit ? credit.lock_balance : '0.00'}}</span>
                    </el-col>
                    <el-col :span="4">
                        <el-button type="success" size="mini" class="vm" @click="toCash">提现(至少￥{{less_cash}})</el-button>
                    </el-col>
                </el-row>
            </div>
            <template v-if="list.length > 0">
                <lee-base-cell v-for="(item,index) in list" title-class="green f14 f-n" :right-title-class="parseFloat(item.expense) > 0 ? 'green' : 'red'"  :right-title="parseFloat(item.expense) > 0 ? '-￥'+item.expense : '+￥'+item.income">
                    <div slot="subTitle">
                        <div class="f16 dark-1 text-line-1" style="line-height: 24px;">{{item.des}}</div>
                        <div class="dark-3 f-thin" :class="$isMobile ? 'f12' : 'f14'" style="line-height: 20px;">记录时间：{{item.create_time}}</div>
                    </div>
                </lee-base-cell>
                <div class="text-center margin-top-100">
                    <el-pagination
                        :hide-on-single-page="true"
                        background
                        layout="pager"
                        :page-size="pageSize"
                        :current-page.sync="page"
                        :total="totalCount"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
            </template>
            <el-empty description="暂无记录" v-else></el-empty>
        </lee-user-page>
        <el-dialog title="提现" :visible.sync="show" :close-on-click-modal="false" :close-on-press-escape="false">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" :show-message="true" :inline-message="true">
                <el-row :gutter="10">
                    <el-col :span="12">可提现金额：<b class="f-n green f16">￥{{credit ? credit.balance : '0.00'}}</b></el-col>
                    <el-col :span="12">最低提现金额：<b class="f-n green f16">￥{{less_cash}}</b></el-col>
                </el-row>
                <el-form-item label="" prop="mobile" class="margin-bottom-0 margin-top-20">
                    <div class="green">手机号码：</div>
                    <el-input v-model="formData.mobile" class="lee-input mobile" placeholder="输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="" prop="account_name" class="margin-bottom-0">
                    <div class="green">账户名：</div>
                    <el-input v-model="formData.account_name" class="lee-input mobile" placeholder="输入账户名"></el-input>
                </el-form-item>
                <el-form-item label="" prop="account_bank" class="margin-bottom-0">
                    <div class="green">银行名称：</div>
                    <el-input v-model="formData.account_bank" class="lee-input mobile" placeholder="输入银行名称"></el-input>
                </el-form-item>
                <el-form-item label="" prop="account_num" class="margin-bottom-0">
                    <div class="green">银行账户：</div>
                    <el-input v-model="formData.account_num" class="lee-input mobile" placeholder="输入银行账户"></el-input>
                </el-form-item>
                <el-form-item label="" prop="money" class="margin-bottom-0">
                    <div class="green">提现金额：</div>
                    <el-input v-model="formData.money" class="lee-input mobile" placeholder="输入提现金额"></el-input>
                </el-form-item>
            </el-form>
            <div class="cl" slot="footer">
                <el-button size="medium" type="info" plain class="box-shadow-30" @click="show = false">关闭</el-button>
                <el-button size="medium" type="success" class="box-shadow-green" @click="submit('form')">提交确认</el-button>
            </div>
        </el-dialog>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            list:[],
            page:1,
            pageSize:20,
            totalCount:0,

            credit:null,

            less_cash:0,

            formData:{
                mobile:this.$user.getMobile(),
                account_name:'',
                account_num:'',
                account_bank:'',
                money:0
            },

            show:false,

            rules: {
                account_name:[{ required: true, message:'请输入账户名'}],
                account_num:[{ required: true, message:'请输入银行账户'}],
                mobile:[{ required: true, message:'请输入手机号码'}],
                account_bank:[{ required: true, message:'请输入银行名称'}],
                money:[
                    { required: true, message:'请输入奖励金额'},
                    {message: '请填写正确的金额',pattern:this.$regexp.money},
                    {message: '金额必须大于0',validator:this.$utils.rulePriceNotZero}
                ],
            },
        }
    },
    mounted() {
        this.getList();
        this.getCredit();
        this.getLessCash();
    },
    methods:{
        getCredit(){
            let _this = this;
            this.$http.post({
                url: '/AuthUserCredit/detail',
                data: null,
                success(res) {
                    _this.credit = res;
                }
            });
        },
        getList(){
            let _this = this;
            this.$http.post({
                url: '/AuthUserCreditLog/listPage',
                data: {page:this.page,type:'balance'},
                success(res) {
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.getList();
        },
        toCash(){
            let _this = this;

            if(parseFloat(this.credit.balance) < parseFloat(this.less_cash)){
                this.$message.error('余额不够，至少需要￥'+this.less_cash);
                return;
            }

            this.$http.post({
                url: '/UserProfile/detail',
                data: null,
                success(res) {
                    _this.formData.account_bank = res.account_bank;
                    _this.formData.account_name = res.account_name;
                    _this.formData.account_num = res.account_num;

                    _this.show = true;
                }
            });
        },
        getLessCash(){
            let _this = this;
            this.$http.post({
                url:'/SystemSetting/detail',
                data:{skey:'less_cash'},
                success(res){
                    _this.less_cash = parseFloat(res.svalue);
                }
            })
        },
        submit(formName){
            let _this = this;

            if(parseFloat(this.credit.balance) < parseFloat(this.less_cash)){
                this.$message.error('余额不够，至少需要￥'+this.less_cash);
                return;
            }

            if(parseFloat(this.formData.money) < parseFloat(this.less_cash)){
                this.$message.error('提现金额不够，至少需要￥'+this.less_cash);
                return;
            }

            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/AuthUserCash/add',
                        data: _this.formData,
                        success(res) {
                            _this.formData.account_num = '';
                            _this.formData.account_bank = '';
                            _this.formData.account_name = '';
                            _this.formData.money = ''

                            _this.show = false;

                            _this.getCredit();
                            _this.getList();

                            _this.$message.success('提现成功');
                        }
                    })
                }
            })
        }
    }
}
</script>
