<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" :back="false" title="项目申报" :bread="[{title:'项目',url:'/prize'},{title:'项目申报'}]" v-if="info">
		<div class="bg-img-11 padding-top-100 padding-bottom-400">
			<div class="wrap1100">
				<div class="box-shadow bg-light padding-30" v-if="info">
					<div class="green-line">
						<div class="cl f34">
							<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
<!--							{{info}}-->
							您正在申报《{{ info.jiangxiang.name }}-{{info.year}}年项目》
						</div>
					</div>
					<template v-if="user">
						<div class="margin-top-20">
							<el-steps :active="active">
								<el-step title="填写基础信息"></el-step>
								<el-step title="填写项目信息"></el-step>
								<el-step title="完成"></el-step>
							</el-steps>
						</div>
						<div style="width: 500px;padding:50px;" v-if="step === 1">
							<el-form ref="form1" :model="formData" label-width="auto" label-position="left" :rules="rules">
								<el-form-item label="企业名称" prop="title">
									<el-input v-model="formData.title" maxlength="20" show-word-limit style="width: 100%;"></el-input>
								</el-form-item>
								<el-form-item label="关键词" prop="tags">
									<el-tag :key="tag" v-for="tag in formData.tags" closable :disable-transitions="false" @close="onCloseTags(tag)" style="margin-right: 10px;">{{tag}}</el-tag>
									<template v-if="inputVisible">
										<el-input style="width: 100px;margin-right: 10px;" v-model="inputValue" ref="saveTagInput" size="small" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm"></el-input>
										<el-button size="small" @click="handleInputConfirm">确定</el-button>
									</template>
									<el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
								</el-form-item>
								<el-form-item label="企业法人" prop="faren">
									<el-input v-model="formData.faren" maxlength="6" show-word-limit style="width: 100%;"></el-input>
								</el-form-item>
								<el-form-item label="企业所在地" prop="city_code">
									<province-city-select v-model="formData.address_tmp" style="width: 100%;" @change="onChangeArea"></province-city-select>
								</el-form-item>
								<el-form-item label="企业性质" prop="type_nature_id">
									<type-select style="width: 100%;" v-model="formData.type_nature_id" :data="{akey:'nature',orderBy:'sort',sortBy:'asc',state:1}"></type-select>
								</el-form-item>
								<el-form-item label="企业属性" prop="type_property_id">
									<type-select style="width: 100%;" v-model="formData.type_property_id" :data="{akey:'property',orderBy:'sort',sortBy:'asc',state:1}"></type-select>
								</el-form-item>
								<el-form-item label="主要网点" prop="main_location">
									<el-input v-model="formData.main_location" maxlength="200" show-word-limit style="width: 100%;"></el-input>
								</el-form-item>
								<el-form-item label="合作客户" prop="customer">
									<el-input v-model="formData.customer" maxlength="200" show-word-limit style="width: 100%;"></el-input>
								</el-form-item>
								<el-form-item label="荣誉" prop="honor">
									<el-input v-model="formData.honor" maxlength="200" show-word-limit style="width: 100%;"></el-input>
								</el-form-item>
								<el-form-item label="企业宣传PPT" prop="ppt">
									<file-upload v-model="formData.ppt" :files="formData.files" style="width: 100%;"></file-upload>
								</el-form-item>
							</el-form>
						</div>
						<div style="width: 500px;padding:50px;" v-if="step === 2">
							<el-form ref="form2" :model="formData2" label-position="left" label-width="auto">
								<el-form-item :label="t.name" :prop="'params_json.'+i+'.value'" v-for="(t,i) in formData2.params_json" :rules="[{required:true,message:t.name+'不能为空'},{pattern:$regexp.money,message:'格式不正确，请输入正确的金额',trigger:'blur'}]">
									<el-input-number v-model="t.value" :min="1" :max="999999" :step="1" v-if="t.type === 'number'"></el-input-number>
									<el-input v-model="t.value" v-if="t.type === 'float'"></el-input>
								</el-form-item>
							</el-form>
						</div>
						<div class="text-center">
							<el-button type="success" class="box-shadow-green" @click="toNext" v-if="step === 1">下一步</el-button>
							<el-button type="info" @click="toPre" v-if="step === 2">上一步</el-button>
							<el-button type="success" class="box-shadow-green" @click="toSubmit" v-if="step === 2">提交审核</el-button>
						</div>
					</template>
					<div class="text-center f16 dark-3" style="margin: 100px auto;" v-else>
						<i class="orange margin-right el-icon-warning"></i>
						请先登录
						<login-button></login-button>
					</div>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
import TypeSelect from '../../components/TypeSelect'
export default {
	name: "List",
	components: {TypeSelect},
	data() {
		return {
			inputVisible: false,
			inputValue: '',
			id: '',
			info: null,
			active:1,
			step:1,
			address:'',
			formData:{
				id:'',
				title:'',
				province_code:'',
				province_name:'',
				city_code:'',
				city_name:'',
				faren:'',
				address_tmp:'',
				type_nature_id:'',
				type_property_id:'',
				main_location:'',
				customer:'',
				honor:'',
				ppt:'',
				tags:[]
			},
			formData2:{
				tid:'',
				company_id:'',
				params_json:[]
			},
			user:null,
			rules:{
				title:[{required:true,message:'企业名称不能为空'}],
				city_code:[{required:true,message:'企业所在地不能为空'}],
				faren:[{required:true,message:'企业法人不能为空'}],
				type_nature_id:[{required:true,message:'企业性质不能为空'}],
				type_property_id:[{required:true,message:'企业属性不能为空'}],
				main_location:[{required:true,message:'企业主要网点不能为空'}],
				customer:[{required:true,message:'合作客户不能为空'}],
				honor:[{required:true,message:'荣誉不能为空'}],
				ppt:[{required:true,message:'企业宣传ppt不能为空'}],
			}
		}
	},
	created() {
		this.id = this.$route.query.id;
		this.user = this.$user.getAll();
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		showInput() {
			this.inputVisible = true;
			this.$nextTick(_ => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},

		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.formData.tags.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		onCloseTags(e){
			this.formData.tags.splice(this.formData.tags.indexOf(e), 1);
		},
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/Prize/detail',
				data: {id: this.id},
				success(res) {
					_this.info = res;
					_this.getMyCompany();
				}
			});
		},
		onChangeArea(e){
			this.formData.province_code = e.provinceCode;
			this.formData.province_name = e.provinceName;
			this.formData.city_code = e.cityCode;
			this.formData.city_name = e.cityName;
		},
		toNext(){
			let _this = this;

			this.$refs.form1.validate(valid => {
				if (valid) {
					let _url = ''
					if(_this.formData.id){
						_url = '/Company/edit'
					}else{
						_url = '/Company/add'
					}
					_this.$http.post({
						url: _url,
						data: _this.formData,
						success(res) {
							console.log(res);
							_this.getMyCompany();
							_this.active = 2;
							_this.step = 2;
							_this.formData2.tid = _this.id;
							_this.formData2.company_id = res.id;
							_this.formData2.params_json = _this.info.params_json;
						}
					});
				}
			})
		},
		getMyCompany(){
			let _this = this;
			this.$http.post({
				url: '/Company/myCompany',
				data: null,
				success(res) {
					console.log(res);
					_this.formData.id = res.id;
					_this.formData.title = res.title;
					_this.formData.province_code = res.province_code;
					_this.formData.province_name = res.province_name;
					_this.formData.city_code = res.city_code;
					_this.formData.city_name = res.city_name;
					_this.formData.faren = res.faren;
					_this.formData.type_nature_id = res.type_nature_id;
					_this.formData.type_property_id = res.type_property_id;
					_this.formData.main_location = res.main_location;
					_this.formData.customer = res.customer;
					_this.formData.honor = res.honor;
					_this.formData.ppt = res.ppt;
					_this.formData.files = res.files;
					_this.formData.tags = res.tags;
					_this.formData.address_tmp = [res.province_code,res.city_code];
				}
			});
		},
		toPre(){
			this.active = 1;
			this.step = 1;
			this.getMyCompany();
		},
		toSubmit(){
			let _this = this;
			this.$refs.form2.validate(valid => {
				if (valid) {
					_this.$http.post({
						url: '/PrizeCompanyItem/add',
						data: this.formData2,
						success(res) {
							_this.$message.success('提交成功');
							_this.$router.replace({path:'/'})
						}
					});
				}
			})
		}
	}
}
</script>
