<template>
	<el-cascader
		size="large"
		:props="{ expandTrigger: 'hover' }"
		:options="options"
		v-model="values"
		@change="change">
	</el-cascader>
</template>

<script>
import {provinceAndCityData, regionData, CodeToText} from 'element-china-area-data'

// import provinceAndCityData from '@/components/miihe-china-area-data/areaCode.json'
// import CodeToText from '@/components/miihe-china-area-data/areaName.json'
export default {
	name: "ProvinceCitySelect",
	data() {
		return {
			options: null,
			values: [],
			name: '',
			prop: {
				value: 'code',
				label: 'name',
				children: 'children',
				expandTrigger: 'hover'
			},
			cols: []
		}
	},
	model: {
		prop: 'value',
		event: 'input'
	},
	props: {
		value: {
			type: [Array, String],
			default() {
				return []
			}
		},
		type: {
			type: String,
			default: 'pc'
		},
		outData: {
			type: String,
			default: 'province,city'
		},
		outType: {
			type: String,
			default: 'array'
		}
	},
	created() {
		this.options = this.type === 'pca' ? regionData : provinceAndCityData;

		// console.log(provinceAndCityData);

		// this.options = provinceAndCityData;
		this.values = this.value ? (typeof this.value === 'string' ? this.value.split(',') : this.value) : [];

		this.cols = this.outData ? this.outData.split(',') : (this.type === 'pca' ? 'province,city,area'.split(',') : 'province,city'.split(','));
	},
	watch: {
		value(n, o) {
			this.values = n ? (typeof n === 'string' ? n.split(',') : n) : [];
		}
	},
	mounted() {

	},
	methods: {
		change(e) {
			console.log(e);
			let _out = {};
			let _labels = [];
			let _province_code = e[0];
			let _province_name = CodeToText[_province_code];
			let _city_code = e[1];
			let _city_name = CodeToText[_city_code];

			_out.provinceCode = _province_code;
			_out.provinceName = _province_name;
			_out.cityCode = _city_code;
			_out.cityName = _city_name;

			if (this.type === 'pca') {
				let _area_code = e[2];
				let _area_name = CodeToText[_area_code];

				_out.areaCode = _area_code;
				_out.areaName = _area_name;
			}

			for (let i = 0; i < e.length; i++) {
				_labels.push(CodeToText[e[i]]);
			}

			let _e = [];

			for (let i = 0; i < this.cols.length; i++) {
				if (!this.$utils.isUndefined(_out[this.cols[i] + 'Code']) && _out[this.cols[i] + 'Code']) {
					_e.push(_out[this.cols[i] + 'Code']);
				}
			}


			if (_e.length === 1) {
				_e = _e[0];
			} else if (_e.length === 2) {
				_e = _e[0] + ',' + _e[1];
			} else {
				_e = _e[0] + ',' + _e[1] + ',' + _e[2];
			}
			console.log(_e)

			this.$emit("input", this.outType === 'array' ? _e.split(',') : (typeof _e === 'string' ? _e : _e.join(',')));

			this.$emit('change', _out);

			this.$emit('label', this.outType === 'array' ? _labels : (typeof _labels === 'string' ? _labels : _labels.join(' / ')));
		}
	}
}
</script>
