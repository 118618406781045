<template>
    <div class="wrap">
        <ul class="cl head">
            <li class="logo"><img src="/images/logo.png" height="40" class="margin-top"/></li>
            <li v-for="(item,index) in $config.topMenu"><a href="javascript:void(0);" :class="$route.path === item.path ? 'on' : ''" @click="$router.push({path:item.path})">{{item.title}}</a></li>
        </ul>
    </div>
</template>

<script>
export default {
name: "Header"
}
</script>

<style scoped>
    .head{list-style: none;height: 52px;}
    .head li{float: left;width:86px;margin-right:40px;}
    .head li.logo{width:40px;}
    .head li a{color:#fff;font-size: 16px;display: block;width: 86px;line-height: 62px;height: 52px; text-align: center;}
    .head li a.on{background: #7ED321;}
</style>
