<template>
    <div>
        <div class="text-center f24 light f-lq padding-top-50" style="height: 120px;background-image: url('/images/bg_head_9.jpg');background-repeat: no-repeat;background-size: 100% 100%;">订单支付</div>
        <div class="text-center">
            <div class="f14 dark-2 padding-top-bottom-40">订单金额：￥<span class="red f22">{{data.pay_amount}}</span></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "pay",
    data(){
        return{
            data:null,
            openid:''
        }
    },
    created() {
        this.data = this.$route.query;
        this.data.pay_type = 'weixin';
    },
    mounted() {
        this.getOpenId();
    },
    methods:{
        toPay(){
            let _this = this;

            this.$http.post({
                url:'/Common/addOrder',
                data:this.data,
                success(res){
                    console.log(res);
                    if (typeof WeixinJSBridge == "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', _this.onBridgeReady, false);
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', _this.onBridgeReady);
                            document.attachEvent('onWeixinJSBridgeReady', _this.onBridgeReady);
                        }
                    } else {
                        _this.onBridgeReady(res);
                    }
                }
            })
        },
        onBridgeReady(data){
            var _this = this;
            WeixinJSBridge.invoke(
                'getBrandWCPayRequest', data,
                function(res){
                    if(res.err_msg === "get_brand_wcpay_request:ok" ){
                        _this.$toast({
                            message: '支付成功',
                            position: 'top',
                        });
                        _this.$router.replace({path:'/order/paydone'});
                    }else{
                        _this.$toast({
                            message: '支付失败',
                            position: 'top',
                        });
                    }
                });
        },
        getOpenId(){
            let _this = this;
            if (!this.data.openid) {
                if (!this.$route.query.code) {
                    let _url = window.location.href;
                    let _wxUrl = this.$utils.getWxCodeUrl(_url);
                    window.location.replace(_wxUrl);
                } else {
                    this.$http.post({
                        url: '/Common/getOpenid',
                        data: {code: this.$route.query.code},
                        success: function (res) {
                            if(res.openid) {
                                _this.$user.setOpenId(res.openid);
                                _this.data.openid = res.openid;
                            }
                        }
                    });
                }
            }
        }
    }
}
</script>
