<template>
    <lee-user-main-page ref="main">
        <lee-user-page>
            <lee-user-top-tab slot="headRight"></lee-user-top-tab>
            <div style="width: 530px;" class="padding-bottom-100">
                <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" :show-message="false" :inline-message="true">
                    <div class="form-title">用户名</div>
                    <el-input v-model="formData.username" :readonly="true" class="lee-input mobile" placeholder="用户名"></el-input>
                    <div class="form-title">昵称</div>
                    <el-input v-model="formData.nickname" class="lee-input mobile" placeholder="输入昵称" maxlength="15" show-word-limit></el-input>
                    <!--                <lee-input placeholder="输入昵称" v-model="formData.nickname" class="bg-gray-5 mobile"/>-->
                    <div class="form-title margin-top">性别</div>
                    <el-radio-group v-model="formData.sex" class="lee-radio bg-gray-5">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="2">女</el-radio>
                    </el-radio-group>
                    <div class="form-title margin-top">邮箱</div>
                    <el-input v-model="formData.email" class="lee-input mobile" placeholder="输入邮箱" maxlength="30" show-word-limit></el-input>
                    <!--            <lee-cell :title="formData.email">-->
                    <!--                <div class="y green hand" slot="right">修改</div>-->
                    <!--            </lee-cell>-->
                    <div class="form-title margin-top">手机</div>
                    <el-input v-model="formData.mobile" :readonly="true" class="lee-input mobile" placeholder="输入手机" maxlength="11" show-word-limit></el-input>
                    <!--            <lee-cell :title="formData.mobile">-->
                    <!--                <div class="y green hand" slot="right">修改</div>-->
                    <!--            </lee-cell>-->
                    <div class="form-title margin-top">姓名</div>
                    <el-input v-model="formData.name" class="lee-input mobile" placeholder="输入姓名" maxlength="10" show-word-limit></el-input>
                    <!--                <lee-input placeholder="输入联系人" v-model="formData.contact" class="bg-gray-5 mobile"/>-->
                    <el-button type="success" class="padding-left-50 padding-right-50 f18 f-n box-shadow-green margin-top-50" @click="submit('form')">保存</el-button>
                </el-form>
            </div>
        </lee-user-page>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "Base",
    data(){
        return{
            formData:{
                sex:1,
                nickname:'',
                email:'',
                mobile:'',
                name:'',
                username:''
            },
            rules: {
                mobile:[{ required: true, message:'请选择问题分类'}],
                // email:[{ required: true, message:'请输入问题标题'}],
                sex:[{ required: true, message:'请确认问题内容'}],
                nickname:[{ required: true, message:'请填写昵称'}],
                // contact:[{ required: true, message:'请确认问题内容'}],
            }
        }
    },
    created() {
        if(this.$isMobile){
            this.$router.replace({path:'/user/profile/mobile'});
        }
    },
    mounted() {
        this.getAll();
    },
    methods:{
        getAll(){
            this.formData.username = this.$user.getUserName();
            // this.formData.sex = this.formData.sex ? parseInt(this.formData.sex) : '';

            let _this = this;
            //getBase
            this.$http.post({
                url:'/UserProfile/getBase',
                data:null,
                success(res){
                    _this.formData.sex = res.sex;
                    _this.formData.mobile = res.mobile;
                    _this.formData.name = res.name;
                    _this.formData.nickname = res.nickname;
                    _this.formData.email = res.email;
                }
            })
        },
        submit(formName){
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/UserProfile/editBase',
                        data: this.formData,
                        success(res) {
                            if(res === 'ok') {
                                _this.$user.setEmail(_this.formData.email ? _this.formData.email : '');
                                _this.$user.setNickName(_this.formData.nickname ? _this.formData.nickname : '');
                                _this.$user.setSex(_this.formData.sex ? _this.formData.sex : '');
                                _this.$user.setMobile(_this.formData.mobile ? _this.formData.mobile : '');
                                _this.$user.setContact(_this.formData.name ? _this.formData.name : '');

                                _this.$message.success('修改成功');

                                _this.$refs.main.getNickname();
                                _this.$refs.main.getMobile();

                                _this.getAll();
                            }
                        }
                    })
                }
            })
        }
    }
}
</script>
