<template>
<!--	<el-row class="bg-light cl box-shadow-bottom hide">-->
<!--		<el-col :span="24 / list.length" v-for="(item,index) in list">-->
<!--			<div class="text-center hy-data-item" :class="{on:type === item[valueKey]}">-->
<!--				<span @click="change(item)"><i style="margin-left: 5px;" :class="item[iconKey]"></i>{{item[labelKey]}}</span>-->
<!--			</div>-->
<!--&lt;!&ndash;			<div class="text-center hand border-radius-5 hide lee-tab-item" :class="type === item[valueKey] ? 'on '+activeClass : ''" @click="change(item)">&ndash;&gt;-->
<!--&lt;!&ndash;				<img :src="item[iconKey]+(type === item[valueKey] ? 'on' : 'off') + '.png'" height="20" class="margin-right" v-if="hasIcon"/>&ndash;&gt;-->
<!--&lt;!&ndash;				{{ item[labelKey] }}&ndash;&gt;-->
<!--&lt;!&ndash;			</div>&ndash;&gt;-->
<!--		</el-col>-->
<!--	</el-row>-->
	<div class="bg-light hide" style="display: flex;flex-direction: row;align-items: center;justify-content: space-around;">
		<div class="text-center hy-data-item" :class="{on:type === item[valueKey]}" v-for="(item,index) in list">
			<span @click="change(item)"><i style="margin-left: 5px;" :class="item[iconKey]" v-if="hasIcon"></i>{{item[labelKey]}}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: "Tabs",
	data() {
		return {
			type: ''
		}
	},
	props: {
		list: {
			type: Array,
			default: () => {
				return []
			}
		},
		defaultIndex: {
			type: [String, Number],
			default: ''
		},
		activeClass: {
			type: String,
			default: 'bg-green-line'
		},
		valueKey: {
			type: String,
			default: 'value'
		},
		labelKey: {
			type: String,
			default: 'label'
		},
		iconKey: {
			type: String,
			default: 'icon'
		},
		hasIcon: {
			type: Boolean,
			default: true
		},
	},
	created() {
		if (this.defaultIndex) {
			this.type = this.defaultIndex;
		} else {
			if (this.list.length > 0) {
				this.type = this.list[0][this.valueKey];
			}
		}

		if (this.$route.query.mmtype) {
			this.type = this.$route.query.mmtype;
		}
		this.$emit('change', {value: this.type})
	},
	methods: {
		change(item) {
			// this.$emit('change',item);
			this.type = item[this.valueKey];
			this.$router.replace({query: {mmtype: item[this.valueKey]}})
		}
	}
}
</script>
<style>
.hy-data-item.on span, .hy-data-item span:hover{border-bottom: 3px solid #67C23A;color: #67C23A;}
.hy-data-item span {
	height: 50px;
	line-height: 50px;
	display: inline-block;
	cursor: pointer;
}
</style>
