<template>
    <div class="cl f14 border-radius-5 padding-left-right bg-gray-5" style="height:40px;line-height: 40px;">
        <div class="z">{{title}}</div>
        <slot name="right"></slot>
    </div>
</template>

<script>
export default {
    name: "Cell",
    props:{
        title:{
            type:String,
            default:''
        }
    }
}
</script>
