<template>
    <lee-user-main-page type="dark">
        <lee-user-page class="padding-bottom-100">
            <template v-if="list.length > 0">
                <lee-base-cell right-title="查看" v-for="(item,index) in list" title-class="green f14 f-n" img="/images/icon/icon_good.png" @rightClick="$router.push({path:'/'+item.akey+'/detail/'+item.slug.id})">
                    <div slot="subTitle">
                        <div class="f16 dark-1" :class="$isMobile ? 'text-line-2' : 'text-line-1'" style="line-height: 24px;">{{item.slug.title}}</div>
                        <div class="dark-3 f-thin text-line-2" :class="$isMobile ? 'f12 text-line-1' : 'f14'" style="line-height: 20px;">{{item.slug.content}}</div>
                        <div class="dark-3 f-thin" :class="$isMobile ? 'f12' : 'f14 margin-top-30'" style="line-height: 20px;">时间：{{item.create_time}}</div>
                    </div>
                </lee-base-cell>
                <div class="text-center margin-top-100" v-if="!$isMobile">
                    <el-pagination
                        :hide-on-single-page="true"
                        background
                        layout="pager"
                        :page-size="pageSize"
                        :current-page.sync="page"
                        :total="totalCount"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
            </template>
            <el-empty description="暂无记录" v-else></el-empty>
        </lee-user-page>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "BuyLog",
    data(){
        return{
            list:[],
            page:1,
            pageSize:15,
            totalCount:0
        }
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.post({
                url: '/UserLog/listPage',
                data: {page:this.page,type:'like',uid:this.$user.getUid()},
                success(res) {
                    _this.list = res.list;
                    _this.totalCount = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.getList();
        },
    }
}
</script>
