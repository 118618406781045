<template>
	<div style="width: 400px;margin:100px auto;">
		<el-form :model="formData" label-position="left" label-width="auto">
			<el-form-item label="邀请码" prop="code" :rules="[{required:true,message:'请填写验证码'}]">
				<el-input v-model="formData.code" maxlength="6" show-word-limit></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="success" class="box-shadow-green" @click="toJoin">确定</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: "joinCompany",
	data() {
		return {
			formData:{
				code:''
			}
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toJoin(){
			let _this = this;
			this.$http.post({
				url: '/UserCompanyCodeLog/toUse',
				data: this.formData,
				success(res) {
					_this.$emit('success')
				}
			});
		},
	}
}
</script>
