<template>
    <div class="padding margin-top bg-light border-radius-5 padding-bottom-30 user-menu">
        <div class="hand" :class="$route.path.indexOf(item.path) >= 0  ? 'light f-b on' : 'green-hover dark-2 f-thin'" style="height: 50px;line-height: 50px;padding-left:70px;" v-for="(item,index) in $config.userMenu" @click="$router.push({path:item.path})">
            <img :src="item.icon + ($route.path.indexOf(item.path) >= 0 ? '_on.png' : '_off.png')" height="18" width="18" class="margin-right"/>
            <span class="vm f18">{{item.title}}</span>
        </div>
<!--        <div class="border-radius-5 hide inline-block tab-trans-on abs" style="height:50px;left:10px;right:10px;top:10px;" :style="{transform:'translateY('+top+'px)'}">-->
<!--            <span class="tab-round-bg"></span>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "UserMenu",
    data(){
        return{
            top:0,
            activeIndex:0
        }
    },
    props:{

    },
    mounted() {
    },
    methods:{

    }
}
</script>
