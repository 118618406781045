<template>
	<lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="冷藏车相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷藏车'}]">
		<div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}" v-if="data">
			<check-vip akey="hangyedata_car">
				<div class="wrap1100">
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<div class="green-line">
							<div class="f34 flex flex-ai flex-jc-between">
								<div class="flex flex-ai">
									<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
									<div class="f22">冷藏车相关数据</div>
								</div>
								<div class="flex flex-ai">
									<el-select v-model="searchData" @change="onChangeYear">
										<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
									</el-select>
									<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
										<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
									</el-select>
								</div>
							</div>
						</div>
						<div id="#a6">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按保有量分析</span>
							</div>
							<div class="f16 margin-top-20 dark-2" v-if="data">
								{{data.count.now.content1 ? data.count.now.content1 : ''}}
							</div>
							<div class="text-center margin-top-20">
								<lee-bar-echarts
									key="bar1"
									ref="bar1"
									:grid-bottom="30"
									:grid-top="30"
									left-name="（万辆）"
									height="350px"
									show-legend
								></lee-bar-echarts>
							</div>
						</div>
						<div id="#a77">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按销量分析</span>
							</div>
							<div class="f16 margin-top-20 dark-2" v-if="data">
								{{data.count.now.content2 ? data.count.now.content2 : ''}}
							</div>
							<el-row :gutter="10">
								<el-col :span="!!searchQuarterData ? 12 : 24">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar1_1"
											ref="bar1_1"
											:grid-bottom="30"
											:grid-top="30"
											left-name="（辆）"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</el-col>
								<el-col :span="12" v-if="!!searchQuarterData">
									<div class="text-center margin-top-20">
										<lee-bar-echarts
											key="bar1_2"
											ref="bar1_2"
											:grid-bottom="30"
											:grid-top="30"
											left-name="（辆）"
											height="250px"
											show-legend
										></lee-bar-echarts>
									</div>
								</el-col>
							</el-row>
						</div>
						<div id="#a1">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按地区分析</span>
							</div>
							<div class="f16 margin-top-20 dark-2" v-if="data">
								{{data.area.now.content1 ? data.area.now.content1 : ''}}
							</div>
							<div class="text-center margin-top-20">
								<el-row>
									<el-col :span="12">
										<lee-bar-echarts-hor
											key="bar2"
											ref="bar2"
											:grid-bottom="10"
											:grid-top="10"
											grid-right="10%"
											height="350px"
											:show-legend="false"
											legend-orient="vertical"
											legend-pos="right"
											:show-left="true"
											:show-right="false"
										></lee-bar-echarts-hor>
									</el-col>
									<el-col :span="12">
										<lee-pie-echarts
											key="pie1"
											ref="pie1"
											:grid-bottom="30"
											height="350px"
											:show-legend="false"
											label-format="{b}:{d}%"
											label-pos="outside"
											:radius="['30%','50%']"></lee-pie-echarts>
									</el-col>
								</el-row>
							</div>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按车型分析</span>
							</div>
							<div class="f16 margin-top-20 dark-2" v-if="data">
								{{data.type.now.content1 ? data.type.now.content1 : ''}}
							</div>
							<div class="text-center margin-top-20">
								<el-row>
									<el-col :span="12">
										<lee-bar-echarts-hor
											key="bar3"
											ref="bar3"
											:grid-bottom="10"
											:grid-top="10"
											grid-right="10%"
											height="350px"
											:show-legend="false"
											legend-orient="vertical"
											legend-pos="right"
											:show-left="true"
											:show-right="false"
										></lee-bar-echarts-hor>
									</el-col>
									<el-col :span="12">
										<lee-pie-echarts
											key="pie3_3"
											ref="pie3_3"
											:grid-bottom="30"
											height="350px"
											:show-legend="false"
											label-format="{b}:{d}%"
											label-pos="outside"
											:radius="['30%','50%']"></lee-pie-echarts>
									</el-col>
								</el-row>
							</div>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按燃料分析</span>
							</div>
							<div class="f16 margin-top-20 dark-2" v-if="data">
								{{data.oil.now.content1 ? data.oil.now.content1 : ''}}
							</div>
							<div class="text-center margin-top-20">
								<el-row>
									<el-col :span="12">
										<lee-bar-echarts-hor
											key="bar4"
											ref="bar4"
											:grid-bottom="10"
											:grid-top="10"
											grid-right="10%"
											height="350px"
											:show-legend="false"
											legend-orient="vertical"
											legend-pos="right"
											:show-left="true"
											:show-right="false"
										></lee-bar-echarts-hor>
									</el-col>
									<el-col :span="12">
										<lee-pie-echarts
											key="pie4_4"
											ref="pie4_4"
											:grid-bottom="30"
											height="350px"
											:show-legend="false"
											label-format="{b}:{d}%"
											label-pos="outside"
											:radius="['30%','50%']"></lee-pie-echarts>
									</el-col>
								</el-row>
							</div>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按省份分析</span>
							</div>
							<div class="f16 margin-top-20 dark-2" v-if="data">
								{{data.province.now.content1 ? data.province.now.content1 : ''}}
							</div>
							<div class="text-center margin-top-20">
								<color-china-echarts ref="china" height="600px" @click="onClickMap" @onload="onClickMap"></color-china-echarts>
							</div>
						</div>
						<div class="margin-top-20">
							<div style="border-bottom: 2px solid #7ED321;">
								<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按厂商分析</span>
							</div>
							<div class="margin-top" v-if="data.supplier && data.supplier.now && data.supplier.now.supplierList.length > 0">
								<div class="f16 margin-top-20 dark-2" v-if="data">
									{{data.supplier.now.content1 ? data.supplier.now.content1 : ''}}
								</div>
								<table class="lee-table margin-auto" style="width:100%;margin-top: 20px;">
									<thead>
									<td width="300">厂商名称</td>
									<td>车辆数量</td>
									</thead>
									<tr v-for="(item,index) in data.supplier.now.supplierList">
										<td>{{ item.name }}</td>
										<td>{{ item.num }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
		let _this = this;
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCar/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCar/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		onClickMap(e){
			console.log(e);
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataColdCar/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata1_1 = {bar1: [], line1: [], xaxis: []};
					let _bardata1_2 = {bar1: [], line1: [], xaxis: []};
					let _bardata3 = {bar1: [], xaxis: ['MPV专用','皮卡','轻卡','轻客','微卡','微客','中卡','重卡']};
					let _pie3_3Data = [];
					let _bardata4 = {bar1: [],xaxis: ['柴油','柴油混合动力','纯电动','甲醇混合动力','汽油','汽油混合动力','燃料电池','天然气','天然气混合动力']};
					let _pie4_4Data = [];
					let _bar2data = {bar: [], bar2: [], xaxis: []};
					let _pie1Data = [];
					let _chinaData = [];

					let _last_area = res.area.list[res.area.list.length - 1];
					let _pre_area = res.area.list[res.area.list.length - 2];

					_pie1Data.push({value: _last_area.huazhong, name: '华中'});
					_pie1Data.push({value: _last_area.huadong, name: '华东'});
					_pie1Data.push({value: _last_area.huanan, name: '华南'});
					_pie1Data.push({value: _last_area.huabei, name: '华北'});
					_pie1Data.push({value: _last_area.dongbei, name: '东北'});
					_pie1Data.push({value: _last_area.xinan, name: '西南'});
					_pie1Data.push({value: _last_area.xibei, name: '西北'});

					_bar2data.bar.push(_last_area.huazhong);
					_bar2data.bar.push(_last_area.huadong);
					_bar2data.bar.push(_last_area.huanan);
					_bar2data.bar.push(_last_area.huabei);
					_bar2data.bar.push(_last_area.dongbei);
					_bar2data.bar.push(_last_area.xinan);
					_bar2data.bar.push(_last_area.xibei);

					for(let i=0;i< res.province.area.length;i++){
						// _bardata5.bar1.push(this.data.province.list[i][province_py]);
						// _bardata5.xaxis.push(this.data.province.list[i].time_str);

						_chinaData.push({name:res.province.area[i].name,value:parseInt(res.province.area[i].value)});
						// _chinaData.push({name:res.province.[i].name,value:parseInt(res.province_list[i].value)});
					}

					for (let i = 0; i < res.count.list.length; i++) {
						_bardata1.bar1.push(res.count.list[i].owner);
						_bardata1_1.bar1.push(res.count.list[i].out);
						_bardata1.line1.push(res.count.list[i].tongbi);
						_bardata1_1.line1.push(res.count.list[i].huanbi);
						_bardata1.xaxis.push(res.count.list[i].time_str);
						_bardata1_1.xaxis.push(res.count.list[i].time_str);
					}

					for (let i = 0; i < res.count.listAll.length; i++) {
						_bardata1_2.bar1.push(res.count.listAll[i].out);
						_bardata1_2.xaxis.push(res.count.listAll[i].time_str);
					}

					if(res.type.now) {
						_bardata3.bar1.push(res.type.now ? res.type.now.mpv : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.pika : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.qingka : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.qingke : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.weika : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.weike : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.middle : 0);
						_bardata3.bar1.push(res.type.now ? res.type.now.heavy : 0);

						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.mpv : 0), name: 'MPV专用'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.pika : 0), name: '皮卡'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.qingka : 0), name: '轻卡'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.qingke : 0), name: '轻客'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.weika : 0), name: '微卡'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.weike : 0), name: '微客'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.middle : 0), name: '中卡'});
						_pie3_3Data.push({value: parseInt(res.type.now ? res.type.now.heavy : 0), name: '重卡'});
					}

					if(res.oil.now){
						_bardata4.bar1.push(res.oil.now.chaiyou);
						_bardata4.bar1.push(res.oil.now.cyhhdl);
						_bardata4.bar1.push(res.oil.now.diandong);
						_bardata4.bar1.push(res.oil.now.jchhdl);
						_bardata4.bar1.push(res.oil.now.qiyou);
						_bardata4.bar1.push(res.oil.now.qyhhdl);
						_bardata4.bar1.push(res.oil.now.rldc);
						_bardata4.bar1.push(res.oil.now.tianranqi);
						_bardata4.bar1.push(res.oil.now.trqhhdl);

						_pie4_4Data.push({value: parseInt(res.oil.now.chaiyou), name: '柴油'});
						_pie4_4Data.push({value: parseInt(res.oil.now.cyhhdl), name: '柴油混合动力'});
						_pie4_4Data.push({value: parseInt(res.oil.now.diandong), name: '纯电动'});
						_pie4_4Data.push({value: parseInt(res.oil.now.jchhdl), name: '甲醇混合动力'});
						_pie4_4Data.push({value: parseInt(res.oil.now.qiyou), name: '汽油'});
						_pie4_4Data.push({value: parseInt(res.oil.now.qyhhdl), name: '汽油混合动力'});
						_pie4_4Data.push({value: parseInt(res.oil.now.rldc), name: '燃料电池'});
						_pie4_4Data.push({value: parseInt(res.oil.now.tianranqi), name: '天然气'});
						_pie4_4Data.push({value: parseInt(res.oil.now.trqhhdl), name: '天然气混合动力'});
					}

					setTimeout(()=>{
						if(_this.$refs.china){
							_this.$refs.china.initData(_chinaData);
						}
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷藏车保有量', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '万辆'},
									{name: '保有量同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if (_this.$refs.bar1_1) {
							_this.$refs.bar1_1.initData(
								[
									{name: '冷藏车销量', type: 'bar', color: 'green', data: _bardata1_1.bar1, dw: '辆'},
									{name: '销量同比', type: 'line', color: 'red', data: _bardata1_1.line1, dw: '%'},
								],
								_bardata1_1.xaxis
							);
						}

						if (_this.$refs.bar1_2) {
							_this.$refs.bar1_2.initData(
								[
									{name: '冷藏车销量', type: 'bar', color: 'blue', data: _bardata1_2.bar1, dw: '辆'},
								],
								_bardata1_2.xaxis
							);
						}

						if (_this.$refs.pie1) {
							_this.$refs.pie1.initData(_pie1Data);
						}

						if (_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: _last_area.time_str, type: 'bar', color: 'most', data: _bar2data.bar, dw: '辆'},
									// {name: _pre_area.time_str, type: 'bar', color: 'blue', data: _bar2data.bar2, dw: '辆'},
								],
								['华中', '华东', '华南', '华北', '东北', '西南', '西北']
							);
						}

						if (_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '车型数量', type: 'bar', color: 'most', data: _bardata3.bar1, dw: '辆'},
								],
								_bardata3.xaxis
							);
						}
						if(_this.$refs.pie3_3) {
							_this.$refs.pie3_3.initData(_pie3_3Data);
						}


						if (_this.$refs.bar4) {
							_this.$refs.bar4.initData(
								[
									{name: '车辆数量', type: 'bar', color: 'most', data: _bardata4.bar1, dw: '辆'},
									// {name: '同比', type: 'line', color: 'purple', data: _bardata4.line1, dw: '%'},
								],
								_bardata4.xaxis
							);
						}
						console.log(_this.$refs.pie4_4);
						if(_this.$refs.pie4_4) {
							_this.$refs.pie4_4.initData(_pie4_4Data);
						}
					},300)
				}
			})
		},
	}
}
</script>
