<template>
	<el-select v-model="values" placeholder="请选择" @change="change" style="width: 500px;">
		<el-option
			v-for="item in list"
			:key="item.value"
			:label="item.label"
			:value="item.value"></el-option>
	</el-select>
</template>

<script>
export default {
	name: "ProvinceSelect",
	data() {
		return {
			values:'',
			list:[
				{label:'北京', value:'beijing'},
				{label:'天津', value:'tianjing'},
				{label:'河北', value:'hebei'},
				{label:'山东', value:'shandong'},
				{label:'江苏', value:'jiangsu'},
				{label:'浙江', value:'zhejiang'},
				{label:'上海', value:'shanghai'},
				{label:'广东', value:'guangdong'},
				{label:'福建', value:'fujian'},
				{label:'海南', value:'hainan'},
				{label:'安徽', value:'anhui'},
				{label:'湖北', value:'hubei'},
				{label:'湖南', value:'hunan'},
				{label:'江西', value:'jiangxi'},
				{label:'黑龙江', value:'heilongjiang'},
				{label:'吉林', value:'jilin'},
				{label:'辽宁', value:'liaoning'},
				{label:'新疆', value:'xinjiang'},
				{label:'甘肃', value:'gansu'},
				{label:'宁夏', value:'ningxia'},
				{label:'陕西', value:'shanxi'},
				{label:'青海', value:'qinghai'},
				{label:'西藏', value:'xizang'},
				{label:'山西', value:'shanxi2'},
				{label:'四川', value:'sichuan'},
				{label:'重庆', value:'chongqing'},
				{label:'贵州', value:'guizhou'},
				{label:'云南', value:'yunnan'},
				{label:'广西', value:'guangxi'},
				{label:'内蒙古', value:'menggu'},
				{label:'河南', value:'henan'},
			],
		}
	},
	model:{
		prop:'value',
		event:'change'
	},
	props:{
		value:{
			type:[String,Array,Object],
			default(){
				return {}
			}
		},
	},
	created() {
		this.values = this.value ? this.value : '';
	},
	watch:{
		value(n,o){
			this.values = n ? n : '';
		}
	},
	mounted() {

	},
	methods: {
		change(e){
			this.$emit("change", e);
		}
	}
}
</script>
