<template>
    <div class="bg-light fixed padding block box-shadow text-center vm" slot="footer" style="bottom:0;left:0;">
        <slot></slot>
    </div>
</template>

<script>
export default {
name: "FooterFix"
}
</script>
