<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">进出口总值相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="f16 margin-top-20 dark-2" v-if="data && data.now && data.now.content1">
			{{data.now.content1 ? data.now.content1 : ''}}
		</div>
		<div class="margin-top-20">
			<el-row :gutter="10">
				<el-col :span="!!searchQuarterData ? 12 : 24">
					<div class="text-center margin-top-20">
						<lee-bar-echarts
							key="bar1"
							ref="bar1"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿美元）"
							height="300px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
				<el-col :span="12" v-if="!!searchQuarterData">
					<div class="text-center margin-top-20">
						<lee-bar-echarts
							key="bar1_1"
							ref="bar1_1"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿美元）"
							height="300px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="margin-top-20">
			<el-row :gutter="10">
				<el-col :span="!!searchQuarterData ? 12 : 24">
					<div class="text-center">
						<lee-bar-echarts
							key="bar2"
							ref="bar2"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿美元）"
							height="300px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
				<el-col :span="12" v-if="!!searchQuarterData">
					<div class="text-center">
						<lee-bar-echarts
							key="bar2_2"
							ref="bar2_2"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿美元）"
							height="300px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="margin-top-20">
			<el-row :gutter="10">
				<el-col :span="!!searchQuarterData ? 12 : 24">
					<div class="text-center">
						<lee-bar-echarts
							key="bar3"
							ref="bar3"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿美元）"
							height="300px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
				<el-col :span="12" v-if="!!searchQuarterData">
					<div class="text-center">
						<lee-bar-echarts
							key="bar3_3"
							ref="bar3_3"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿美元）"
							height="300px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	name: "InOutZongzhi",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getMonth()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataInout/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getMonth();
				}
			});
		},
		getMonth() {
			let _this = this;

			this.$http.post({
				url: '/HydataInout/getMonth',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		formatBar(type, dw = '万吨') {
			let _bardata = {bar: [],line:[],};

			for (let i = 0; i < data.length; i++) {
				_bardata.bar.push(data[i].total);
			}

			return {name: dw, type: 'bar', color: 'green', data: _bardata.bar};
		},

		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'month';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataInout/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata1 = {bar: [],line:[], xaxis: []};
					let _bardata1_1 = {bar: [],xaxis: []};
					let _bardata2 = {bar: [],line:[], xaxis: []};
					let _bardata2_2 = {bar: [], xaxis: []};
					let _bardata3 = {bar: [],line:[], xaxis: []};
					let _bardata3_3 = {bar: [], xaxis: []};

					if(res && res.list.length > 0){
						for(let i=0;i < res.list.length;i++){
							_bardata1.bar.push(parseFloat(res.list[i].amount_inout));
							_bardata1.line.push(parseFloat(res.list[i].tongbi_inout));
							_bardata1.xaxis.push(res.list[i].time_str);

							_bardata2.bar.push(parseFloat(res.list[i].amount_in));
							_bardata2.line.push(parseFloat(res.list[i].tongbi_in));
							_bardata2.xaxis.push(res.list[i].time_str);

							_bardata3.bar.push(parseFloat(res.list[i].amount_out));
							_bardata3.line.push(parseFloat(res.list[i].tongbi_out));
							_bardata3.xaxis.push(res.list[i].time_str);
						}

						setTimeout(()=>{
							if(_this.$refs.bar1){
								_this.$refs.bar1.initData(
									[
										{name: '进出口总值', type: 'bar', color: 'green', data: _bardata1.bar, dw: '亿美元'},
										{name: '同比', type: 'line', color: 'purple', data: _bardata1.line, dw: '%'},
									],
									_bardata1.xaxis
								);
							}

							if(_this.$refs.bar2){
								_this.$refs.bar2.initData(
									[
										{name: '进口总值', type: 'bar', color: 'blue', data: _bardata2.bar, dw: '亿美元'},
										{name: '同比', type: 'line', color: 'purple', data: _bardata2.line, dw: '%'},
									],
									_bardata2.xaxis
								);
							}

							if(_this.$refs.bar3){
								_this.$refs.bar3.initData(
									[
										{name: '出口总值', type: 'bar', color: 'orange', data: _bardata3.bar, dw: '亿美元'},
										{name: '同比', type: 'line', color: 'purple', data: _bardata3.line, dw: '%'},
									],
									_bardata3.xaxis
								);
							}
						},200)
					}

					if(!!_this.searchQuarterData){
						if(res && res.listAll.length > 0){
							for(let i=0;i < res.listAll.length;i++){
								_bardata1_1.bar.push(parseFloat(res.listAll[i].amount_inout));
								_bardata1_1.xaxis.push(res.listAll[i].time_str);

								_bardata2_2.bar.push(parseFloat(res.listAll[i].amount_in));
								_bardata2_2.xaxis.push(res.listAll[i].time_str);

								_bardata3_3.bar.push(parseFloat(res.listAll[i].amount_out));
								_bardata3_3.xaxis.push(res.listAll[i].time_str);
							}

							setTimeout(()=>{
								if(_this.$refs.bar1_1){
									_this.$refs.bar1_1.initData(
										[
											{name: '进出口总值', type: 'bar', color: 'green', data: _bardata1_1.bar, dw: '亿美元'},
										],
										_bardata1_1.xaxis
									);
								}

								if(_this.$refs.bar2_2){
									_this.$refs.bar2_2.initData(
										[
											{name: '进口总值', type: 'bar', color: 'blue', data: _bardata2_2.bar, dw: '亿美元'},
										],
										_bardata2_2.xaxis
									);
								}

								if(_this.$refs.bar3_3){
									_this.$refs.bar3_3.initData(
										[
											{name: '出口总值', type: 'bar', color: 'orange', data: _bardata3_3.bar, dw: '亿美元'},
										],
										_bardata3_3.xaxis
									);
								}
							},200)
						}
					}
				}
			})
		},
	}
}
</script>
