<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :title="data.title" des="" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'行业发展报告',url:'/yanjiu/list/industry'},{title:data.title}]" height="280" v-if="data">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" style="background:#f8f9fa">
            <div class="cl"></div>
            <div class="wrap1100">
                <div class="cl"></div>
                <div class="box-shadow bg-light margin-top-30 padding-30" v-if="data">
                    <div class="border-bottom cl f30 padding-bottom">
                        <img src="/images/icon/icon_36.png" height="22" class="no-v-mid"/>
                        {{data.title}}
                    </div>
                    <div class="cl margin-top-20">
                        <img :src="data.head_img.indexOf('http') >= 0 ? data.head_img : $config.assetsUrl + data.head_img" :width="250" class="box-shadow z"/>
                        <div class="z margin-left-20" style="width: 750px;">
                            <div class="f30">{{data.name}}</div>
                            <ul class="book-ul">
                                <li>作者：{{data.author}}</li>
                                <li>出版社：{{data.publisher}}</li>
                                <li v-if="!$isMobile">摘要：{{data.content}}</li>
                                <li v-if="!$isMobile">出版时间：{{data.push_time}}</li>
                            </ul>
                            <div class="dark-3 f-thin margin-top-20 f16" style="line-height:30px;">
                                价格：￥<b class="f30 f-n red">{{data.price}}</b>
                            </div>
                            <el-button type="warning" class="margin-top-20 text-center border-radius-0" @click="toPay" style="width: 150px">立即购买</el-button>
                        </div>
                    </div>
                    <div class="green f-b f18 margin-top-30">图书简介</div>
                    <div class="f16 dark-1" v-html="data.book_info"></div>
                    <div class="green f-b f18 margin-top-30">作者简介</div>
                    <div class="f16 dark-1" v-html="data.author_info"></div>
                    <div class="green f-b f18 margin-top-30">目录</div>
                    <div class="f16 dark-1" v-html="data.book_list"></div>
	                <lee-good-view class="margin-top-50" :id="data.id" akey="industry" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1" :open-comment="data.open_comment === 1"></lee-good-view>
                </div>
                <div class="box-shadow padding-30 bg-light margin-top-30" v-if="data.open_comment === 1">
                    <lee-comment-view total-count="30" akey="industry" :id="data.id"></lee-comment-view>
                </div>
<!--                <lee-good-view v-if="$isMobile" class="margin-top-20"></lee-good-view>-->
            </div>
        </div>
        <el-dialog
            title="填写收件人信息"
            :visible.sync="show">
            <lee-add-order :order_type="data.type" :id="id" @onPay="onPay"></lee-add-order>
        </el-dialog>
        <el-dialog
            title="购买报告"
            :visible.sync="showPay" @close="closePay">
<!--            <lee-pay-view :name="data.title" :price="data.price" title="购买报告" sub-title="请扫码右侧的二维码联系购买" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="data" akey="industry"></lee-pay-view>-->
          <lee-pay-view :name="data.title" :price="data.price" title="购买报告" sub-title="请扫码右侧的二维码联系购买" file-size="" :qrcode="qrcode.indexOf('http') >= 0 ? qrcode : $config.assetsUrl + qrcode" :phone="contact_phone" v-if="data" akey="industry"></lee-pay-view>
        </el-dialog>
    </lee-page>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data';
export default {
    name: "List",
    data(){
        return{
            cityData:[],
            options: regionData,
            show:false,
            showPay:false,
            data:null,
            time:null,
            qrcode:'',
            contact_phone:'',
            id:'',
            order_id:'',
            rules:{
                id: [{required: true}],
                consignee: [{required: true}],
                phone: [{required: true}],
                province_code: [{required: true}],
                province_name: [{required: true}],
                city_code: [{required: true}],
                city_name: [{required: true}],
                area_code: [{required: true}],
                area_name: [{required: true}],
                address: [{required: true}],
            },
            payData:{id:''}
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        this.getDetail();
    },
    methods:{
        toPay(v){
            if(!this.$user.getToken()){
                this.$message.error('请先登录');
            }else {
                this.show = true;
            }
        },
        onPay(res){
            let _this = this;

            if(res.id){
                this.show = false;
                this.getPayCode(res.id);
            }
        },
        getPayCode(addressid){
            let _this = this;
            this.$http.post({
                url:'/Order/getPayCode',
                data:{id:this.id,order_type:_this.data.type,address_id:addressid},
                success(res){
                    _this.qrcode = res.qrcode;
                    _this.order_id = res.order_id;
                    _this.contact_phone = res.phone;
                    _this.showPay = true;

                    if(_this.data.type !== 'industry') {
                      _this.timeDown('checkState');
                    }
                }
            })
        },
        getDetail(){
            let _this = this;
            this.$http.post({
                url:'/Report/detail',
                data:{id:this.id},
                success(res){
                    _this.data = res;
                }
            })
        },
        timeDown(type){
            if(type === 'checkState') {
                this.time = setInterval(this.checkState, 3000)
            }
        },
        closePay(){
            this.showPay = false;
            clearInterval(this.time);
            this.time = null;
            this.qrcode = '';
            this.order_id = '';
        },
        checkState(){
            let _this = this;
            this.$http.post({
                url:'/Common/checkOrder',
                data:{id:this.order_id},
                success(res){
                    if(res.state === 2){
                        clearInterval(_this.time);
                        _this.time = null;
                        // _this.info.bid_state = res.state;
                        _this.showPay = false;
                        _this.$message.success('支付成功');

                        _this.$router.push({path:'/user/buylog'})
                    }
                }
            })
        },
    }
}
</script>
