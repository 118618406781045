<template>
	<div v-if="show && info" :style="{width:info.width}">
		<el-carousel :interval="5000" arrow="always" v-if="list.length > 1" :height="info.height" style="width: 100%;" trigger="click">
			<el-carousel-item v-for="(item,index) in list">
				<img class="hand" :src="item.res_url.indexOf('http') >= 0 ? item.res_url : $config.assetsUrl + item.res_url" :style="{height:info.height,width:'100%'}"/>
			</el-carousel-item>
		</el-carousel>
		<div v-else :style="{width:'100%',height:info.height}">
			<img class="hand" :src="list[0].res_url.indexOf('http') >= 0 ? list[0].res_url : $config.assetsUrl + list[0].res_url" :style="{height:info.height,width:'100%'}" v-if="list[0]"/>
		</div>
	</div>
</template>

<script>
export default {
	name: "Banner",
	data() {
		return {
			list: [],
			show: true,
			width: '100%',
			height: '300px',
			info: null
		}
	},
	props: {
		akey: {
			type: String,
			default: ''
		}
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;
			this.$http.post({
				url: '/Banner/listAll',
				data: {key:this.akey},
				success(res) {
					console.log(res);
					_this.info = res.info;
					_this.list = res.list;
					// _this.list = res;
				}
			})
		},
		toOutUrl(out_url) {
			if (out_url) {
				let _url = out_url.indexOf('http') >= 0 ? out_url : 'http://' + out_url;

				window.open(_url, '_blank');
			}
		},
	}
}
</script>
