<template>
    <div class="year-tab rel hide border-radius-5" ref="layout" :class="$isMobile ? 'year-tab-mobile bg-gray-2' : 'bg-light box-shadow'">
        <div class="rel cl" style="z-index: 4;">
            <div :style="{width:(100 / list.length)+'%'}" class="z" v-for="(item,index) in list">
                <div class="text-center hand f20" :class="item.value === year ? 'tab-trans-on tab-round-bg light f-b' : 'dark-2 f-thin blue-hover'" @click="(e)=>chooseTab(e,item,index)">{{item.label}}</div>
            </div>
        </div>
        <!--        <span class="inline-block tab-trans-on abs" style="top:0;" :style="{width:(100 / list.length)+'%',transform:'translateX('+left+'px)'}">-->
        <!--            <span class="tab-round-bg"></span>-->
        <!--        </span>-->
    </div>
</template>

<script>
export default {
    name: "YearTab",
    data(){
        return{
            left:0,
            activeIndex:0,
            year:''
        }
    },
    props:{
        list:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    created() {
        let _now = new Date();

        // this.year = this.$route.query.year ? parseInt(this.$route.query.year) : this.list[0].value;
    },
    mounted() {
        // console.log(this.$refs.layout);
        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            _this.year = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.list.length > 0 ? _this.list[0].value : _now.getFullYear() - 1);
        })

    },
    methods:{
        chooseTab(e,item,index){
            // console.log(item);
            this.$router.replace({query:{...this.$route.query,year:item.value}});
            // console.log(item);
            // this.activeIndex = index;
            // this.left = e.currentTarget.offsetLeft;
            // this.$emit('change',{value:item.value,label:item.label,index:index});
        },
    },
    watch:{
        list(n,o){
            this.year = this.$route.query.year ? parseInt(this.$route.query.year) : n[0].value;
        }
    }
}
</script>
