<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" title="冷库计算器" :bread="[{title:'冷库计算器'}]">
		<div class="bg-img-2"  style="background-color:#f8f9fa">
			<div class="wrap1100 padding-top-100 padding-bottom-400">
				<div class="box-shadow bg-light padding-30">
<!--					{{form}}-->
					<el-form :model="form" ref="form" label-width="auto">
						<el-form-item label="地区" prop="province" :rules="[{required:true,message:'地区不能为空'}]">
<!--							<province-select v-model="form.province" @change="getDefaultValue"></province-select>-->
							<province-city-select v-model="form.province" style="width: 500px;" @change="onChangeProvince"></province-city-select>
						</el-form-item>
						<el-form-item label="常住人口" prop="czrk" :rules="[{required:true,message:'常住人口不能为空'},{pattern:$regexp.integer,message:'请填入正确的人口数量'}]">
							<el-input v-model="form.czrk" maxlength="20" show-word-limit style="width: 500px;">
								<template slot="append">人</template>
							</el-input>
						</el-form-item>
						<el-form-item label="餐饮收入" prop="cysr" :rules="[{required:true,message:'餐饮收入不能为空'},{pattern:$regexp.money,message:'请填入正确的金额'}]">
							<el-input v-model="form.cysr" maxlength="20" show-word-limit style="width: 500px;">
								<template slot="append">亿元</template>
							</el-input>
						</el-form-item>
						<el-form-item label="平均冷库租金" prop="lkzj" :rules="[{required:true,message:'平均冷库租金不能为空'},{pattern:$regexp.money,message:'请填入正确的金额'}]">
							<el-input v-model="form.lkzj" maxlength="20" show-word-limit style="width: 500px;">
								<template slot="append">元/托/天</template>
							</el-input>
						</el-form-item>
						<el-form-item label="人均生鲜食品消费量" prop="rjsxspxfl" :rules="[{required:true,message:'人均生鲜食品消费量不能为空'},{pattern:$regexp.money,message:'请填入正确的数量'}]">
							<el-input v-model="form.rjsxspxfl" maxlength="20" show-word-limit style="width: 500px;">
								<template slot="append">KG/人/天</template>
							</el-input>
						</el-form-item>
						<el-form-item label="生鲜食品消费量" prop="sxspxfl" :rules="[{required:true,message:'生鲜食品消费量不能为空'},{pattern:$regexp.money,message:'请填入正确的消费了'}]">
							<el-input v-model="form.sxspxfl" maxlength="20" show-word-limit style="width: 500px;">
								<template slot="append">万吨</template>
							</el-input>
						</el-form-item>
						<el-form-item label="恩格尔系数" prop="egexs" :rules="[{required:true,message:'恩格尔系数不能为空'},{pattern:$regexp.money,message:'请填入正确的百分比'}]">
							<el-input v-model="form.egexs" maxlength="20" show-word-limit style="width: 500px;">
								<template slot="append">%</template>
							</el-input>
						</el-form-item>
						<el-form-item>
							<div class="margin-top-20">
								<el-button type="success" class="box-shadow-green" @click="toSubmit">开始计算</el-button>
								<el-button type="info" @click="toReset">重置</el-button>
							</div>
						</el-form-item>
						<el-form-item v-if="info">
							<div style="background-color: #f0f9eb;border:1px solid #67c23a;padding:20px;font-size: 20px;" v-html="info">
								{{info}}
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			form:{
				province:'',
				province_code:'',
				city_code:'',
				cysr:'',
				lkzj:'',
				sxspxfl:'',
				rjsxspxfl:'',
				czrk:'',
				egexs:'',
			},
			info:null
		}
	},
	created() {
	},
	mounted() {
	},
	methods: {
		onChangeProvince(e){
			console.log(e);
			this.form.province_code = e.provinceCode;
			this.form.city_code = e.cityCode;
			this.form.cysr = '';
			this.form.lkzj = '';
			this.form.sxspxfl = '';
			this.form.rjsxspxfl = '';
			this.form.czrk = '';
			this.form.egexs = '';
			this.getDefaultValue();
		},
		getDefaultValue(){
			let _this = this;
			if(!this.form.province_code){
				return;
			}
			_this.$http.post({
				url: '/Calculator/listAll',
				data: {province_code:this.form.province_code},
				success: res => {
					console.log(res);
					for(let i=0;i < res.length;i++){
						let _content = JSON.parse(res[i].content_json);
						let _obj = _content.find(t => t.code === _this.form.city_code);
						_this.form[res[i].type] = _obj.value ? parseFloat(_obj.value) : '';
					}

					if(_this.form.czrk && _this.form.rjsxspxfl && !_this.form.sxspxfl){
						_this.form.sxspxfl = parseFloat((_this.form.czrk * _this.form.rjsxspxfl / 1000 / 10000 * 365).toFixed(2));
					}
				}
			});
		},
		toSubmit(){
			let _this = this;
			this.$refs.form.validate(valid => {
				if (valid) {
					let _data = JSON.parse(JSON.stringify(_this.form));

					_this.$http.post({
						url: '/Calculator/jisuan',
						data: _data,
						success: res => {
							console.log(res);
							_this.info = res;
						}
					});
				}
			})
		},
		toReset(){
			this.form.province = '';
			this.form.province_code = '';
			this.form.city_code = '';
			this.form.cysr = '';
			this.form.lkzj = '';
			this.form.sxspxfl = '';
			this.form.rjsxspxfl = '';
			this.form.czrk = '';
			this.form.egexs = '';
			this.info = null;

			setTimeout(()=>{
				this.$refs.form.clearValidate();
			},20)
		}
	}
}
</script>
