<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
<!--					{{data.inout.total}}-->
          据中物联冷链委统计，{{data.inout.total[data.inout.total.length - 1].year}}年全国进出口总额达{{data.inout.total[data.inout.total.length - 1].total}}亿元。

<!--            我国进出口生鲜农产品市场规模逐步扩大，尤其是从2017年起进口增长迅猛，<template v-for="(item,index) in data.inout.list">-->
<!--            {{item.year}}年进出口总额为{{item.total}}亿美金，{{item.rate >= 0? '增长' : '减少'}}{{item.rate}}%，-->
<!--        </template>-->
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData5",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {
        // this.startYear = this.year;
        // this.endYear = this.year - 4;
    },
    mounted() {
        this.getTotal1();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/HydataInout/getYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear, endYear){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataInout/getData',
                data:{time_type:'year'},
                success(res){
                    _this.data = res;

									let _bardata = {bar: [], xaxis: []};

									for (let i = 0; i < res.inout.total.length; i++) {
										_bardata.bar.push(res.inout.total[i].total);
										_bardata.xaxis.push(res.inout.total[i].time_str);
									}

									if (_this.$refs.bar1) {
										_this.$refs.bar1.initData(
											[
												{name: '进出口总额', type: 'bar', color: 'green', data: _bardata.bar, dw: '亿美元'},
											],
											_bardata.xaxis
										);
									}
                }
            })
        },
    }
}
</script>
