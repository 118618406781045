<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
            据中物联冷链委统计，{{data.count.list[data.count.list.length - 1].year}}年全国冷藏车市场保有量达到{{data.count.list[data.count.list.length - 1].total}}辆，同比{{data.count.list[data.count.list.length - 1].tongbi >= 0 ? '增长' : '减少'}}{{Math.abs(data.count.list[data.count.list.length - 1].tongbi)}}%，环比{{data.count.list[data.count.list.length - 1].huanbi >= 0 ? '增长' : '减少'}}{{Math.abs(data.count.list[data.count.list.length - 1].huanbi)}}%。
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData1",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {

    },
    mounted() {
        this.getTotal1();
    },
    methods:{
      // getYears() {
      //   let _this = this;
			//
      //   this.$http.post({
      //     url: '/HydataColdCar/getYears',
      //     data:null,
      //     success(res) {
      //       _this.endYear = res[res.length - 1];
			//
      //       _this.nowYear = res[0];
			//
      //       _this.getTotal1(_this.nowYear,_this.endYear);
      //     }
      //   });
      // },
        getTotal1(startYear, endYear){
            let _this = this;
            this.$http.post({
                url:'/HydataColdCar/getData',
                data:{time_type:'year'},
                success(res){
                    _this.data = res;

									let _bardata1 = {bar: [], line1: [],line2: [], xaxis: []};

									for (let i = 0; i < res.count.list.length; i++) {
										_bardata1.bar.push(res.count.list[i].total);
										_bardata1.line1.push(res.count.list[i].tongbi);
										_bardata1.line2.push(res.count.list[i].huanbi);
										_bardata1.xaxis.push(res.count.list[i].time_str);
									}

									if(_this.$refs.bar1) {
										_this.$refs.bar1.initData(
											[
												{name: '保有量', type: 'bar', color: 'green', data: _bardata1.bar,dw:'辆'},
												{name: '同比', type: 'line', color: 'purple', data: _bardata1.line1,dw:'%'},
												{name: '环比', type: 'line', color: 'red', data: _bardata1.line2,dw:'%'},
											],
											_bardata1.xaxis
										);
									}
                }
            })
        },
    }
}
</script>
