<template>
    <lee-page bg-img="/images/bg_head_1.jpg" title="奖项申报" :bread="[{title:'奖项申报'}]">
        <div class="bg-img-2"  style="background-color:#f8f9fa">
            <div class="wrap1100 padding-top-100 padding-bottom-400">
              <div class="cl"></div>
              <template v-if="list.length > 0">
								<el-row :gutter="20">
									<el-col :span="8" v-for="(item,index) in list">
										<div class="box-shadow bg-light margin-bottom-20 hand rel" @click="toInfo(item)" style="height: 350px;overflow: hidden;width: 100%;">
											<img :src="$utils.formatImagePath(item.head_img)" style="width: 100%;height: 250px;"/>
											<div class="hide">
												<div class="margin">
<!--													<div class="cl">-->
														<div class="green-line">
<!--															<img src="/images/icon/icon_30.png" height="14" class="margin-right"/>-->
															<div class="dark-0 vm f16 text-line-1">{{item.title}}</div>
														</div>
<!--													</div>-->
<!--													<div class="f14 dark-2 text-line-2" v-html="item.clean_des" v-if="item.clean_des"></div>-->
													<div class="margin-top f14 dark-3" v-if="item.start_time">发布时间：{{item.start_time}}</div>
												</div>
											</div>
											<template v-if="item.has_child === 0">
												<span v-if="item.state === 1" class="bg-green light f14" style="position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">进行中</span>
												<span v-if="item.state === 2" class="f14" style="background-color:#eee;color:#777;position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">已结束</span>
												<span v-if="item.state === 0" class="f14" style="background-color:#ffb700;color:#fff;position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">未开始</span>
											</template>
										</div>
									</el-col>
								</el-row>
                <div class="text-center margin-top-100">
                  <el-pagination
                    background
                    layout="pager"
                    :page-size="searchForm.pageSize"
                    :current-page.sync="searchForm.page"
                    :total="totalCount"
                    @current-change="pageChange">
                  </el-pagination>
                </div>
              </template>
              <el-empty description="暂无数据" v-else></el-empty>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
      return{
        list:[],
        searchForm:{
          page:1,
          pageSize:10,
          state:[[1,0,2],'in'],
          orderBy:'field(state,1,0,2)',
          sortBy:'asc'
        },
        totalCount:0
      }
    },
    created() {
        // this.id = this.formData.tid = this.$route.params.id ? this.$route.params.id : '';
    },
    mounted() {
        this.getList();
    },
    methods:{
			toInfo(item){
				let _this = this;
				this.$router.push({path:'/survey/info/'+item.id})
			},
      pageChange(e){
        this.searchForm.page = e;
        this.getList();
      },
      getList(){
        let _this = this;

        this.$http.post({
          url:'/SurveyTitle/listPage',
          data:this.searchForm,
          success(res){
            _this.list = res.list;
            _this.totalCount = res.totalCount;
          }
        })
      }
    }
}
</script>
