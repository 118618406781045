<template>
	<lee-page bg-img="/images/bg_head_1.jpg" :title="$isMobile ? '中国冷链委' : '链云数据平台'" :sub-title="$isMobile ? '数据平台' : 'Chain Cloud Data Platform'" :height="400">
		<!--					<div class="wrap1100 cl" style="margin-top:-55px;">-->
		<!--							<el-row :gutter="10">-->
		<!--									<el-col :span="6" v-for="(item,index) in tabList">-->
		<!--											<div class="block1 box-shadow" style="height: 111px;" @click="$router.push({path:item.path})">-->
		<!--													<div class="cl">-->
		<!--															<div class="z img margin-top-5">-->
		<!--																	<img :src="'/images/icon/'+item.img+'_off.png'" height="37" class="icon-off"/>-->
		<!--																	<img :src="'/images/icon/'+item.img+'_on.png'" height="37" class="icon-on"/>-->
		<!--															</div>-->
		<!--															<div class="z">-->
		<!--																	<div class="f20 title f-lq" style="line-height: 44px;">{{item.title}}</div>-->
		<!--&lt;!&ndash;                                    <div class="f14 f-thin sub-title">{{item.subTitle}}</div>&ndash;&gt;-->
		<!--															</div>-->
		<!--													</div>-->
		<!--											</div>-->
		<!--									</el-col>-->
		<!--							</el-row>-->
		<!--					</div>-->
		<div class="wrap1100 bg-light box-shadow padding-20" style="margin-top:-150px;">
			<lee-select v-model="type" style="width: 282px;" :options="searchTypeList"></lee-select>
			<lee-select v-model="searchForm.title_type" style="width: 177px;" class="margin-left" :options="[{label:'标题',value:'title'},{label:'关键字',value:'key'}]"></lee-select>
			<!--                <lee-input placeholder="输入标题" v-model="searchForm.title" class="margin-left" style="width: 177px"/>-->
			<lee-input placeholder="输入关键词" v-model="searchForm.title" style="width: 385px" class="margin-left"/>
			<el-button type="success" size="medium" class="vm lee-button margin-left f-lq" @click="toSearch" style="width: 186px;">
				<i class="el-icon-s-promotion"></i>查询
			</el-button>
		</div>
<!--		<standard></standard>-->
		<news></news>
		<div class="margin-top-50 bg-img-2" style="padding-bottom: 50px;">
			<div class="cl"></div>
			<div class="margin-top-50 text-center f38 dark-1 f-lq divider-green">最新行业数据</div>
			<div class="text-center f24 f-thin dark-3">Latest Data</div>
			<div class="margin-auto margin-top-20" style="width: 1114px;">
				<lee-index-hydata></lee-index-hydata>
			</div>
		</div>
		<div class="padding-bottom-400">
			<report></report>
		</div>
	</lee-page>
</template>

<script>
import Standard from './components/standard'
import Report from './components/report'
import News from './components/news'

export default {
	name: "Index",
	components: {News, Report, Standard},
	data() {
		return {
			type: 'zhengce',
			searchForm: {
				title_type: 'title',
				title: ''
			},
			nowYear: '',
			newsList: [],
			searchTypeList: [
				// {
				//     value: 'news',
				//     label: '新闻资讯'
				// },
				{
					value: 'zhengce',
					label: '政策标准'
				},
				{
					value: 'yanjiu',
					label: '研究报告'
				},
				// {
				//     value: 'Question',
				//     label: '问答中心'
				// }
			],
			tabList: [
				{
					img: 'icon_24',
					title: '政策标准',
					subTitle: '权威信息，精准解读',
					path: '/zhengce'
				},
				{
					img: 'icon_25',
					title: '行业数据',
					subTitle: '权威数据，精准解读',
					path: '/hydata'
				},
				{
					img: 'icon_26',
					title: '研究报告',
					subTitle: '权威报告，精准解读',
					path: '/yanjiu'
				},
				{
					img: 'icon_27',
					title: '问答中心',
					subTitle: '权威报告，精准解读',
					path: '/qa'
				}
			],
			standardTypeList: [],
			policyTypeList: [],
			qaList: [],
			yanjiuList: [],

			qaIndex: 0,
		}
	},
	created() {
		let _now = new Date();

		this.nowYear = _now.getFullYear();
	},
	mounted() {
		this.getPolicyType();
		this.getStandardType();
		this.getNews();
		this.getQaList();
		this.getReport('industry');
		// this.getReport('industry','agriculture');
		// this.getReport('domain', 'chain');
		// this.getReport('domain','agriculture');
	},
	methods: {
		toSearch() {
			console.log(this.searchForm);
			if (this.type === 'zhengce') {
				this.$router.push({path: '/zhengce/search', query: {...this.searchForm}})
			} else if (this.type === 'yanjiu') {
				this.$router.push({path: '/yanjiu/search', query: {...this.searchForm}})
			} else if (this.type === 'news') {
				this.$router.push({path: '/news', query: {...this.searchForm}})
			} else {
				this.$router.push({path: '/qa', query: {...this.searchForm}})
			}
		},
		getStandardType() {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {akey: 'standard', orderBy: 'sort', sortBy: 'asc', state: 1},
				success(res) {
					console.log(res);
					_this.standardTypeList = res;
				}
			});
		},
		getPolicyType() {
			let _this = this;
			this.$http.post({
				url: '/Type/listAll',
				data: {akey: 'policy', orderBy: 'sort', sortBy: 'asc', state: 1},
				success(res) {
					console.log(res);
					_this.policyTypeList = res;
				}
			});
		},
		getNews() {
			let _this = this;
			this.$http.post({
				url: '/News/listPage',
				data: {page: 1, pageSize: 4},
				success(res) {
					_this.newsList = res.list;
				}
			});
		},
		getQaList(orderBy = 'answer_count') {
			let _this = this;
			this.$http.post({
				url: '/Question/listPage',
				data: {page: 1, orderBy: orderBy, pageSize: 6, sortBy: 'desc'},
				success(res) {
					if (res.list) {
						_this.qaList = res.list;
					}
				}
			})
		},
		getReport(type) {
			let _this = this;
			this.$http.post({
				url: '/Report/listPage',
				data: {page: 1, pageSize: 2, type: type, orderBy: 'create_time', sortBy: 'desc'},
				success(res) {
					for (let i = 0; i < res.list.length; i++) {
						_this.yanjiuList.push(res.list[i]);
					}
				}
			})
		},
		changeQaTab(i) {
			this.qaIndex = i;

			let _type = 'answer_count';

			if (i === 0) {
				_type = 'answer_count';
			} else {
				_type = 'create_time';
			}
			this.getQaList(_type);
		}
	}
}
</script>
<style scoped>
.block1 {
	background: #fff;
	cursor: pointer;
	padding: 30px 0;
}

.block1:hover {
	background: #7ED321;
}

.block1 .icon-off {
	display: unset;
}

.block1 .icon-on {
	display: none;
}

.block1:hover .icon-off {
	display: none;
}

.block1:hover .icon-on {
	display: unset;
}

.block1:hover .title, .block1:hover .sub-title {
	color: #fff !important;
}

.block1 .img {
	width: 39px;
	height: 39px;
	margin: 0 30px;
}

.block1 .title {
	color: #6C6C6C;
}

.block1 .sub-title {
	color: #9F9F9F;
}

.ad-item {
}

.ad-item .img {
	height: 213px;
	width: 344px;
}

.ad-item .img img {
	height: 100%;
	width: 100%;
}

.ad-item .time {
	line-height: 30px;
}

.ad-item .title {
	line-height: 40px;
}

.ad-item .des {
	line-height: 24px;
}
</style>
