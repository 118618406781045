<template>
    <!--    <lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" :height="280" title="我的提问" :bread="['212','2121']">-->
    <lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" height="280" :back="false" title="我的提问" :bread="[{title:'问答中心',url:'/qa'},{title:'我的提问'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" style="background:#f8f9fa">
            <div class="cl"></div>
            <div class="wrap1100">
                <template v-if="list.length > 0">
                    <div class="bg-light border-radius-5 margin-bottom qa-list" v-for="(item,index) in list">
                        <div :class="index === activeIndex ? 'on' : ''" class="title cl">
                            <img src="/images/icon/icon_q.png" height="30" class="z img"/>
                            <div class="z title-in text-line-1" style="width: 800px;">问题标题：{{item.title}}</div>
                            <div class="y options">
<!--                                <lee-state-tag title="已付款" type="blue" v-if="item.is_pay === 1"></lee-state-tag>-->
<!--                                <lee-state-tag title="购买回答" type="red" class="hand" v-if="item.is_pay === 0 && item.is_answer === 1" @click.native="buyAnswer(item)"></lee-state-tag>-->
                                <!--                                <lee-state-tag title="已回答" type="green" v-if="item.status === 1 || item.status === 2"></lee-state-tag>-->
                              <span class="inline-block vm orange margin-right hand" @click="toDel(item.id)" v-if="item.uid === uid">
                                    <i class="el-icon-delete"></i>
                                    删除
                                </span>
                                <span class="hand green margin-left" @click="activeIndex = index">
                                        <i :class="index === activeIndex ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                        详情
                                    </span>
                            </div>
                        </div>
                      <div :class="index === activeIndex ? '' : 'none'">
                      <div class="padding-left-right-20 padding-top-bottom">问题描述:{{item.content}}</div>
                        <div class="bg-success-light padding-top-bottom padding-left-right-20">
                            <div v-if="item.answer_list.length > 0">
                                <div class="dark-2">回答列表：</div>
                                <div class="cl border-bottom-1 border-dark-6" v-for="(t,i) in item.answer_list">
                                    <div class="z padding-top-bottom" style="max-width: 800px" v-html="(i+1)+'. ' + (t.uid === uid ? '<b class=\'orange f-n margin-right\'>[你的回答]</b>' : '') + t.content+(t.bid_state === 0 && t.uid !== uid ? '......<b class=\'dark-3 f-n\'>[需购买]</b>' : '')"></div>
                                    <div class="y">
                                        <el-button class="gablue" type="text" icon="el-icon-goods" @click="toBuyAnswer(t,item)" v-if="t.uid !== uid && t.bid_state === 0">购买</el-button>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center" v-else>暂无回答</div>
                        </div>
                      </div>
<!--                        <div class="padding-20" :class="index === activeIndex ? '' : 'none'" v-if="item.answer && item.is_pay === 1">{{item.answer}}</div>-->
<!--                        <div class="padding-20 green text-center" :class="index === activeIndex ? '' : 'none'" v-else-if="item.answer && item.is_pay === 0">已有回答，请先购买</div>-->
<!--                        <div class="padding-20 dark-2 text-center" :class="index === activeIndex ? '' : 'none'" v-else>暂无回答</div>-->
                    </div>
                    <div class="text-center margin-top-100">
                        <el-pagination
                            background
                            layout="pager"
                            :page-size="pageSize"
                            :current-page.sync="page"
                            :total="total"
                            @current-change="pageChange">
                        </el-pagination>
                    </div>
                </template>
                <div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
                    暂无数据
                </div>
            </div>
        </div>
        <el-dialog
          title="支付"
          :visible.sync="show" @close="closePay">
          <lee-pay-view :name="buyData.title" :price="buyData.price" title="购买答案" sub-title="答案金额" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="buyData"></lee-pay-view>
          <!--                <lee-pay-view :price="buyData.price.amount" :name="buyData.title" title="购买答案" sub-title="答案金额" file-size="" @submit="paySubmit"></lee-pay-view>-->
        </el-dialog>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            activeIndex:0,
            show:false,
            list:[],
            page:1,
            pageSize:15,
            total:0,

            buyData:null,
            qrcode:'',
            time:null,
            order_id:'',
            uid:this.$user.getUid()
        }
    },
    created() {
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
        // buyAnswer(item){
        //     // this.show = true;
        //     this.buyData = item;
        //
        //     this.getPayCode();
        //
        //     console.log(item);
        //     event.preventDefault();
        // },
      toDel(id){
        let _this = this;
        this.$confirm('是否删除此问题?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post({
            url:'/Question/del',
            data:{id:id},
            success(res){
              _this.$message.success('删除成功');
              _this.getList();
            }
          })
        })
      },
        closePay(){
            this.show = false;
            clearInterval(this.time);
            this.time = null;
            this.qrcode = '';
            this.order_id = '';
        },
        toBuyAnswer(e,e2){
            // this.show = true;
            this.buyData = e;

            this.buyData.price = e2.price;

            this.getPayCode();
        },
        getPayCode(){
            let _this = this;
            this.$http.post({
                url:'/Order/getPayCode',
                data:{id:this.buyData.id,order_type:'answer'},
                success(res){
                    _this.qrcode = res.qrcode;
                    _this.order_id = res.order_id;
                    _this.show = true;
                    _this.timeDown('checkState');
                }
            })
        },
        timeDown(type){
            if(type === 'checkState') {
                this.time = setInterval(this.checkState, 3000)
            }
        },
        checkState(){
            let _this = this;
            this.$http.post({
                url:'/Common/checkOrder',
                data:{id:this.order_id},
                success(res){
                    if(res.state === 2){
                        clearInterval(_this.time);
                        _this.time = null;
                        _this.show = false;
                        _this.$message.success('支付成功');

                        _this.getList();
                    }
                }
            })
        },
        getList(){
            let _this = this;

            this.$http.post({
                url: '/User/getMyQuestion',
                data: {page:this.page,pageSize:this.pageSize},
                success(res) {
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.$router.replace({query:{page:this.page}});
        },
    }
}
</script>
