<template>
    <lee-page :bg-img="$isMobile ? '/images/bg_head_9.jpg' : '/images/bg_head_3.jpg'" :title="$isMobile ? '' : data.title" des="" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'细分领域报告',url:'/yanjiu/list/domain'},{title:data.title}]" :back="$isMobile" :height="$isMobile ? '120' : '280'" v-if="data">
        <div :class="$isMobile ? 'padding-bottom-100 padding-left-right bg-light' : 'bg-img-2 padding-bottom-400 padding-top-50'" style="background:#f8f9fa">
            <div class="cl"></div>
            <div :class="$isMobile ? 'border-radius-5 bg-light' : 'wrap1100'" :style="{marginTop:$isMobile ? '-40px' : ''}" v-if="data">
                <div class="cl"></div>
                <div class="box-shadow" :class="$isMobile ? 'hide border-radius-5 padding' : 'bg-light margin-top-30 padding-30'">
                    <div class="green-line">
                        <div class="cl" :class="$isMobile ? 'f20' : 'f30'">
                            <img src="/images/icon/icon_36.png" height="22" class="no-v-mid" v-if="!$isMobile"/>
                            {{data.title}}
                        </div>
                    </div>
                  <template v-if="data.check.type === 'ok'">
                    <div class="f16 dark-1" v-html="data.html_content ? data.html_content : ''"></div>
                    <div class="margin-top-50" v-if="data.files.length > 0">
                      <lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="domain" :tid="data.id"></lee-vip-file-down>
                    </div>
                  </template>
                  <vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买报告" pay-sub-title="报告金额" type="domain" v-else></vip-check-button>
                  <lee-good-view class="margin-top-50" :id="data.id" akey="domain"></lee-good-view>
                </div>
                <div class="box-shadow padding-30 bg-light margin-top-30" v-if="!$isMobile">
                    <lee-comment-view total-count="30" akey="domain" :id="data.id"></lee-comment-view>
                </div>
            </div>
            <div v-else>
                <div class="text-center padding-top-bottom-40" v-if="httpCode === 401">
                    {{message}}
                </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            id:'',
            data:null,
            httpCode:200,
            message:'',
        }
    },
    created() {
        this.id = this.$route.params.id;
    },
    mounted() {
        if(this.id){
            this.getDetail();
        }
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.post({
                url:'/Report/detail',
                data:{id:this.id},
                success(res){
                    _this.data = res;

                    // if(res.type === 'domain' && res.bid_state > 1){
                    //     _this.timeDown('checkState');
                    // }
                }
            })
        }
    }
}
</script>
