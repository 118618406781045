<template>
    <lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="农批市场相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'农批市场'}]">
        <div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
            <check-vip akey="hangyedata_agriculture">
                <div class="wrap1100">
                    <lee-year-tab :list="yearList"></lee-year-tab>
                    <div class="box-shadow padding-30 bg-light margin-top-30">
                        <div class="green-line">
                            <div class="cl f34">
                                <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                                <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">农批市场相关数据</div>
                            </div>
                            <!--                        <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">-->
                            <!--                            <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>-->
                            <!--                            <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>-->
                            <!--                            <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>-->
                            <!--                        </el-row>-->
                        </div>
                        <div class="f16 dark-2">
<!--                            <p v-if="data && data.count.length > 0">根据{{nowYear}}中国商品交易市场统计年鉴的数据显示，我国目前农产品市场主要分为以下2类：农产品综合市场及农产品专业市场。其中农产品专业市场还可细分为粮油市场、肉禽蛋市场、水产品市场、蔬菜市场、干鲜果品市场、棉麻土畜烟叶市场、其他农产品市场；其中{{data.count[0].title}}市场数量、总摊位数、年末出租摊由数、营业面、成交额均位列第一，分别为：{{data.count[0].market_count}}个、{{data.count[0].stall_count}}个、{{data.count[0].end_stall_count}}个、{{data.count[0].area}}平方米、{{data.count[0].amount}}万元。</p>-->
                            <table class="lee-table margin-top-30 margin-auto" style="width: 900px;"  :class="$isMobile ? 'mobile' : ''" v-if="data && data.count.length > 0">
                                <thead>
                                <td>市场类型</td>
                                <td>市场数量(个)</td>
                                <td>总摊位数(个)</td>
                                <td>年末出租摊位数(个)</td>
                                <td>营业面积(平方米)</td>
                                <td>成交额(万元)</td>
                                </thead>
                                <tr v-for="(item,index) in data.count">
                                    <td>{{item.title}}</td>
                                    <td>{{item.market_count ? item.market_count : '0'}}</td>
                                    <td>{{item.stall_count ? item.stall_count : '0'}}</td>
                                    <td>{{item.end_stall_count ? item.end_stall_count : '0'}}</td>
                                    <td>{{item.area ? item.area : '0'}}</td>
                                    <td>{{item.amount ? item.amount : '0'}}</td>
                                </tr>
                            </table>
                            <div class="green margin-top-50" :class="$isMobile ? 'f20' : 'f26'">各区域农产品市场数量</div>
                            <p v-if="data && data.area.length > 0">{{nowYear}}年，中国主要区域农产品市场数量情况为：<template v-for="(item,index) in data.area">{{item.name}}地区{{item.total}}个{{index < (data.length - 1) ? '，' : '。'}}</template>如图1-10所示。</p>
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar1"
                                    :show-right="false"
                                    :show-title="true"
                                    title-pos="bottom"
                                    :title-size="14"
                                    :title="nowYear+'年中国主要地区农产品市场数量'"
                                    :grid-bottom="30"
                                    height="350px"
                                    :show-legend="false"></lee-bar-echarts>
                            </div>
                            <p v-if="data && data.area.length > 0">{{data.area[0].name}}前三是{{data.area[0].list[0].name}}{{data.area[0].list[0].total}}个、{{data.area[0].list[1].name}}{{data.area[0].list[1].total}}个、{{data.area[0].list[2].name}}{{data.area[0].list[2].total}}个。</p>
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar2"
                                    :show-title="true"
                                    title-pos="bottom"
                                    :title-size="14"
                                    :title="nowYear+'年中国'+ (data && data.area.length > 0 ? data.area[0].name : '') + '地区农产品市场数量'"
                                    :grid-bottom="30"
                                    height="350px"
                                    :show-legend="false"
                                    :show-right="false"></lee-bar-echarts>
                            </div>
                            <p v-if="data && data.area.length > 0">{{data.area[1].name}}前三是{{data.area[1].list[0].name}}{{data.area[1].list[0].total}}个、{{data.area[1].list[1].name}}{{data.area[1].list[1].total}}个、{{data.area[1].list[2].name}}{{data.area[1].list[2].total}}个。</p>
<!--                            <p v-if="data && data.area.length > 0">根据{{nowYear}}中国商品交易市场统计年鉴的数据显示，{{nowYear}}年，中国{{data.area[1].name}}地区农产品市场数量为{{data.area[1].total}}个，其中<template v-for="(item,index) in data.area[1].list">{{item.name}}{{item.total}}个，</template>如图1-12所示。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar3"
                                    :show-title="true"
                                    title-pos="bottom"
                                    :title-size="14"
                                    :title="nowYear+'年中国'+ (data && data.area.length > 0 ? data.area[1].name : '') + '地区农产品市场数量'"
                                    :grid-bottom="30"
                                    height="350px"
                                    :show-legend="false"
                                    :show-right="false"
                                    :bar-width="50"></lee-bar-echarts>
                            </div>
                            <p v-if="data && data.area.length > 0">{{data.area[2].name}}前三是{{data.area[2].list[0].name}}{{data.area[2].list[0].total}}个、{{data.area[2].list[1].name}}{{data.area[2].list[1].total}}个、{{data.area[2].list[2].name}}{{data.area[2].list[2].total}}个。</p>
<!--                            <p v-if="data && data.area.length > 0">根据{{nowYear}}中国商品交易市场统计年鉴的数据显示，{{nowYear}}年，中国{{data.area[2].name}}地区农产品市场数量为{{data.area[2].total}}个，其中<template v-for="(item,index) in data.area[2].list">{{item.name}}{{item.total}}个，</template>如图1-13所示。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar4"
                                    :show-title="true"
                                    title-pos="bottom"
                                    :title-size="14"
                                    :title="nowYear+'年中国'+ (data && data.area.length > 0 ? data.area[2].name : '') + '地区农产品市场数量'"
                                    :grid-bottom="30"
                                    height="350px"
                                    :show-legend="false"
                                    :show-right="false"></lee-bar-echarts>
                            </div>
                            <p v-if="data && data.area.length > 0">{{data.area[3].name}}前三是{{data.area[3].list[0].name}}{{data.area[3].list[0].total}}个、{{data.area[3].list[1].name}}{{data.area[3].list[1].total}}个、{{data.area[3].list[2].name}}{{data.area[3].list[2].total}}个。</p>
<!--                            <p v-if="data && data.area.length > 0">根据{{nowYear}}中国商品交易市场统计年鉴的数据显示，{{nowYear}}年，中国{{data.area[3].name}}地区农产品市场数量为{{data.area[3].total}}个，其中<template v-for="(item,index) in data.area[3].list">{{item.name}}{{item.total}}个，</template>如图1-14所示。</p>-->
                            <div class="text-center">
                                <lee-bar-echarts
                                    ref="bar5"
                                    :show-title="true"
                                    title-pos="bottom"
                                    :title-size="14"
                                    :title="nowYear+'年中国'+ (data && data.area.length > 0 ? data.area[3].name : '') + '部地区农产品市场数量'"
                                    :grid-bottom="30"
                                    height="350px"
                                    :show-legend="false"
                                    :show-right="false"></lee-bar-echarts>
                            </div>
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
        }
    },
    created() {
        let _this = this;

        let _now = new Date();

        this.$nextTick(()=>{
            // _this.nowYear = _this.$route.query.year ? parseInt(_this.$route.query.year) : (_this.yearList.length > 0 ? _this.yearList[0].value : _now.getFullYear() - 1);
        })

        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
        // this.nowYear = this.$route.query.year ? this.$route.query.year : this.yearList[0].value;
    },
    mounted() {
        this.getYears();
    },
    methods:{
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataNongpi/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.endYear = res[res.length - 1];

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataNongpi/getData',
                data:{year:year,yearlist:[year,year - 4]},
                success(res){
                    _this.data = res;

                    let _bardata1 = {bar:[],xaxis:[]};
                    let _bardata2 = {bar:[],xaxis:[]};
                    let _bardata3 = {bar:[],xaxis:[]};
                    let _bardata4 = {bar:[],xaxis:[]};
                    let _bardata5 = {bar:[],xaxis:[]};

                    if(res.area.length > 0) {
                        for (let i = 0; i < res.area.length; i++) {
                            _bardata1.bar.push(res.area[i].total);
                            _bardata1.xaxis.push(res.area[i].name ? res.area[i].name + '地区' : '');
                        }

                        if (_this.$refs.bar1) {
                            _this.$refs.bar1.initData(
                                [
                                    {name: '市场数量', type: 'bar', color: 'most', data: _bardata1.bar,dw:'个'}
                                ],
                                _bardata1.xaxis
                            );
                        }

                        let _list1 = res.area[0].list;

                        for (let i = 0; i < _list1.length; i++) {
                            _bardata2.bar.push(_list1[i].total);
                            _bardata2.xaxis.push(_list1[i].name ? _list1[i].name : '');
                        }

                        if (_this.$refs.bar2) {
                            _this.$refs.bar2.initData(
                                [
                                    {name: '市场数量', type: 'bar', color: 'green', data: _bardata2.bar,dw:'个'}
                                ],
                                _bardata2.xaxis
                            );
                        }

                        let _list2 = res.area[1].list;

                        for (let i = 0; i < _list2.length; i++) {
                            _bardata3.bar.push(_list2[i].total);
                            _bardata3.xaxis.push(_list2[i].name ? _list2[i].name : '');
                        }

                        if (_this.$refs.bar3) {
                            _this.$refs.bar3.initData(
                                [
                                    {name: '市场数量', type: 'bar', color: 'green', data: _bardata3.bar,dw:'个'}
                                ],
                                _bardata3.xaxis
                            );
                        }

                        let _list3 = res.area[2].list;

                        for (let i = 0; i < _list3.length; i++) {
                            _bardata4.bar.push(_list3[i].total);
                            _bardata4.xaxis.push(_list3[i].name ? _list3[i].name : '');
                        }

                        if (_this.$refs.bar4) {
                            _this.$refs.bar4.initData(
                                [
                                    {name: '市场数量', type: 'bar', color: 'green', data: _bardata4.bar,dw:'个'}
                                ],
                                _bardata4.xaxis
                            );
                        }

                        let _list4 = res.area[3].list;

                        for (let i = 0; i < _list4.length; i++) {
                            _bardata5.bar.push(_list4[i].total);
                            _bardata5.xaxis.push(_list4[i].name ? _list4[i].name : '');
                        }

                        if (_this.$refs.bar5) {
                            _this.$refs.bar5.initData(
                                [
                                    {name: '市场数量', type: 'bar', color: 'green', data: _bardata5.bar,dw:'个'}
                                ],
                                _bardata5.xaxis
                            );
                        }
                    }
                }
            })
        },

    }
}
</script>
