<template>
    <div class="sort-tag text-center f20 bg-gray-2 green inline-block" v-if="isnew">
        NEW
    </div>
    <div class="sort-tag text-center f20 inline-block" :class="{'bg-red light':number > 0,'bg-green light':number < 0,'bg-gray-2':number === 0}" :style="number === 0 ? 'padding:12px 25px 0 25px' : ''" v-else>
        <div v-if="number === 0" style="height: 5px;border-radius:3px;" class="bg-dark-3"></div>
        <template v-else>
            <img :src="'/images/icon/icon_sort_'+(number > 0 ? 'up' : 'down')+'.png'" height="12" class="margin-right"/>{{Math.abs(number)}}
        </template>
    </div>
</template>

<script>
export default {
    name: "UpDownTab",
    props:{
        type:{
            type:String,
            default:'up'
        },
        number:{
            type:[String,Number],
            default:10
        },
        isnew:{
            //type === 'up' ? 'bg-red' : (number === 0 ? 'bg-gray-2' : 'bg-green')
            type:Boolean,
            default:false
        }
    }
}
</script>
