<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :type="$isMobile ? 'dark' : 'light'" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '50' : '280'" :back="$isMobile" :title="$isMobile ? '' : year+'中国冷链物流百家重点企业分析报告'" :bread="[{title:'重点企业',url:'/company'},{title:year+'中国冷链物流百家重点企业分析报告'}]">
        <div :class="$isMobile ? 'padding-left-right bg-light' : 'bg-img-2 padding-bottom-400 padding-top-100'" :style="{backgroundColor:$isMobile ? '' : '#f8f9fa'}">
            <check-vip akey="baiqiang_report">
                <div :class="$isMobile ? '' : 'wrap1100'">
                    <div :class="$isMobile ? '' : 'box-shadow padding-30 bg-light'">
                        <div class="cl f34">
                            <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                            <div class="z" :class="$isMobile ? 'f18 text-center dark-2 block' : 'f22'">{{year}}中国冷链物流百家重点企业分析报告</div>
                        </div>
                        <div :class="$isMobile ? 'margin-top' : 'margin-top-40'">
                            <lee-hash-tab id="a1" :list="tabList"></lee-hash-tab>
                            <p class="f16 dark-2" v-if="data">在{{year}}年中国冷链物流百家重点企业中，<template v-for="(item,index) in data.nature">{{item.name}}企业有{{item.company_count}}家，</template>。民营企业仍是冷链物流百家重点企业的主要组成部分，体现出了整体环境的市场竞争性，同时也在一定程度上反映出了冷链物流行业的开放性。。</p>
                            <el-row :gutter="$isMobile ? 10 : 50" v-if="data">
                                <el-col :span="8" v-for="(item,index) in data.nature" :class="$isMobile ? 'margin-bottom' : 'margin-bottom-30'">
                                    <div class="rel margin-auto" :style="$isMobile ? 'height:80px;' : 'width: 205px;height: 122px;overflow: hidden;'">
                                        <img :src="$config.assetsUrl+item.head_img" :width="$isMobile ? '100%' : '205'" :height="$isMobile ? '80' : '122'" class="abs"/>
                                        <div :class="$isMobile ? 'f20 padding-top' : 'f30 margin-top-20'" class="rel light text-center" :style="{zIndex: 2, lineHeight:$isMobile ? '30px' : '40px'}">
                                            {{item.name}}<br/>
                                            {{item.company_count}}家
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
<!--                            <p class="margin-top-30 f16 dark-2">-->
<!--                                新晋与跌出的冷链物流百强企业分布于各个名次阶段，但主要集中于1-10名和61-100名，百强波动较大的原因主要有：<br/>-->
<!--                                （1）冷链行业竞争激烈，仍处在小组赛阶段，企业更多的是博弈与竞争，未形成高效协同整合的新局面；<br/>-->
<!--                                （2）部分企业客户稳定性较弱、黏性较低，业务单一、赋能性差的企业逐渐被市场淘汰；<br/>-->
<!--                                （3）随着进出口食品贸易发展提速，跨境冷链物流需求快速增长，部分企业跨境冷链业务发展迅猛；<br/>-->
<!--                                （4）随着客户对冷链物流服务要求的越来越高，部分企业开始通过科技赋能，升级优化冷链相关系统，提升企业信息化互联水平，逐步建立企业技术核心竞争力；<br/>-->
<!--                                （5）部分企业未能按时提交财务报表及审计报告。-->
<!--                            </p>-->
                            <lee-hash-tab id="a2" class="margin-top-50" :active-index="1" :list="tabList"></lee-hash-tab>
                            <div class="margin-top">
                                <div class="cl">
                                    <div class="z" style="width: 940px;">
                                        <el-row :gutter="10">
                                            <el-col :span="8" class="cl margin-bottom-20" v-for="(item,index) in BaiqiangChoose">
                                                <span class="z line-index bg-green light f16" v-if="item.arank > 3">{{item.arank}}</span>
                                                <img :src="'/images/icon/jiangbei_' + item.arank+'.png'" width="30" height="30" class="z" v-else/>
                                                <div class="z margin-left 20 text-line-1 f-thin" style="width: 250px;line-height: 30px;">{{item.title}}</div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div class="y text-right" style="line-height: 30px;">
                                        <el-button type="success" size="small" @click="showBaiqiang">选择企业<i class="el-icon-caret-bottom el-icon--right"></i></el-button>
                                        <!--                                    选择企业<i class="el-icon-caret-bottom"></i>-->
                                    </div>
                                </div>
                                <div>
                                    <lee-bar-echarts
                                        ref="baiqiang"
                                        :height="$isMobile ? '200px' : '350px'"
                                        show-legend>
                                    </lee-bar-echarts>
                                </div>
                            </div>
                            <lee-hash-tab id="a3" class="margin-top-50" :list="tabList" :active-index="2"></lee-hash-tab>
<!--                            <p class="f16 dark-2" v-if="data">近5年来冷链业务发展迅速，百强营收规模不断扩大，年复合增长率达29.96%。{{year}}年百强企业冷链业务营业收入合计达{{data.total.now.amount}}亿元，同比增长{{data.total.now.amount_rate}}%；占{{year}}年冷链物流市场规模的16.21%，百强市场占有率相较于2016年的9.22%、2017的10.19%、2018年的13.79%逐年增长，冷链市场集中度不断提高。</p>-->
                            <div class="text-center margin-top-40">
                                <el-row :gutter="10">
                                    <el-col :md="24" :xs="24">
                                        <lee-bar-echarts
                                            ref="bar1"
                                            :height="$isMobile ? '200px' : '350px'"
                                            show-legend>
                                        </lee-bar-echarts>
                                    </el-col>
<!--                                    <el-col :md="10" :xs="24">-->
<!--                                        <template v-if="data && data.lenglian">-->
<!--                                            <div class="bg-light box-shadow margin-bottom-20" v-for="(item,index) in data.lenglian">-->
<!--                                                <div class="f18 text-left light padding" :class="headColor[index % 3]">{{item.year}}</div>-->
<!--                                                <template v-for="(item2,index2) in data.total.list">-->
<!--                                                    <div class="padding f-thin text-left" v-if="item2.year === item.year">-->
<!--&lt;!&ndash;                                                        冷链物流市场规模 <span class="f-b">{{item.wlsc_amount}}亿元</span><br/>&ndash;&gt;-->
<!--                                                        重点企业市场占有率 <span class="f-b">{{ parseFloat(item.wlsc_amount) > 0 ? ((parseFloat(item2.amount) / parseFloat(item.wlsc_amount)) * 100).toFixed(2) : '0.00'}}%</span>-->
<!--                                                        &lt;!&ndash;                                                    <span class="f-b" v-else>0%</span>&ndash;&gt;-->
<!--                                                    </div>-->
<!--                                                </template>-->
<!--                                            </div>-->
<!--                                        </template>-->
<!--                                    </el-col>-->
                                </el-row>
                            </div>
<!--                            <p class="f16 dark-2" v-if="data">{{year}}年冷链物流百强企业入围门槛营收为{{data.total.now.menkan}}万元，较上年{{parseFloat(data.total.now.menkan) >= parseFloat(data.total.last.menkan) ? '提高' : '减少'}}{{ Math.abs(parseFloat(data.total.now.menkan) - parseFloat(data.total.last.menkan))}}万元，{{data.total.now.menkan_rate >= 0 ? '增长' : '减少'}}{{Math.abs(data.total.now.menkan_rate)}}%。除2016年受经济形势影响入围门槛营收有所回落外，其余年份冷链物流百强企业入围门槛保持逐年上升，表明冷链物流企业竞争越来越激烈，同时整体冷链物流企业不断发展壮大，企业营收水平逐年提升。</p>-->
                            <div class="margin-top-40">
                              <lee-bar-echarts
                                  ref="bar2"
                                  :height="$isMobile ? '200px' : '350px'"
                                  show-legend
                                  :show-left="false"
                                  :show-right="false">
                              </lee-bar-echarts>
                            </div>
                            <lee-hash-tab id="a4" class="margin-top-50" :list="tabList" :active-index="3"></lee-hash-tab>
                            <table class="lee-table margin-top-30 margin-auto" style="width:900px;" :class="$isMobile ? 'mobile' : ''" v-if="data">
                                <thead>
                                <td>区域</td>
                                <td v-for="(item,index) in data.area.count">{{item.title}}区域</td>
                                </thead>
                                <tr>
                                    <td>入围企业数</td>
                                    <td v-for="(item,index) in data.area.count">{{item.count}}家</td>
                                </tr>
                            </table>
                            <div class="text-center margin-top-40">
                                <el-row>
                                    <el-col :md="14" :xs="24">
                                        <lee-bar-echarts
                                            ref="bar3"
                                            :grid-bottom="$isMobile ? 50 : 30"
                                            :height="$isMobile ? '250px' : '350px'"
                                            show-legend
                                            :show-left="false"
                                            :show-right="false"
                                        ></lee-bar-echarts>
                                    </el-col>
                                    <el-col :md="10" :xs="24">
                                        <lee-pie-echarts
                                            ref="pie1"
                                            :grid-bottom="$isMobile ? 100 : 30"
                                            :height="$isMobile ? '300px' : '350px'"
                                            show-legend
                                            label-pos="outside"
                                            :radius="['30%','50%']"></lee-pie-echarts>
                                    </el-col>
                                </el-row>
                            </div>
<!--                            <p class="f16 dark-2" v-if="data">-->
<!--                                按区域入围企业数量排序：<template v-for="(item,index) in data.area.count">{{item.title}} <template v-if="index + 1 < data.area.count.length">＞</template> </template> ，{{data.area.count[0].title}}区域入围企业数量最多，是冷链物流最为集中的区域。<br/>-->
<!--                                按区域百强企业营收排序：<template v-for="(item,index) in data.area.amount">{{item.title}} <template v-if="index + 1 < data.area.amount.length">＞</template> </template>，{{data.area.total.title}}入围企业总营收最高，占百强总营收的比例为{{ data.area.total.zhanbi}}%，{{year - 1}}年这一数据为{{data.area.pre_total.zhanbi}}%，华东区域性优势进一步显现；东北区域排名较上年进步一位，区域整体行业营收能力提升较为显著；<br/>-->
<!--                                按区域百强营收均值排序：<template v-for="(item,index) in data.area.pinjun">{{item.title}} <template v-if="index + 1 < data.area.pinjun.length">＞</template> </template>，{{data.area.pinjun[0].title}}入围企业平均营收最高；华北区域排名较上年靠前一位，企业营收水平进一步提升。-->
<!--                            </p>-->
                            <div class="margin-top-30">
                                <el-row :gutter="10">
                                    <el-col :span="16">
                                        <div>
                                            <lee-china-echarts :height="$isMobile ? '400px' : '600px'" @click="mapClick" @onload="mapOnload"></lee-china-echarts>
                                        </div>
                                    </el-col>
                                    <el-col :span="8">
                                        <div class="padding border-radius-5 border-w-1 border-dark-3" style="margin-top:50px;">
                                            <div class="f16 green padding-bottom">{{province_name}}{{companyList.length}}家</div>
                                            <div class="f14" style="line-height: 30px;" v-for="(item,index) in companyList">{{item.title}}</div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>
                            <lee-hash-tab id="a5" class="margin-top-50" :list="tabList" :active-index="4"></lee-hash-tab>
                            <div class="text-center margin-top-40">
                                <el-row>
                                    <el-col :md="24" :xs="24">
                                        <lee-bar-echarts
                                            ref="bar4"
                                            :grid-bottom="$isMobile ? 50 : 30"
                                            :height="$isMobile ? '250px' : '350px'"
                                            show-legend
                                            :show-left="false"
                                            :show-right="false"
                                        ></lee-bar-echarts>
                                    </el-col>
<!--                                    <el-col :md="8" :xs="24">-->
<!--                                        <lee-pie-echarts-->
<!--                                            ref="pie2"-->
<!--                                            :grid-bottom="$isMobile ? 100 : 30"-->
<!--                                            :height="$isMobile ? '300px' : '350px'"-->
<!--                                            show-legend-->
<!--                                            :radius="[0,'50%']"></lee-pie-echarts>-->
<!--                                    </el-col>-->
                                </el-row>
                            </div>
                            <el-row :gutter="10" class="margin-top-40" v-if="data">
                                <el-col :span="4">
                                    <div class="bg-light box-shadow padding cl">
                                        <div class="z">
                                            <div class="f-thin">自有车辆</div>
                                            <div class="f-thin">占比{{ parseFloat(data.car.now.owner_car) + parseFloat(data.car.now.out_car) > 0 ? (parseFloat(data.car.now.owner_car) / (parseFloat(data.car.now.owner_car) + parseFloat(data.car.now.out_car)) * 100).toFixed(2) : '0.00'}}%</div>
                                        </div>
                                        <div class="y">
                                            <lee-img-icon size="40" url="/images/icon/car/car_1.png" color="#7ED321"></lee-img-icon>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="bg-light box-shadow padding cl">
                                        <div class="z">
                                            <div class="f-thin">外协车辆</div>
                                            <div class="f-thin">占比{{ parseFloat(data.car.now.owner_car) + parseFloat(data.car.now.out_car) > 0 ? (parseFloat(data.car.now.out_car) / (parseFloat(data.car.now.owner_car) + parseFloat(data.car.now.out_car)) * 100).toFixed(2) : '0.00'}}%</div>
                                        </div>
                                        <div class="y">
                                            <lee-img-icon size="40" url="/images/icon/car/car_2.png" color="#7ED321"></lee-img-icon>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="bg-light box-shadow padding cl">
                                        <div class="z">
                                            <div class="f-thin">微型车辆</div>
                                          <div class="f-thin">占比{{ parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car) > 0 ? parseFloat(parseInt(data.car.now.mini_car) / (parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car)) * 100).toFixed(2) : '0.00'}}%</div>
<!--                                            <div class="f-thin">共{{data.car.now.mini_car}}辆</div>-->
                                        </div>
                                        <div class="y">
                                            <lee-img-icon size="40" url="/images/icon/car/car_3.png" color="#7ED321"></lee-img-icon>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="bg-light box-shadow padding cl">
                                        <div class="z">
                                            <div class="f-thin">轻型车辆</div>
                                            <div class="f-thin">占比{{ parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car) > 0 ? parseFloat(parseInt(data.car.now.light_car) / (parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car)) * 100).toFixed(2) : '0.00'}}%</div>
                                        </div>
                                        <div class="y">
                                            <lee-img-icon size="40" url="/images/icon/car/car_4.png" color="#7ED321"></lee-img-icon>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="bg-light box-shadow padding cl">
                                        <div class="z">
                                            <div class="f-thin">中型车辆</div>
                                            <div class="f-thin">占比{{ parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car) > 0 ? parseFloat(parseInt(data.car.now.small_car) / (parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car)) * 100).toFixed(2) : '0.00'}}%</div>
                                        </div>
                                        <div class="y">
                                            <lee-img-icon size="40" url="/images/icon/car/car_5.png" color="#7ED321"></lee-img-icon>
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="4">
                                    <div class="bg-light box-shadow padding cl">
                                        <div class="z">
                                            <div class="f-thin">重型车辆</div>
                                            <div class="f-thin">占比{{ parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car) > 0 ? parseFloat(parseInt(data.car.now.heavy_car) / (parseInt(data.car.now.mini_car) + parseInt(data.car.now.light_car) + parseInt(data.car.now.small_car) + parseInt(data.car.now.heavy_car)) * 100).toFixed(2) : '0.00'}}%</div>
                                        </div>
                                        <div class="y">
                                            <lee-img-icon size="40" url="/images/icon/car/car_6.png" color="#7ED321"></lee-img-icon>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
<!--                            <p v-if="data">冷链物流百强企业冷藏车资源方面显示，近5年来百强冷藏车总量逐年攀升。{{year}}年百强投入使用的冷藏车共计{{parseInt(data.car.now.total.total)}}辆，较上年增长{{data.car.now.total.rate}}%，约占全国冷藏车保有量的{{ ((parseInt(data.car.now.total.total) / data.car.baoyou.now.total) * 100).toFixed(2)}}%。与上年数据相比，百强自有车辆减少，外协车辆增加，根据调研结果显示，一方面是在国家政策影响下，企业在{{year}}年淘汰了一批国三车辆，并逐步补充国五、国六冷藏车；另一方面由于行业竞争加剧，企业对资源投入更为谨慎，更多通过外协车辆来进行运力的补充。同时由于城配、宅配等业务增加，企业偏向于购置轻型、中型冷藏车。{{year}}年百强冷链运输平均月公里数为10094.68公里/车/月，与上年相比增长19.63%，表明企业运输效率进一步提升。</p>-->
                            <div class="text-center margin-top-40">
                                <el-row :gutter="10">
                                    <el-col :md="24" :xs="24">
                                        <lee-bar-echarts
                                            ref="bar5"
                                            :grid-bottom="$isMobile ? 50 : 30"
                                            :height="$isMobile ? '300px' : '350px'"
                                            show-legend
                                            :show-left="false"
                                            :show-right="false"></lee-bar-echarts>
                                    </el-col>
<!--                                    <el-col :md="8" :xs="24">-->
<!--                                        <lee-pie-echarts-->
<!--                                            ref="pie3"-->
<!--                                            :grid-bottom="$isMobile ? 100 : 30"-->
<!--                                            :height="$isMobile ? '300px' : '350px'"-->
<!--                                            show-legend-->
<!--                                            :radius="[0,'60%']"></lee-pie-echarts>-->
<!--                                    </el-col>-->
                                </el-row>
                            </div>
<!--                            <div class="margin-top-40" v-if="data">-->
<!--                                <el-row :gutter="10">-->
<!--                                    <el-col :md="12" :xs="24">-->
<!--                                        <el-row :gutter="10">-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.yewu.now.peisong}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含配送型业务</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.yewu.now.peisong >= data.yewu.last.peisong ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.yewu.now.peisong >= data.yewu.last.peisong ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.yewu.now.yuanqu}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含园区型业务</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.yewu.now.yuanqu >= data.yewu.last.yuanqu ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.yewu.now.yuanqu >= data.yewu.last.yuanqu ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.yewu.now.baoshui}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含保税型业务</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.yewu.now.baoshui >= data.yewu.last.baoshui ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.yewu.now.baoshui >= data.yewu.last.baoshui ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.yewu.now.pifa}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含批发市场型业务</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.yewu.now.pifa >= data.yewu.last.pifa ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.yewu.now.pifa >= data.yewu.last.pifa ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                        </el-row>-->
<!--                                    </el-col>-->
<!--                                    <el-col :md="12" :xs="24">-->
<!--                                        <el-row :gutter="10">-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.lengku.zhanbi.now.lengdongku}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含冷冻区</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.lengku.zhanbi.now.lengdongku >= data.lengku.zhanbi.last.lengdongku ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.lengku.zhanbi.now.lengdongku >= data.lengku.zhanbi.last.lengdongku ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.lengku.zhanbi.now.lengcangku}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含冷藏区</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.lengku.zhanbi.now.lengcangku >= data.lengku.zhanbi.last.lengcangku ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.lengku.zhanbi.now.lengcangku >= data.lengku.zhanbi.last.lengcangku ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.lengku.zhanbi.now.changwenku}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含常温区</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.lengku.zhanbi.now.changwenku >= data.lengku.zhanbi.last.changwenku ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.lengku.zhanbi.now.changwenku >= data.lengku.zhanbi.last.changwenku ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                            <el-col :md="12" :xs="12">-->
<!--                                                <div class="bg-light box-shadow padding cl margin-bottom-20 border-green-2 border-top-10">-->
<!--                                                    <div class="z">-->
<!--                                                        <div class="f-thin">{{data.lengku.zhanbi.now.chaodiwenku}}% 百强企业</div>-->
<!--                                                        <div class="f-thin">含超低温区</div>-->
<!--                                                    </div>-->
<!--                                                    <div class="y">-->
<!--                                                        <lee-img-icon size="40" :url="data.lengku.zhanbi.now.chaodiwenku >= data.lengku.zhanbi.last.chaodiwenku ? '/images/icon/icon_sort_up.png' : '/images/icon/icon_sort_down.png'" :color="data.lengku.zhanbi.now.chaodiwenku >= data.lengku.zhanbi.last.chaodiwenku ? '#FF6262' : '#8ae824'" fixwh="width"></lee-img-icon>-->
<!--                                                    </div>-->
<!--                                                </div>-->
<!--                                            </el-col>-->
<!--                                        </el-row>-->
<!--                                    </el-col>-->
<!--                                </el-row>-->
<!--                            </div>-->
<!--                            <p class="f16 dark-2" v-if="data">冷链物流百强企业冷库资源方面显示，{{year}}年投入使用的冷库共约{{data.lengku.now.total.total}}万吨（折合约4213万立方米），较去年百强增长{{data.lengku.now.total.rate}}%，百强冷库的配送型、园区型业务增长明显，另外随着跨境冷链的发展，保税型冷库需求快速增加。百强企业冷库总量约占全国冷库总容量的{{ ((parseFloat(data.lengku.now.total.total) / parseFloat(data.lengku.other.now.size.total)) * 100 ).toFixed(2)}}%，与上年相比有小幅增长。截至{{year}}年年底，我国冷库总量达到{{data.lengku.other.now.size.total}}万吨（折合约{{data.lengku.other.now.volume.total}}亿立方米）。 <br/>在今年的峰会上，秦玉鸣秘书长在做了中国冷链物流百强分析之后，还增加了百强说环节，与百强TOP企业进行深度沟通，共探高速发展提升之道，更多详细信息，请关注中物联冷链委微信公众号。</p>-->
                        </div>
                    </div>
                </div>
            </check-vip>
        </div>
        <el-dialog
            title="选择企业"
            :visible.sync="is_show_baiqiang"
            width="60%"
            center
            @closed="closeBaiqiang">
            <div style="max-height: 400px;overflow-y: auto;">
                <el-row :gutter="10">
                    <el-col :span="8" v-for="(item,index) in BaiqiangList">
                        <div class="border-radius-5 hand padding cl margin-bottom-20" style="height: 50px;" :class="tmpChoose.indexOf(item.id) >= 0 ? 'bg-green-light' : ''" @click="toChooseBaiqiang(item)">
                            <span class="z line-index bg-green light f16" v-if="item.arank > 3">{{item.arank}}</span>
                            <img :src="'/images/icon/jiangbei_' + item.arank+'.png'" width="30" height="30" class="z" v-else/>
                          <i class="z margin-top-5 iconfont icon-vip margin-left yellow f20 vm" v-if="item.is_free === 0"></i>
                            <div class="z margin-left 20 text-line-1 f-thin" style="width: 200px;line-height: 30px;">
                              {{item.title}}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelChoose">取 消</el-button>
                <el-button type="success" @click="submitChoose">确 定</el-button>
            </span>
        </el-dialog>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            is_show_baiqiang:false,
            tabList:[
                {title:'企业概况',link:'#a1'},
                {title:'重点企业',link:'#a2'},
                {title:'经营绩效',link:'#a3'},
                {title:'分布情况',link:'#a4'},
                {title:'冷链资源',link:'#a5'}
            ],

            year:'',

            data:null,
            headColor:['bg-blue','bg-green','bg-yellow'],

            province_code:'',
            province_name:'',

            companyList:[],
            BaiqiangList:[],

            BaiqiangChoose:[],
            tmpChoose:[],

            vip_state:0,

            vipList:[]
        }
    },
    created() {
        let _now = new Date();
        let _startYear = _now.getFullYear() - 1;

        this.year = this.$route.params.year ? this.$route.params.year : _startYear;
    },
    mounted() {
        this.getAll(this.year);
        this.getBaiqiangList();
        this.getVipState();
    },
    methods:{
        getVipState(){
            let _state = this.$user.getVipState();

            this.vip_state = parseInt(_state);
        },
        closeBaiqiang(){
            this.is_show_baiqiang = false;
        },
        showBaiqiang(){
            this.is_show_baiqiang = true;
        },
        getAll(year){
            for(let i in this){
                if(i.indexOf('getTotal') === 0){
                    this[i](year);
                }
            }
        },

        toChooseBaiqiang(e){
            // if(this.tmpChoose.length < 10) {
                let _index = this.tmpChoose.indexOf(e.id);

                if (_index >= 0) {
                    if(this.tmpChoose.length > 1) {
                        this.tmpChoose.splice(_index, 1);
                    }else{
                        this.$message.error('至少要选择1个企业');
                    }
                } else {
                    if(this.tmpChoose.length < 10) {
                        console.log(e);
                        if(e.is_free === 0){
                            if(this.vip_state === 1){
                                this.tmpChoose.push(e.id);
                            }else{
                                this.$message.error('VIP才能选择该企业');
                            }
                        }else {
                            this.tmpChoose.push(e.id);
                        }
                    }else{
                        this.$message.error('最多只能选择10个企业');
                    }
                }
            // }else{
            //     this.$message.error('最多只能选择10个企业');
            // }
        },

        cancelChoose(){
            this.tmpChoose = [];

            for(let i=0;i < this.BaiqiangChoose.length;i++){
                this.tmpChoose.push(this.BaiqiangChoose[i].id);
            }

            this.closeBaiqiang();
        },

        submitChoose(){
            this.BaiqiangChoose = [];

            for(let i=0;i < this.BaiqiangList.length;i++){

                if(this.tmpChoose.indexOf(this.BaiqiangList[i].id) >= 0){
                    this.BaiqiangChoose.push(this.BaiqiangList[i]);
                }
            }

            this.closeBaiqiang();

            this.getBaiqiangData();
        },

        getBaiqiangList(){
            let _this = this;
            this.$http.post({
                url: '/Baiqiang/listAll',
                data: {orderBy:'arank',sortBy:'asc',year:this.year,is_open:1},
                success(res) {
                    _this.BaiqiangList = res;

                    // _this.baiqiangList = res;

                    if(_this.BaiqiangChoose.length <= 0){
                        for(let i=0;i < res.length;i++){
                            if(_this.BaiqiangChoose.length < 10) {
                                if(res[i].is_free === 1) {
                                    _this.BaiqiangChoose.push(res[i]);
                                    _this.tmpChoose.push(res[i].id);
                                }
                            }else{
                                break;
                            }
                        }
                    }

                    _this.getBaiqiangData();

                }
            });
        },

        getBaiqiangData(){
            let _this = this;

            let _ids = [];

            for(let i=0;i<this.BaiqiangChoose.length;i++){
                _ids.push(this.BaiqiangChoose[i].id);
            }

            this.$http.post({
                url: '/Baiqiang/listAll',
                data: {orderBy:'arank',sortBy:'asc',year:this.year,id:[_ids,'in']},
                success(res) {

                    let _baiqiangBar = {bar:[],bar2:[],bar3:[],xaxis:[]};

                    for(let i=0;i < res.length;i++) {
                        let _lengku = 0;
                        let _car = 0;

                        _lengku += parseFloat(res[i].changwenku);
                        _lengku += parseFloat(res[i].chaodiwenku);
                        _lengku += parseFloat(res[i].lengdongku);
                        _lengku += parseFloat(res[i].lengcangku);

                        _car += res[i].heavy_car;
                        _car += res[i].light_car;
                        _car += res[i].mini_car;
                        _car += res[i].small_car;
                        // _car += res[i].heavy_car;
                        // _car += res[i].heavy_car;

                        _baiqiangBar.bar.push(parseFloat(res[i].amount));
                        _baiqiangBar.bar2.push(_lengku.toFixed(2));
                        _baiqiangBar.bar3.push(_car);
                        // _bardata4.line.push(res.car.list[i].rate);
                        _baiqiangBar.xaxis.push(res[i].title);
                    }

                    if(_this.$refs.baiqiang) {
                        _this.$refs.baiqiang.initData([
                                // {name: '营业收入', type: 'bar', color: 'green', data: _baiqiangBar.bar},
                                {name: '冷库总量', type: 'bar', color: 'blue', data: _baiqiangBar.bar2},
                                {name: '冷藏车总量', type: 'bar', color: 'orange', data: _baiqiangBar.bar3},
                                // {name: '增长率', type: 'line', color: 'purple', data: _bardata4.line}
                            ],
                            _baiqiangBar.xaxis
                        );
                    }


                }
            });
        },

        mapOnload(e){
            console.log('mapOnload');
            this.province_code = e.province_code;
            this.province_name = e.province_name;

            this.getCompany()
        },

        mapClick(e){
            console.log(e);
            this.province_code = e.province_code;
            this.province_name = e.province_name;

            this.getCompany();
        },

        getCompany(){
            let _this = this;

            this.$http.post({
                url:'/Baiqiang/listAll',
                data:{province_code:this.province_code,year:this.year},
                success(res){
                    console.log(res);

                    _this.companyList = res;
                }
            })
        },

        getTotal1(year){
            let _this = this;
            this.$http.post({
                url:'/Baiqiang/getData',
                data:{year:year},
                success(res){
                    _this.data = res;

                    //营业收入
                    let _bardata = {bar:[],line:[],xaxis:[]};

                    //门槛
                    let _bardata2 = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.total.list.length;i++) {
                        _bardata.bar.push(parseFloat(res.total.list[i].amount));
                        _bardata.line.push(res.total.list[i].amount_rate);
                        _bardata.xaxis.push(res.total.list[i].year.toString());

                        _bardata2.bar.push(res.total.list[i].menkan);
                        _bardata2.line.push(res.total.list[i].menkan_rate);
                        _bardata2.xaxis.push(res.total.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData([
                            {name: '冷链业务营业收入(亿元)', type: 'bar', color: 'green', data: _bardata.bar},
                            {name: '增长率', type: 'line', color: 'purple', data: _bardata.line}
                            ],
                            _bardata.xaxis
                        );
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData([
                                {name: '入围门槛营收(万元)', type: 'bar', color: 'green', data: _bardata2.bar},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata2.line}
                            ],
                            _bardata2.xaxis
                        );
                    }

                    let _bardata3 = {bar:[],bar2:[],xaxis:[]};
                    let _pie1 = [];

                    for(let i = 0;i < res.area.amount.length;i++){
                        _bardata3.bar.push(res.area.amount[i].total);
                        _bardata3.bar2.push(res.area.amount[i].pinjun);
                        _pie1.push({value:res.area.amount[i].total,name:res.area.amount[i].title});
                        _bardata3.xaxis.push(res.area.amount[i].title);
                    }

                    // _bardata3.xaxis = ['东北','华北','华东','华中','华南','西南','西北'];

                    if(_this.$refs.bar3) {
                        _this.$refs.bar3.initData([
                                {name: '区域百家重点企业'+year+'年冷链业务营业收入(万元)', type: 'bar', color: 'green', data: _bardata3.bar},
                                {name: '区域百家重点企业'+year+'年冷链业务营收均值(万元)', type: 'bar', color: 'blue', data: _bardata3.bar2}
                            ],
                            _bardata3.xaxis
                        );
                    }

                    if(_this.$refs.pie1) {
                        _this.$refs.pie1.initData(_pie1);
                    }

                    //冷藏车
                    let _bardata4 = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.car.list.length;i++) {
                        _bardata4.bar.push(parseFloat(res.car.list[i].total));
                        _bardata4.line.push(res.car.list[i].rate);
                        _bardata4.xaxis.push(res.car.list[i].year.toString());
                    }

                    if(_this.$refs.bar4) {
                        _this.$refs.bar4.initData([
                                {name: '冷链物流百家重点企业冷藏车总量(辆)', type: 'bar', color: 'green', data: _bardata4.bar},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata4.line}
                            ],
                            _bardata4.xaxis
                        );
                    }

                    let _pie2 = [];
                    let _other = 0;

                    _other += parseInt(res.car.now.heavy_car);
                    _other += parseInt(res.car.now.light_car);
                    _other += parseInt(res.car.now.mini_car);
                    _other += parseInt(res.car.now.small_car);

                    _pie2.push({value:parseInt(res.car.now.owner_car),name:year+'年冷链物流百家重点企业自有冷藏车数量占比'});
                    _pie2.push({value:parseInt(res.car.now.out_car),name:year+'年冷链物流百家重点企业外协冷藏车数量占比'});
                    _pie2.push({value:_other,name:year+'年冷链物流非百家重点企业冷藏车数量占比'});

                    if(_this.$refs.pie2) {
                        _this.$refs.pie2.initData(_pie2);
                    }

                    //冷库
                    let _bardata5 = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.lengku.list.length;i++) {
                        _bardata5.bar.push(res.lengku.list[i].total);
                        _bardata5.line.push(res.lengku.list[i].rate);
                        _bardata5.xaxis.push(res.lengku.list[i].year.toString());
                    }

                    if(_this.$refs.bar5) {
                        _this.$refs.bar5.initData([
                                {name: '冷链物流百家重点企业冷库总容量(立方米)', type: 'bar', color: 'green', data: _bardata5.bar},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata5.line}
                            ],
                            _bardata5.xaxis
                        );
                    }

                    let _pie3 = [];
                    // let _other2 = 0;
                    //
                    // _other2 += parseFloat(res.lengku.now.changwenku);
                    // _other2 += parseFloat(res.lengku.now.lengcangku);
                    // _other2 += parseFloat(res.lengku.now.chaodiwenku);
                    // _other2 += parseFloat(res.lengku.now.lengdongku);

                    _pie3.push({value:res.lengku.now.total.total,name:year+'年冷链物流百家重点企业冷库容量占比'});
                    _pie3.push({value:res.lengku.other.now.size ? res.lengku.other.now.size.total : 0,name:year+'年冷链物流非百家重点企业冷库容量占比'});

                    if(_this.$refs.pie3) {
                        _this.$refs.pie3.initData(_pie3);
                    }
                }
            })
        },
    }
}
</script>
