import { render, staticRenderFns } from "./VipCheckButton.vue?vue&type=template&id=baffb3de"
import script from "./VipCheckButton.vue?vue&type=script&lang=js"
export * from "./VipCheckButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports