<template>
    <lee-echarts :options="option" autoresize theme="lenglian" :style="{height:height,width:width}"></lee-echarts>
</template>

<script>
import ECharts from 'vue-echarts'
import echartsTheme from "@/assets/json/lenglian.json";
import 'echarts/lib/chart/bar'
export default {
    name: "BarEcharts1",
    data(){
        return{

        }
    },
    props:{
        width:String,
        height:String,
        option:{
            type:Object,
            default:null
        }
    },
    created() {
        this.theme = echartsTheme
        ECharts.registerTheme('lenglian', echartsTheme)
        // this.id = this.$utils.randomWords(10);
        // this.$echarts.registerTheme('lenglian', echartsTheme);
        //
        // this.valueData = this.data;
        // this.categoryData = this.category;

        // console.log(this.data);
        // console.log(this.category);
        // console.log(this.option);
    },
    mounted() {
        // this.echarts = this.$echarts.init(
        //     document.getElementById(this.id),
        //     'lenglian',
        //     {
        //         width:this.width,
        //         height:this.height
        //     }
        // );
        // this.echarts.setOption(this.option);
    },
    watch:{
    },
    methods:{

    }
}
</script>
