<template>
    <span class="img-icon" :style="{width:wh+'px',height:wh+'px',lineHeight:wh+'px',background:color,borderRadius:'50%'}" style="font-size:0 !important;">
        <img :src="url" v-if="url" :style="{height:fixwh === 'height' ? wh/2 +'px' : '',width:fixwh === 'width' ? wh/2 +'px' : ''}"/>
    </span>
</template>

<script>
export default {
    name: "ImgIcon",
    data(){
        return{
            wh:0
        }
    },
    props:{
        size:{
            type:[String,Number],
            default:65
        },
        url:{
            type:String,
            default:''
        },
        color:{
            type:String,
            default:'#fff'
        },
        fixwh:{
            type:String,
            default:'height'
        },
    },
    created() {
        this.wh = parseInt(this.size);
    }
}
</script>
<style scoped>
    .img-icon{display: inline-block;text-align: center;}
</style>
