<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="10" class="f16 dark-1" v-if="data">
          {{nowYear}}年，中国主要农产品市场数量为{{data.now_count.total}}个。
<!--            根据{{nowYear}}中国商品交易市场统计年鉴的数据显示，我国目前农产品市场主要分为以下2类：农产品综合市场及农产品专业市场。其中农产品专业市场还可细分为粮油市场、肉禽蛋市场、水产品市场、蔬菜市场、干鲜果品市场、棉麻土畜烟叶市场、其他农产品市场；其中{{data.count[0].title}}市场数量、总摊位数、年末出租摊由数、营业面、成交额均位列第一，分别为：{{data.count[0].market_count}}个、{{data.count[0].stall_count}}个、{{data.count[0].end_stall_count}}个、{{data.count[0].area}}平方米、{{data.count[0].amount}}万元。-->
        </el-col>
        <el-col :span="14">
            <table class="lee-table f12 margin-auto" :class="$isMobile ? 'mobile' : ''" v-if="data">
                <thead>
                <td>市场类型</td>
                <td>市场数量(个)</td>
                <td>总摊位数(个)</td>
                <td>年末出租摊位数(个)</td>
                <td>营业面积(平方米)</td>
                <td>成交额(万元)</td>
                </thead>
                <tr v-for="(item,index) in data.count">
                    <td>{{item.title}}</td>
                    <td>{{item.market_count ? item.market_count : '0'}}</td>
                    <td>{{item.stall_count ? item.stall_count : '0'}}</td>
                    <td>{{item.end_stall_count ? item.end_stall_count : '0'}}</td>
                    <td>{{item.area ? item.area : '0'}}</td>
                    <td>{{item.amount ? item.amount : '0'}}</td>
                </tr>
            </table>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData6",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {
        // this.startYear = this.year;
        // this.endYear = this.year - 4;
    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/HydataNongpi/getYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear, endYear){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataNongpi/getData',
                data:{year:startYear},
                success(res){
                    _this.data = res;

                    // let _data = res;
                    //
                    // let _bardata = {bar:[],xaxis:[]};
                    //
                    // for(let i=0;i < _data.length;i++) {
                    //     _bardata.bar.push(_data[i].quantity);
                    //     _bardata.xaxis.push(_data[i].regional ? _data[i].regional.name : '');
                    // }
                    //
                    // if(_this.$refs.bar1) {
                    //     _this.$refs.bar1.initData([
                    //             {name: '市场数量', type: 'bar', color: 'most', data: _bardata.bar}],
                    //         _bardata.xaxis
                    //     );
                    // }
                }
            })
        },
    }
}
</script>
