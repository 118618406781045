<template>
	<lee-user-main-page type="dark">
		<lee-user-page class="padding-bottom-100">
			<el-button type="primary" size="mini" @click="$router.push({path:'/user/fapiao/add'})" slot="headRight">申请发票</el-button>
			<el-table :data="list">
				<el-table-column label="申请时间" prop="create_time" width="180px"></el-table-column>
				<el-table-column label="发票类型">
					<template slot-scope="scope">
						<span v-if="scope.row.open_type === 'person'">个人</span>
						<span v-if="scope.row.open_type === 'company'">公司</span>
					</template>
				</el-table-column>
				<el-table-column label="发票抬头" prop="title"></el-table-column>
				<el-table-column label="发票总额" prop="pay_amount">
					<template slot-scope="scope">
						￥{{scope.row.pay_amount}}
					</template>
				</el-table-column>
				<el-table-column label="发票性质">
					<template slot-scope="scope">
						<span v-if="scope.row.type === 'email'">电子</span>
						<span v-if="scope.row.type === 'paper'">纸质</span>
					</template>
				</el-table-column>
				<el-table-column label="发票状态">
					<template slot-scope="scope">
						<span v-if="scope.row.state === 0">待审核</span>
						<span v-if="scope.row.state === 1">已开票</span>
						<span v-if="scope.row.state === 2">已拒绝</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right">
					<template slot-scope="scope">
<!--						<el-button type="text" @click="toEdit(scope.row)" v-if="scope.row.state === 2 || scope.row.state === 0">修改</el-button>-->
						<el-button type="text" @click="toShowFile(scope.row)" v-if="scope.row.state === 1">查看</el-button>
						<el-button type="text" @click="toDetail(scope.row)">明细</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="text-center margin-top-100">
				<el-pagination
					background
					layout="pager"
					:page-size="searchForm.pageSize"
					:current-page.sync="searchForm.page"
					:total="total"
					@current-change="pageChange">
				</el-pagination>
			</div>
		</lee-user-page>
	</lee-user-main-page>
</template>

<script>
export default {
	name: "Fapiao",
	data() {
		return {
			list:[],
			searchForm:{
				page:1,
				pageSize:10
			},
			total:0
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		pageChange(e){
			this.searchForm.page = e;
			this.getList();
		},
		toEdit(row){
			this.$router.push({path:'/user/fapiao/add',query:{id:row.id}})
		},
		getList(){
			let _this = this;
			this.$http.post({
				url: '/AuthUserInvoiceLog/listPage',
				data: this.searchForm,
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			});
		},
		
		toDetail(row){
			this.$router.push({path:'/user/fapiao/detail',query:{id:row.id}});
		},
		
		toShowFile(row){
			if(row && row.file){
				let _url = row.file.indexOf('http') >= 0 ? row.file : this.$config.assetsUrl + row.file;
				
				window.open(_url,'_blank');
			}
		}
	}
}
</script>
