<template>
        <el-upload
          class="avatar-uploader"
            :show-file-list="false"
            :action="$config.uploadUrl"
            :data="{key:imgKey}"
            :headers="{Authorization:$user.getToken()}"
            accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP"
            ref="upload"
            :on-success="onSuccess"
            :on-exceed="onExceed"
            :file-list="fileList"
            :limit="limit"
            :auto-upload="true">
          <img v-if="values" :src="values" class="border-radius hide vm" style="height: 80px;width:80px;">
          <i class="el-icon-edit-outline avatar-uploader-icon abs" style="display: none;"></i>
        </el-upload>
</template>

<script>
export default {
    name: "HeadImgUpload",
    data() {
        return {
            bigImg: '',
            show: false,
            imgKey:'headImg',
            fileList:[],
            values:''
        };
    },
    model:{
        prop:'value',
        event:'change'
    },
    props:{
        value:{
            type:String,
            default:''
        },
        limit:{
            type:Number,
            default:1
        }
    },
    created() {
        this.values = this.value.indexOf('http') >= 0 ? this.value : this.$config.assetsUrl + this.value;
    },
    watch:{
        value(n,o){
          console.log(n);
            this.values = n && n.indexOf('http') >= 0 ? n : this.$config.assetsUrl + n;

            if(!n){
                this.fileList = [];
            }
        }
    },
    methods: {
        delImg(){
            this.values = '';
            this.$refs.upload.clearFiles();
            this.$emit('change','');
        },
        showBigImg() {
            this.bigImg = this.values.indexOf('http') >= 0 ? this.values : this.$config.assetsUrl + this.values;
            this.show = true;
        },
        onSuccess(response, file, fileList) {
            if(response.code !== 0){
                this.$utils.error(response.msg);
                this.fileList = [];
            }else{
                this.fileList = fileList;

                this.values = response.result.url;

                this.$emit('change',response.result.url);
            }
        },
        onExceed(f,fl){
            console.log('323');
        }
    },
    destroyed() {
        console.log('dis');
    }
}
</script>
<style>
.avatar-uploader-icon {
  font-size: 28px;
  color: #fff;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  top:0;
  left:0;
  background-color: rgba(0,0,0,.3);
}
.avatar-uploader .el-upload {
  border: 1px solid #ccc;
  border-radius: 99999px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader .el-upload:hover .avatar-uploader-icon{display: block !important;}
</style>