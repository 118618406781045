<template>
    <div class="report-tab" :class="$isMobile ? 'box-shadow border-radius-5 bg-light' : 'border-bottom-1'" :style="{borderBottomColor:color}">
        <div class="cl">
            <div class="z" :style="'width: '+(100 / list.length)+'%'" v-for="(item,index) in list">
                <div class="text-center" :class="$isMobile ? 'f14' : 'f18'">
                    <a class="hand inline-block padding-top-bottom" :style="{borderBottom:activeIndex === index ? '5px solid '+color : '',color:activeIndex === index ? color : '#777'}" @click="click(item.link)">{{item.title}}</a>
                </div>
            </div>
        </div>
<!--        <el-row>-->
<!--            <el-col :span="24 / list.length" v-for="(item,index) in list">-->
<!--                <div class="text-center" :class="$isMobile ? 'f14' : 'f18'">-->
<!--                    <a class="hand inline-block padding-top-bottom" :style="{borderBottom:activeIndex === index ? '5px solid '+color : '',color:activeIndex === index ? color : '#777'}" @click="click(item.link)">{{item.title}}</a>-->
<!--                </div>-->
<!--            </el-col>-->
<!--        </el-row>-->
    </div>
</template>

<script>
export default {
    name: "HashTab",
    props:{
        color:{
            type:String,
            default:'#7ED321'
        },
        list:{
            type:Array,
            default:()=>{
                return []
            }
        },
        activeIndex:{
            type:Number,
            default:0
        }
    },
    methods:{
        click(e){
					console.log(e);
            if(typeof e === 'string' && e.indexOf('#') === 0){
							console.log(document.querySelector(e))
                document.querySelector(e).scrollIntoView();
            }else {
                this.$router.push({path: item.link ? item.link : ''})
            }
        }
    }
}
</script>
