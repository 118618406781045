<template>
    <el-row :gutter="30" class="margin-top-20">
        <el-col :span="12" class="f16 dark-1" v-if="data">
          据中物联冷链委统计，{{nowYear}}年全国冷链物流总额达{{data.now.lenglian_amount}}亿元，较上年{{ (parseFloat(data.now.lenglian_amount) - parseFloat(data.last.lenglian_amount)) >= 0 ? '增长' : '减少'}}{{ (parseFloat(data.now.lenglian_amount) - parseFloat(data.last.lenglian_amount)).toFixed(2)}}亿元，同比增长{{data.now.lenglian_rate}}%。
<!--            {{nowYear}}年，中物联冷链委通过对蔬菜、水果、肉类、水产品、乳制品和速冻米面这六大类食品（其它类食品产量较少或基本不采用冷链物流方式流通，故没有计算在内）的年产量进行统计，并结合各品类的冷链流通率，测算{{nowYear}}年我国食品冷链物流需求总量为{{data.now.lenglian_amount}}亿吨，比{{nowYear - 1}}年增长{{ (parseFloat(data.now.lenglian_amount) - parseFloat(data.last.lenglian_amount)).toFixed(2)}}万吨，同比增长{{data.now.lenglian_rate}}%。-->
        </el-col>
        <el-col :span="12">
            <lee-bar-echarts
                ref="bar1"
                height="200px"
                width="512px"
                :show-legend="false"
                :show-right="false"
                :bar-width="20"
            ></lee-bar-echarts>
        </el-col>
    </el-row>
</template>

<script>
export default {
    name: "IndexHyData3",
    data(){
        return{
            data:null,
          nowYear:'',
          endYear:''
        }
    },
    props:{
        // year:{
        //     type:[String,Number],
        //     default:''
        // }
    },
    created() {
        // this.startYear = this.year;
        // this.endYear = this.year - 4;
    },
    mounted() {
        this.getYears();
    },
    methods:{
      getYears() {
        let _this = this;

        this.$http.post({
          url: '/HydataLenglian/getYears',
          data:null,
          success(res) {
            _this.endYear = res[res.length - 1];

            _this.nowYear = res[0];

            _this.getTotal1(_this.nowYear,_this.endYear);
          }
        });
      },
        getTotal1(startYear, endYear){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataLenglian/getData',
                data:{time_type:'year',year:[2024,'<=']},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};

                    for(let i=0;i < res.list.length;i++) {
                        _bardata.bar.push(res.list[i].lenglian_amount);
                        _bardata.line.push(res.list[i].lenglian_rate);
                        _bardata.xaxis.push(res.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '冷链物流总额', type: 'bar', color: 'green', data: _bardata.bar,dw:'亿元'},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line,dw:'%'},
                            ],
                            _bardata.xaxis
                        );
                    }
                }
            })
        },
    }
}
</script>
