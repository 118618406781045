<template>
	<lee-page bg-img="/images/bg_head_9.jpg" type="light" title-class="margin-top-100" height="250" title="冷库相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷库'}]">
		<div class="bg-img-2 padding-bottom-400 padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_storage">
				<div class="wrap1100">
					<el-row :gutter="20">
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'HydataLengku' ? 'bg-green light' : 'green'" @click="onChangeDataType('HydataLengku')">冷库总量</div>
						</el-col>
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'gongxu' ? 'bg-green light' : 'green'" @click="onChangeDataType('gongxu')">冷库供需</div>
						</el-col>
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'shichang' ? 'bg-green light' : 'green'" @click="onChangeDataType('shichang')">冷库市场</div>
						</el-col>
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'jianshe' ? 'bg-green light' : 'green'" @click="onChangeDataType('jianshe')">冷库建设</div>
						</el-col>
					</el-row>
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<lengku v-if="dataType === 'HydataLengku'"></lengku>
						<gongxu v-if="dataType === 'gongxu'"></gongxu>
						<build v-if="dataType === 'jianshe'"></build>
						<Market v-if="dataType === 'shichang'"></Market>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
import Lengku from './components/Lengku'
import Gongxu from './components/Gongxu'
import Build from './components/Build'
import Market from './components/Market'
export default {
	name: "List",
	components: {Market, Build, Gongxu, Lengku},
	data() {
		return {
			dataType:'HydataLengku'
		}
	},
	created() {
		let _this = this;
	},
	mounted() {
	},
	methods: {
		onChangeDataType(e){
			this.dataType = e;
		},
	}
}
</script>
