<template>
    <lee-page bg-img="/images/bg_head_5.jpg" title="企业盘点" sub-title="Prize" search stop-search show-year to-path="/prize/search">
<!--        <div class="margin-auto" style="width:700px;margin-top:-20px">-->
<!--            <lee-search class="box-shadow"></lee-search>-->
<!--        </div>-->
        <div class="bg-img-11 padding-top-100 padding-bottom-400">
            <div class="wrap1100">
                <el-row :gutter="60">
                    <el-col :span="12">
                        <div class="margin-bottom-20">
                          <div class="orange f-lq f30">食材供应链盘点</div>
                          <div class="dark-2 f14 margin-top">表彰农产品行业内的标杆企业和案例</div>
                        </div>
                        <div style="width: 100%;height: 500px" class="rel block-list-item hand hide box-shadow" @click="$router.push({path:'/prize/list/nongcp'})">
                            <div class="rel padding bg-light">
                                <img src="/images/bg_cell_16.jpg" width="100%" height="480"/>
                            </div>
                            <div class="abs block-list-on bg-green-op-2 padding" style="height: 100%;width: 100%;left:0;top:0;">
                                <div class="border-w-1 text-center border-light" style="height: 100%;width: 100%">
                                    <span class="inline-block padding-50 border-w-3 margin-top-120 border-light"><img src="/images/icon/icon_60.png" width="128"/></span>
                                </div>
                            </div>
                        </div>
                    </el-col>
<!--                    <el-col :span="12">-->
<!--                        <div class="margin-bottom-20">-->
<!--                            <div class="green f-lq f30">医疗器械奖项</div>-->
<!--                            <div class="dark-2 f14 margin-top">表彰医疗器械行业内的标杆企业和案例</div>-->
<!--                        </div>-->
<!--                        <div style="width: 100%;height: 500px" class="rel block-list-item hand hide box-shadow" @click="$router.push({path:'/prize/list'})">-->
<!--                            <div class="rel padding bg-light">-->
<!--                                <img src="/images/bg_cell_22.jpg" width="100%" height="480"/>-->
<!--                            </div>-->
<!--                            <div class="abs block-list-on bg-green-op-2 padding" style="height: 100%;width: 100%;left:0;top:0;">-->
<!--                                <div class="border-w-1 text-center border-light" style="height: 100%;width: 100%">-->
<!--                                    <span class="inline-block padding-50 border-w-3 margin-top-120 border-light"><img src="/images/icon/icon_60.png" width="128"/></span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </el-col>-->
                </el-row>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            showZhengce:true,
            showBiaozhun:true,
            type:'1',
            zcList:[
                {title:'补贴政策',path:'/zhengce/list/butie',icon:'/images/icon/icon_41.png'},
                {title:'规划政策',path:'/zhengce/list/guihua',icon:'/images/icon/icon_42.png'},
                {title:'征询政策',path:'/zhengce/list/zhengxun',icon:'/images/icon/icon_43.png'},
                {title:'管控政策',path:'/zhengce/list/guankong',icon:'/images/icon/icon_44.png'}
            ],
            bzList:[
                {title:'参考标准',path:'/zhengce/list/cankao',icon:'/images/icon/icon_45.png'},
                {title:'团体标准',path:'/zhengce/list/team',icon:'/images/icon/icon_46.png'},
                {title:'强制标准',path:'/zhengce/list/qiangzhi',icon:'/images/icon/icon_47.png'},
                {title:'行业标准',path:'/zhengce/list/hangye',icon:'/images/icon/icon_48.png'}
            ],
            tabList:[
                {label:'药品标准',value:'1',icon:'/images/icon/icon_57_'},
                {label:'医疗器械标准',value:'2',icon:'/images/icon/icon_58_'},
            ],
            medicalList:[
                {title:'国家标准',image:'/images/bg_cell_13.jpg',path:'/standard/list/produce',des:'描述描述描述描述描述描述描述描述描述描述描述描述'},
                {title:'行业标准',image:'/images/bg_cell_14.jpg',path:'/standard/list/approval',des:'描述描述描述描述描述描述描述描述描述描述描述描述'},
                {title:'团体标准',image:'/images/bg_cell_15.jpg',path:'/standard/list/use',des:'描述描述描述描述描述描述描述描述描述描述描述描述'},
                {title:'扶持政策',image:'/images/bg_cell_16.jpg',path:'/standard/list/manage',des:'描述描述描述描述描述描述描述描述描述描述描述描述'},
            ],
        }
    },
    methods:{
        onTabChange(i){
            this.type = i.value;
        }
    }
}
</script>
