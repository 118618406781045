<template>
    <lee-user-main-page type="dark">
        <lee-user-page class="padding-bottom-100">
            <template v-if="list.length > 0">
                <lee-base-cell :right-title="$isMobile ? '' : '查看'" v-for="(item,index) in list" title-class="green f14 f-n" img="/images/icon/icon_q.png">
                    <div slot="subTitle">
                        <div class="f16 dark-1" :class="$isMobile ? 'text-line-2' : 'text-line-1'" style="line-height: 24px;">{{item.title}}</div>
                        <div class="dark-3 f-thin" :class="$isMobile ? 'f12' : 'f14 margin-top'" style="line-height: 20px;">时间：{{item.create_time}}</div>
                    </div>
                    <div slot="rightButton" class="text-right">
                        <lee-state-tag title="已付款" type="blue" v-if="item.is_pay === 1"></lee-state-tag>
                        <lee-state-tag title="购买回答" type="red" class="hand" v-else-if="item.is_pay === 0 && item.is_answer === 1"></lee-state-tag>
                        <div class="gray-2" v-else>.</div>
                    </div>
                </lee-base-cell>
                <div class="text-center margin-top-100" v-if="!$isMobile">
                    <el-pagination
                        background
                        layout="pager"
                        :page-size="pageSize"
                        :current-page.sync="page"
                        :total="total"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
            </template>
            <div class="text-center padding-top-bottom-40 f18 dark-2" v-else>
            暂无记录
            </div>
        </lee-user-page>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "BuyLog",
    data(){
        return{
            list:[],
            page:1,
            pageSize:20,
            total:0
        }
    },
    created() {
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;
            this.$http.post({
                url: '/User/getMyQuestion',
                data: {page:this.page},
                success(res) {
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.$router.replace({query:{page:this.page}});
        },
    }
}
</script>
