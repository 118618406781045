<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">产销量概况</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">人均消费量</span>
			</div>
			<div class="f16 margin-top-20 dark-2" v-if="data && data.cost && data.cost.now && data.cost.now.content1">
				{{data.cost.now.content1 ? data.cost.now.content1 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<el-row>
					<el-col :span="12">
						<lee-bar-echarts-hor
							key="bar2"
							ref="bar2"
							:grid-bottom="10"
							:grid-top="10"
							grid-right="10%"
							height="350px"
							:show-legend="false"
							legend-orient="vertical"
							legend-pos="right"
							:show-left="true"
							:show-right="false"
						></lee-bar-echarts-hor>
					</el-col>
					<el-col :span="12">
						<lee-pie-echarts
							key="pie2"
							ref="pie2"
							:grid-bottom="30"
							height="350px"
							:show-legend="false"
							label-format="{b}:{d}%"
							label-pos="outside"
							:radius="['30%','50%']"></lee-pie-echarts>
					</el-col>
				</el-row>
			</div>
<!--			<div class="margin-auto" style="width:600px">-->
<!--				<lee-pie-echarts-->
<!--					ref="pie2"-->
<!--					:grid-bottom="30"-->
<!--					height="350px"-->
<!--					:label-format="'{b}\n{d}%'"-->
<!--					label-pos="outside"-->
<!--					legend-orient="horizontal"-->
<!--					legend-pos="bottom"-->
<!--					:radius="['0%','50%']"></lee-pie-echarts>-->
<!--			</div>-->
		</div>
		<div class="margin-top-20" style="border-bottom: 2px solid #7ED321;">
			<span class="light bg-green" style="padding:7px 20px;display: inline-block;">产量</span>
		</div>
		<div class="f16 dark-2">
			<p v-if="pieData">{{getTimeStr(searchData,searchQuarterData ? 'quarter' : 'year',searchQuarterData)}}
				<template v-for="(item,index) in pieData">
					{{ item.name }}总产量为{{ item.value }}万吨{{ index + 1 === pieData.length ? '。' : '，' }}
				</template>
			</p>
			<div class="margin-auto" style="width:600px">
				<lee-pie-echarts
					ref="pie1"
					:grid-bottom="30"
					height="350px"
					:label-format="'{b}\n{d}%'"
					label-pos="outside"
					legend-orient="vertical"
					legend-pos="right"
					:radius="['0%','60%']"></lee-pie-echarts>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ChanliangAll",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:'',
			pieData: null,
			pie2Data: null,
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		getTimeStr(year,time_type,times){
			let quarterTwo = {'1':'第一季度', '2':'第二季度','3':'第三季度', '4':'第四季度'};
			if(time_type === 'year'){
				return year+'年';
			}else{
				return year+'年'+quarterTwo[times];
			}
		},
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataChanliang/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataChanliang/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		getTotal1(year) {
			let _this = this;
			let _where = {};
			if(this.searchData){
				_where.year = [this.searchData,'<=']
			}
			if(this.searchQuarterData){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
			}else{
				_where.time_type = 'year';
			}
			this.$http.post({
				url: '/HydataChanliang/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _pie1data = [];
					let _pie2data = [];
					let _bardata2 = {bar1: [], xaxis: []};

					_bardata2.bar1.push(res.cost.now.shucai);
					_bardata2.bar1.push(res.cost.now.rou);
					_bardata2.bar1.push(res.cost.now.qin);
					_bardata2.bar1.push(res.cost.now.shuichanpin);
					_bardata2.bar1.push(res.cost.now.dan);
					_bardata2.bar1.push(res.cost.now.nai);
					_bardata2.bar1.push(res.cost.now.shuiguo);
					_bardata2.xaxis = ['蔬菜及食用菌','肉类','禽类','水产品','蛋类','奶类','干鲜瓜果类']

					_pie1data.push({value: res.shuiguo.now.total, name: '水果'});
					_pie1data.push({value: res.shucai.now.total, name: '蔬菜'});
					_pie1data.push({value: res.rouzhipin.now.total, name: '肉制品'});
					_pie1data.push({value: res.shuichanpin.now.total, name: '水产品'});
					_pie1data.push({value: res.ruzhipin.now.total, name: '乳制品'});
					_pie1data.push({value: res.sudongshipin.now.total, name: '速冻食品'});
					_this.pieData = _pie1data;

					_pie2data.push({value: res.cost.now.shucai, name: '蔬菜及食用菌'});
					_pie2data.push({value: res.cost.now.rou, name: '肉类'});
					_pie2data.push({value: res.cost.now.qin, name: '禽类'});
					_pie2data.push({value: res.cost.now.shuichanpin, name: '水产品'});
					_pie2data.push({value: res.cost.now.dan, name: '蛋类'});
					_pie2data.push({value: res.cost.now.nai, name: '奶类'});
					_pie2data.push({value: res.cost.now.shuiguo, name: '干鲜瓜果类'});
					_this.pie2Data = _pie2data;

					setTimeout(()=>{
						if (_this.$refs.pie1) {
							_this.$refs.pie1.initData(_pie1data);
						}

						if (_this.$refs.pie2) {
							_this.$refs.pie2.initData(_pie2data,'千克');
						}

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '人均消费量', type: 'bar', color: 'most', data: _bardata2.bar1, dw: '千克'},
								],
								_bardata2.xaxis
							);
						}
					},200)
				}
			});
		},
	}
}
</script>
