<template>
    <span class="abs border-radius-h hand hot" :style="{width:size+'px',height:size+'px',top:top+'px',left:left+'px',right:right+'px',bottom:bottom+'px'}"></span>
</template>

<script>
export default {
    name: "MapHot",
    props:{
        size:{
            type:[String,Number],
            default:0
        },
        top:[String,Number],
        left:[String,Number],
        right:[String,Number],
        bottom:[String,Number],
    }
}
</script>
<style scoped>
.hot:hover{box-shadow: 0 0 10px rgba(0,0,0,.4);}
</style>
