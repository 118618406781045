<template>
    <div class="text-center margin-top-50 padding-left-right">
        <i class="el-icon-success" style="font-size: 100px;color:green"></i>
        <div class="f16 padding-top-bottom-40">登录成功</div>
    </div>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            uuid:'',
            openid:'',
            logined:false
        }
    },
    created() {
        this.uuid = this.$route.query.uuid ? this.$route.query.uuid : '';
        this.openid = this.$route.query.openid ? this.$route.query.openid : '';

        console.log(this.$route.query);
    },
    mounted() {
        this.qrLogin();
    },
    methods:{
        qrLogin(){
            //wx/user
            let _this = this;
            if(this.openid) {
                this.$http.post({
                    url: 'auth/login',
                    data: {openid: this.openid,password:this.openid},
                    success(res) {
                        console.log(res);
                        // _this.logined = true;
                    }
                })
            }
        }
    }
}
</script>
