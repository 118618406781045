<template>
    <div id="page">
        <div class="page" :style="{backgroundImage:'url('+bgImg+')',height:height ? height +'px' : '250px'}">
            <div id="nav" :style="{backgroundColor:'rgba(255,255,255,'+op.op+')'}" :class="op.class">
                <div class="margin-auto" style="width: 1200px;">
                    <ul class="cl head">
                        <li class="logo"><img :src="'/images/'+op.logo" height="40" class="margin-top-5"/></li>
                        <li v-for="(item,index) in $config.topMenu"><a href="javascript:void(0);" :class="item.path ==='/' ? ($route.path === item.path ? 'on' : '') : $route.path.indexOf(item.path) === 0 ? 'on' : ''" @click="$router.push({path:item.path})">{{item.title}}</a></li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="!isLogin">
                            <el-button type="success" size="mini" class="vm" @click="$router.push({path:'/login'})">登录</el-button>
                        </li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">
                            <el-button type="success" size="mini" class="vm" @click="logout">退出</el-button>
                        </li>
                        <li class="y f18 light" style="line-height:62px;width: auto !important;" v-if="isLogin">
                            <a href="javascript:void(0);" @click="$router.push({path:'/user/profile'})" class="text-line-1">
                                <div class="text-line-1">{{$user.getNickName() ? $user.getNickName() : '立即完善资料'}}</div>
                            </a>
                        </li>
<!--                        <li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">-->
<!--                            <a @click="$router.push({path:'/user/vip'})">续费VIP</a>-->
<!--                        </li>-->
											<li class="y" style="line-height:62px;width: auto !important;" v-if="isLogin">
												<a @click="$router.push({path:'/user'})">个人信息</a>
											</li>
                        <li class="y" style="line-height:62px;width: auto !important;" v-if="!isLogin">
                            <el-button size="mini" type="success" plain class="vm" @click="$router.push({path:'/register'})">注册会员</el-button>
                            <!--                            <el-button :type="op.op >= 1 ? 'success' : ''" size="mini" plain class="light vm bg-transparent" @click="$router.push({path:'/register'})">注册会员</el-button>-->
                        </li>
                    </ul>
                </div>
            </div>
            <div class="cl"></div>
            <div class="text-left light wrap1100 text-line-2" :class="search ? 'margin-top-50 f48' : (this.titleClass ? this.titleClass + ' f48' : 'margin-top-70 f48')" v-if="title" v-html="title">{{title}}</div>
            <div class="text-left wrap1100 light f-thin margin-top f22">{{subTitle}}</div>
<!--            <div class="wrap1100 margin-auto text-line-1 text-center f20 light margin-top-20" v-html="des"></div>-->
            <div class="margin-top-20 margin-auto" style="width: 866px" v-if="!$slots.search && search">
                <lee-search :searchPath="searchPath" :showPolicy="showPolicy" :showMmType="showMmType" :searchSlugs="searchSlugs" :stopSearch="stopSearch" :toPath="toPath" :showYear="showYear" @search="onSearch" :search-time-key="searchTimeKey"></lee-search>
            </div>
            <slot name="search"></slot>
            <!--            <slot name="header"></slot>-->
        </div>
        <div style="width: 866px;height: 40px;margin-top:-20px;line-height:40px;" class="padding-left-right border-radius-5 margin-auto bg-light box-shadow rel cl" v-if="bread.length > 0">
            <div class="z f16">
                <i class="el-icon-location-outline f20"></i>
                当前位置：
            </div>
            <el-breadcrumb separator-class="el-icon-arrow-right" style="line-height:40px;">
                <el-breadcrumb-item :to="{ path: '/' }" class="f16 dark-3">首页</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item,index) in bread" class="f16" :class="index + 1 === bread.length ? 'green text-line-1 hide' : 'dark-3'" style="max-width: 430px;" :to="item.url ? { path: item.url } : null">{{item.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div :style="{marginTop:bread.length > 0 ? '-20px' : ''}">
            <slot></slot>
        </div>
        <div class="bg-footer">
            <div class="wrap1100 padding-top-150 padding-bottom-20">
<!--                <el-row>-->
<!--                    <el-col :span="8">-->
<!--                        <div class="dark-2">联系我们</div>-->
<!--                        <div class="green-line margin-top margin-bottom"></div>-->
<!--                        <div class="f12 dark-3">-->
<!--                            办公地点：我是办公地址详情内容1254号<br/>邮政编码：950000<br/>联系电话：4008517485-->
<!--                        </div>-->
<!--                    </el-col>-->
<!--                    <el-col :span="8">-->
<!--                        <div class="dark-2">帮助中心</div>-->
<!--                        <div class="green-line margin-top margin-bottom"></div>-->
<!--                        <div class="f12 dark-3">-->
<!--                            疑难问题<br/>联系客服<br/>商务合作-->
<!--                        </div>-->
<!--                    </el-col>-->
<!--                    <el-col :span="8">-->
<!--                        <img src="/images/icon/icon_qq_off.png" height="42"/>-->
<!--                        <img src="/images/icon/icon_weixin_off.png" height="42"/>-->
<!--                        <img src="/images/icon/icon_weibo_off.png" height="42"/>-->
<!--                        <img src="/images/qrcode.png" height="88" class="margin-left-20"/>-->
<!--                    </el-col>-->
<!--                </el-row>-->
<!--                <div class="text-center f12 dark-2 margin-top-50">-->
<!--                    copyright 中国物流与采购联合会下设冷链物流专业委员会&copy;2018-2020-->
<!--                </div>-->
              <el-row>
                <el-col :span="8">
                  <div class="dark-2">联系我们</div>
                  <div class="green-line margin-top margin-bottom"></div>
                  <div class="f12 dark-3">
                    办公地点：北京市丰台区丽泽路16号院2号楼铭丰大厦11层<br/>
                    邮编：100073<br/>
                    联系电话：010-83775821<br/>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="dark-2">帮助中心</div>
                  <div class="green-line margin-top margin-bottom"></div>
                  <div class="f12 dark-3">
                    联系客服：陈钰 13810374932<br/>商务合作：陈玉勇 13811948055
                  </div>
                </el-col>
                <el-col :span="8">
                  <img src="/images/icon/icon_qq_off.png" height="42"/>
                  <img src="/images/icon/icon_weixin_off.png" height="42"/>
                  <img src="/images/icon/icon_weibo_off.png" height="42"/>
                  <el-popover
                      placement="top"
                      width="230"
                      trigger="hover">
                    <img src="/images/qrcode1.png" height="200" class="vm"/>
                    <img src="/images/qrcode1.png" slot="reference" height="88" class="margin-left-20"/>
                  </el-popover>
                  <el-popover
                      placement="top"
                      width="230"
                      trigger="hover">
                    <img src="/images/qrcode2.png" height="200" class="vm"/>
                    <img src="/images/qrcode2.png" slot="reference" height="88" class="margin-left-20"/>
                  </el-popover>
                </el-col>
              </el-row>
              <div class="text-center f12 dark-2 margin-top-50">
                copyright 北京中物冷联企业管理有限公司-数据平台-冷链物流专业委员会 ©2018-2020
<!--	              /食材供应链分会-->
              </div>
            </div>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
export default {
    name: "Page",
    props:{
        bgImg:String,
        height:{
            type:[String,Number],
            default:''
        },
        search:{
            type:Boolean,
            default:false
        },
        back:{
            type:Boolean,
            default:false
        },
        title:{
            type:String,
            default:''
        },
        miniTitle:{
            type:[String,Object],
            default:''
        },
        subTitle:{
            type:String,
            default:''
        },
        des:{
            type:String,
            default:''
        },
        bread:{
            type:Array,
            default:()=>{
                return []
            }
        },
        titleClass:{
            type:String,
            default:''
        },
        type:{
            type:String,
            default:'light'
        },
        searchPath:{
            type:String,
            default:'',
        },
        searchSlugs:{
            type:Array,
            default(){
                return []
            }
        },
        stopSearch:{
            type:Boolean,
            default:false
        },
        showYear:{
            type:Boolean,
            default:false
        },
      showPolicy:{
        type:Boolean,
        default:false
      },
      showMmType:{
        type:Boolean,
        default:false,
      },
        toPath:{
            type:String,
            default:'',
        },
      searchTimeKey:{
          type:String,
        default:'create_time'
      }
    },
    data(){
        return{
            sideMenu:false,
            scrollTop:0,
            isLogin:false
        }
    },
    mounted() {
        this.handleScroll();
        window.addEventListener('scroll',this.handleScroll);
        this.isLogin = !!this.$user.getToken();
    },
    methods:{
        showSideMenu(){
            this.sideMenu = true;
        },
        handleScroll(e){
            this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        },
        logout(){
            this.$user.logout();
            this.$message.success('你已退出登录');
            location.reload();
            // this.$router.push({path:'/login'});
        },
        onSearch(res){
            this.$emit('onSearch',res);
        }
    },
    computed: {
        op(){
            let _op = this.type === 'light' ? this.scrollTop / 50 : 1;

            return _op >= 1 ? {op:1,class:'nav-dark',logo:'logo_dark.png',color:'#333'} : {op:_op,class:'',logo:'logo.png',color:'#fff'};
        },
    }
}
</script>
<style scoped>
.head{list-style: none;height: 52px;overflow: hidden;}
.head li{float: left;margin-right:20px;display: inline-block;}
.head li.logo{width:40px;}
.head li a{color:#fff;font-size: 16px;display: block;padding:0 13px;line-height: 62px;height: 52px; text-align: center;}
.head li a.on{background: #7ED321;}
.nav-dark .head li a{color:#333 !important;}
.nav-dark .head li a.on{color: #fff !important;}
</style>
