<template>
	<div class="margin-top-100">
		<div class="text-center f38 dark-1 f-lq divider-green">最新标准</div>
		<div class="text-center f24 f-thin dark-3">Latest Standards</div>
		<div class="margin-top-50 margin-auto" style="width: 1100px;">
			<div class="padding-20 cl margin-bottom-20 hand" style="background-color: #f4f4f4;" v-for="(item,index) in list" @click="$router.push({path:'/standard/detail/'+item.id})">
				<div class="z hide" style="width: 100px;height: 60px;border-right: 1px solid #ccc;">
					<div class="dark-2 f28" style="line-height: 40px">{{$moment(item.create_time).format('DD')}}</div>
					<div class="dark-3 f14" style="line-height: 20px">{{$moment(item.create_time).format('YYYY.M')}}</div>
				</div>
				<div class="z padding-left-right-30" style="width: 900px">
					<div class="f18">{{item.title}}</div>
<!--					<div class="margin-top" v-if="item.tags && item.tags.length > 0"><el-tag type="info" class="bd-radius-0 h-24 lh-24 hand c-b mg-r-10" v-for="(st,si) in item.tags">{{st}}</el-tag></div>-->
				</div>
				<div class="y f30 dakr-2" style="line-height: 50px;">&gt;</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "standard",
	data() {
		return {
			list:[]
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList(){
			let _this = this;
			this.$http.post({
				url: '/Policy/listAll',
				data: {type:'standard',orderBy:'create_time',sortBy:'desc',limit:4},
				success(res) {
					_this.list = res;
				}
			});
		},
	}
}
</script>
