<template>
    <lee-user-main-page>
        <lee-user-page>
            <lee-user-top-tab slot="headRight"></lee-user-top-tab>
            <div style="width: 530px;" class="padding-bottom-100">
                <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" :show-message="false" :inline-message="true">
                    <el-form-item label="" prop="name" class="margin-bottom-0">
                        <div class="form-title">公司名称</div>
                        <el-input v-model="formData.company" class="lee-input mobile" placeholder="输入公司名称" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="department_name" class="margin-bottom-0">
                        <div class="form-title margin-top">部门</div>
                        <el-input v-model="formData.department" class="lee-input mobile" placeholder="输入部门名称" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="title" class="margin-bottom-0">
                        <div class="form-title margin-top">职位</div>
                        <el-input v-model="formData.job" class="lee-input mobile" placeholder="输入部门名称" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                    <!--                    <el-form-item label="" prop="product_type" class="margin-bottom-0" v-if="productTypeList.length > 0">-->
                    <!--                        <div class="form-title margin-top">产品类型</div>-->
                    <!--                        <el-row>-->
                    <!--                            <el-checkbox-group v-model="formData.product_type" class="lee-checkbox cl bg-gray-5">-->
                    <!--                                <el-col :span="6" v-for="(item,index) in productTypeList">-->
                    <!--                                    <el-checkbox :label="item.id">{{item.name}}</el-checkbox>-->
                    <!--                                </el-col>-->
                    <!--                            </el-checkbox-group>-->
                    <!--                        </el-row>-->
                    <!--                    </el-form-item>-->
                    <!--                    <el-form-item label="" prop="nature" class="margin-bottom-0" v-if="natureList.length > 0">-->
                    <!--                        <div class="form-title margin-top">企业性质</div>-->
                    <!--                        <el-row>-->
                    <!--                            <el-checkbox-group v-model="formData.nature" class="lee-checkbox cl bg-gray-5">-->
                    <!--                                <el-col :span="6" v-for="(item,index) in natureList">-->
                    <!--                                    <el-checkbox :label="item.id">{{item.name}}{{item.id}}</el-checkbox>-->
                    <!--                                </el-col>-->
                    <!--                            </el-checkbox-group>-->
                    <!--                        </el-row>-->
                    <!--                    </el-form-item>-->
                    <el-button type="success" class="padding-left-50 padding-right-50 f18 f-n box-shadow-green margin-top-50" @click="submit('form')">保存</el-button>
                </el-form>
            </div>
        </lee-user-page>
    </lee-user-main-page>
</template>

<script>
export default {
    name: "Base",
    data(){
        return{
            formData:{
                company:'',
                job:'',
                department:'',
                // nature:[],
                // product_type:[]
            },
            productTypeList:[],
            natureList:[],
            rules: {
                company:[{ required: true, message:'请填写公司名称'}],
                job:[{ required: true, message:'请填写公司职位'}],
                department:[{ required: true, message:'请填写部门名称'}],
            }
        }
    },
    mounted() {
        this.getDetail();
        // this.getNatureList();
        // this.getProductList();
    },
    methods:{
        getDetail(){
            let _this = this;
            this.$http.post({
                url:'/UserProfile/detail',
                data:null,
                success(res){
                    console.log(res);
                    _this.formData.company = res.company ? res.company : '';
                    _this.formData.department = res.department ? res.department : '';
                    _this.formData.job = res.job ? res.job : '';
                }
            })
        },
        getNatureList(){
            let _this = this;
            this.$http.post({
                url:'user/enterprise/nature',
                data:null,
                success(res){
                    console.log(res.data);
                    if(res.data){
                        _this.natureList = res.data;
                    }
                }
            })
        },
        getProductList(){
            let _this = this;
            this.$http.post({
                url:'user/enterprise/product',
                data:null,
                success(res){
                    if(res.data){
                        _this.productTypeList = res.data;
                    }
                }
            })
        },
        submit(formName){
            let _this = this;
            console.log(_this.formData);
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/UserProfile/edit',
                        data: {..._this.formData},
                        success(res) {
                            if(res === 'ok') {
                                _this.$message.success('保存成功');
                                _this.getDetail();
                            }else{
                                _this.$message.error('保存失败');
                            }
                        }
                    })
                }
            })
        },
    }
}
</script>
